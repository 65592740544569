$(".export-trigger").click(function(e) {
    const action = this.getAttribute("data-action");
    if (e.shiftKey) {
        $("#export-form").attr("action", action);
        $("#export-form").submit();
    }
  });

$(".insider-region-select").change(function(e) {
    const selected = $(".insider-region-select option:selected").val();
    window.location.href = selected;
});