// import * as r360 from "route360";
// import * as turf from "@turf/turf";

const r360 = require("route360");
const turf = require("@turf/turf");
const envFile = require("../environments/environment");

const travelTimesAndColors = [
    { time: 5 * 60, color: "#006837", opacity: 0.1 },
    { time: 10 * 60, color: "#39B54A", opacity: 0.2 },
    { time: 15 * 60, color: "#F7931E", opacity: 0.4 },
    { time: 20 * 60, color: "#F15A24", opacity: 0.4 }
];

const TRAVEL_TIMES = travelTimesAndColors.map(time => time.time);

r360.config.travelTimes = TRAVEL_TIMES;
r360.config.defaultTravelTimeControlOptions.travelTimes = travelTimesAndColors;


// height stops function
const getHeightStops = (travelTimes, heightFactor) => {
    return [
        [travelTimes[0], travelTimes.length * (10 * heightFactor)],
        [travelTimes[travelTimes.length - 1], travelTimes.length * heightFactor]
    ];
};

// color stop function
const getColorStops = (times, highlightTime = 0) => {
    const colors = r360.config.defaultTravelTimeControlOptions.travelTimes.map(function (time, idx) {
        return [times[idx], times[idx] === highlightTime ? "#00FF00" : time.color];
    });

    return colors;
};

const getLargestFeature = (coorindates) => {
    let maxArea = 0;
    let largestFeature;
    coorindates.map((coord) => {
        const polygon = turf.polygon(coord);
        const area = turf.area(polygon);
        if (area > maxArea) {
            maxArea = area;
            largestFeature = coord;
        }
    });
    return largestFeature;
};

const initializeDriveTimeLayers = (map, lat, lng) => {

    getDriveTime(map, lat, lng);

    map.addSource("driveTime", {
        "type": "geojson",
        data: {
            "type": "FeatureCollection",
            "features": []
        }
    });

    map.addLayer({
        "id": "driveTimePolygons",
        "type": "fill-extrusion",
        "source": "driveTime",
        "layout": {},
        "paint": {
            "fill-extrusion-base": 0,
            "fill-extrusion-height": 1000,
            "fill-extrusion-color": "red",
            "fill-extrusion-opacity": .4
        }
    });


    map.on("mousemove", "driveTimePolygons", function (e) {
        const times = e.features.map(feature => feature.properties.time);
        const minTime = Math.min(...times);
        map.setPaintProperty("driveTimePolygons", "fill-extrusion-color", {
            "property": "time",
            "stops": getColorStops(TRAVEL_TIMES, minTime)
        });
    });

    // Reset the state-fills-hover layer's filter when the mouse leaves the layer.
    map.on("mouseleave", "driveTimePolygons", function () {
        map.setPaintProperty("driveTimePolygons", "fill-extrusion-color", {
            "property": "time",
            "stops": getColorStops(TRAVEL_TIMES)
        });
    });

    map.on("click", "driveTimePolygons", function (e) {
        const timeProperties = e.features.map((f) => {
            return f.properties["time"];
        });

        const clickedTimeProperty = Math.min.apply(null, timeProperties);
        const clickedFeature = e.features.filter((f) => {
            return f.properties.time === clickedTimeProperty;
        })[0];

        const source = map.getSource("driveTime");
        const selectedFeatureMultipolygon = source
            .serialize()
            .data
            .features
            .find(feature => feature.properties.time === clickedFeature.properties.time);

        const selectedPolygonFeature = {
            ...selectedFeatureMultipolygon,
            geometry: {
                type: "Polygon",
                coordinates: [selectedFeatureMultipolygon.geometry.coordinates[0][0]] // Use first and largest polygon
            }
        };

        // // call the focuspoluygon service
        // focusPolygonService.createFromDriveTime(selectedPolygonFeature);
    });
};

const getDriveTime = (map, lat, lng) => {

    const self = this;
    const travelOptions = r360.travelOptions();
    const travelTimes = TRAVEL_TIMES;
    // please contact us and request your own key if you don't already have one

    travelOptions.setServiceKey(envFile.environment.route360Key);
    // set the service url for your area
    travelOptions.setServiceUrl("https://service.targomo.com/northamerica/");
    // we    only have one source which is the marker we just added
    travelOptions.addSource({ lat: lat, lon: lng, id: 1 });

    // we want to have a single polygon for 30 minutes
    travelOptions.setTravelTimes(travelTimes);
    // go by foot
    travelOptions.setTravelType("car");
    // request geojson
    travelOptions.setPolygonSerializer("geojson");
    // simplify polygons a bit to increase perf
    travelOptions.setSimplifyMeter(200);
    // wgs84
    travelOptions.setSrid(4326);

    // ~150m in longitudinal degrees at 52° latitude
    const bufferLengthsMeters = r360.Util.metersInDegrees(150, lat);
    travelOptions.setBuffer(Math.round(bufferLengthsMeters.lng * 1000) / 1000); // round to thousands

    r360.PolygonService.getTravelTimePolygons(travelOptions, function (geojsonPolygons) {


        const bounds = turf.bbox(geojsonPolygons);

        map.setPaintProperty("driveTimePolygons", "fill-extrusion-height", {
            "property": "time",
            "stops": getHeightStops(travelTimes, 10)
        });

        map.setPaintProperty("driveTimePolygons", "fill-extrusion-color", {
            "property": "time",
            "stops": getColorStops(travelTimes)
        });

        map.fitBounds(bounds);
        const source = map.getSource("driveTime");
        source.setData(geojsonPolygons);
    });
};

const getProcessedDrivingGeo = async (...args) => {
    const driveGeo = await getDrivingGeo(args[0], args[1]);
    const processedGeo = await process(driveGeo["features"][0]);
    processedGeo.coordinates = [this.getLargestFeature(processedGeo.coordinates)];
    return processedGeo;

    async function getDrivingGeo(lat, lon) {
        const travelOptions = r360.travelOptions();
        const travelTimes = [600];
        travelOptions.setServiceKey(environment.route360Key);
        travelOptions.setServiceUrl("https://service.route360.net/northamerica/");
        travelOptions.addSource({ lat, lon, id: 1 });
        travelOptions.setTravelTimes(travelTimes);
        travelOptions.setTravelType("car");
        travelOptions.setPolygonSerializer("geojson");
        travelOptions.setSimplifyMeter(200);
        travelOptions.setSrid(4326);

        const bufferLengthsMeters = r360.Util.metersInDegrees(150, lat);
        travelOptions.setBuffer(Math.round(bufferLengthsMeters.lng * 1000) / 1000); // round to thousands

        return new Promise((resolve, reject) => {
            r360.PolygonService.getTravelTimePolygons(travelOptions, function (geojsonPolygons) {
                geojsonPolygons ? resolve(geojsonPolygons) : reject(geojsonPolygons);
            });
        });
    }

    async function process(feature) {

        if (feature.geometry.coordinates.length > 1) {
            feature.geometry.coordinates = [
                feature.geometry.coordinates[0]
            ];
        }

        // simplify polygon
        const options = { tolerance: 0.0005, highQuality: false };

        // unkink polygon
        const simplified = turf.simplify(feature, options);
        const unkinked = turf.unkinkPolygon(simplified);

        const rewound = turf.rewind(unkinked, { mutate: true });

        const combined = turf.combine(rewound);
        const geo = combined["features"][0].geometry;
        const numberPolygons = geo.coordinates.length;

        const kinks = turf.kinks(geo);
        const numberKinks = kinks.features.length;

        if (numberKinks > numberPolygons) {
            console.log("!!!!! Uh-oh - detected a kink in this polygon...");
        }

        const truncated = turf.truncate(geo, {
            precision: 4
        });

        const rewoundFinal = turf.rewind(truncated, { mutate: true });

        return rewoundFinal;
    }
};

module.exports = {
    initializeDriveTimeLayers
}