const enviornmentFile = require('../environments/environment')

const apiKey = enviornmentFile.environment.hereApiKey;
let labels = [];
let addrComponents = [];
let currentFocus;

const autocomplete = (inp, arr, chosenAddressAction, inputPrefix) => {
    inp.addEventListener("input", function (e) {
        var a, b, i, val = this.value;
        closeAllLists();
        if (!val) { return false; }
        currentFocus = -1;
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "-autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        let width = '75%';
        if (this.parentNode.offsetWidth) {
            width = `${this.parentNode.offsetWidth*0.75}px`;
        }
        a.style.width = width;
        this.parentNode.appendChild(a);
        for (i = 0; i < arr.length; i++) {
            const { label } = arr[i];
            b = document.createElement("DIV");
            b.innerHTML = "<strong>" + label.substr(0, val.length) + "</strong>";
            b.innerHTML += label.substr(val.length);
            b.innerHTML += "<input type='hidden' name='addy' value='" + label + "'><input type='hidden' name='id' value='" + arr[i].id + "'>";
            const autocompleteAddress = addrComponents[i];
            b.addEventListener("click", (e) => {
                chosenAddressAction(e, getLatLng, autocompleteAddress, closeAllLists, inputPrefix)
            });
            a.appendChild(b);
        }
    });


    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
}

const getSuggestions = (address, inputFormID, chosenAddressAction, inputPrefix) => {
    let urtSuggestions = [];
    if (typeof urtAddresses !== 'undefined') {
        const uAddresses = JSON.parse(urtAddresses.replace(/(&#34;)/gm, '\"'));

        console.log("URT suggested addresses:", uAddresses);

        urtSuggestions = uAddresses.filter(a => a.address.startsWith(address))
            .map(s => {
                return {
                    address: {
                        city: s.city,
                        country: s.country,
                        county: null,
                        district: null,
                        houseNumber: null, // s.street_address.split(" ")[0],
                        postalCode: s.postal_code,
                        state: s.state,
                        street: s.street_address,
                        lat: s.lat,
                        lng: s.lng,
                    },
                    countryCode: s.country,
                    label: s.address,
                    language: "en",
                    id: null,
                    matchLevel: "houseNumber",
                };
            });

        console.log({ urtSuggestions });
    }

    const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${address}&in=countryCode:USA,CAN,AUS,GBR&apiKey=${apiKey}`;

    console.log(`Calling here.com autocomplete to suggest address [${address}]`);

    $.get(url, (response) => {
        let suggestions = response["items"];
        if (urtSuggestions.length <= 3) {
            suggestions = [...urtSuggestions, ...suggestions];
        }
        console.log("Suggestions: ", suggestions);
        labels = [];
        addrComponents = [];
        for (let i = 0; i < suggestions.length; i++) {
            const addr = suggestions[i].address;

            if (addr.postalCode) {
                addr.postalCode = addr.postalCode.split("-")[0];
            }

            const labelArr = [
                addr.houseNumber, 
                addr.street ? `${addr.street},` : null, 
                addr.city ? `${addr.city},` : null,
                addr.stateCode, 
                `${addr.postalCode},`,
                addr.countryCode ? addr.countryCode : addr.countryName
            ];

            labels.push({ 
                id: suggestions[i].id,
                label: labelArr.join(" "),
            });
            addrComponents.push(addr);
        }

        autocomplete(document.getElementById(inputFormID), labels, chosenAddressAction, inputPrefix);
    });
};

// entry point, send your input form id and your function for chosen address into here
// inputPrefix for multiple address froms on one page
const beginAutocomplete = (inputFormID, chosenAddressAction, inputPrefix) => {
    const addressInput = document.getElementById(inputFormID);

    addressInput.addEventListener("input", (e) => {
        let query;
        if (e.path) {
            query = e.path[0].value;
        } else {
           query = e.composedPath()[0].value;
        }
        if (query.length > 0) {
            getSuggestions(query, inputFormID, chosenAddressAction, inputPrefix);
        }
    });

    /*execute a function presses a key on the keyboard:*/
    addressInput.addEventListener("keydown", function (e) {
        var x = document.getElementById(this.id + "-autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode == 40) {
            currentFocus++;
            addActive(x);
        } else if (e.keyCode == 38) {
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click();
            }
        }
    });

    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }

    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

}

const getLatLng = (id, cb) => {
    const url = `https://lookup.search.hereapi.com/v1/lookup?id=${id}&apiKey=${apiKey}`

    $.get(url, (response) => {
        let latlng = null;
        if (response && response.position) {
            latlng = response.position;
        }
        console.log(`getting lat lng for ${id}: `, latlng);
        cb(latlng);
    });
}

module.exports = {
    beginAutocomplete
}