const loginPopup = () => {
    try {
        const signupModal = document.getElementById('signup-modal');
        const backdrop = document.getElementById('modal-backdrop');
        backdrop.addEventListener("click", (e) => {
            const signupModal = document.getElementById('signup-modal');
            const backdrop = document.getElementById('modal-backdrop');
            signupModal.style.display = "none";
            backdrop.style.display = "none";
        });
        signupModal.style.display = "block";
        backdrop.style.display = "block";
    } catch (ex) {
        console.log("Error in show loginPopup - ", ex);
    }
}

module.exports = {
    loginPopup
}