
const updateRadiusSettings = () => {
    const toggleEle = document.getElementById("credit-warning-toggle");
    if (toggleEle) {
        const showCreditWarning = getShowCreditWarning();
        if (showCreditWarning) {
            toggleEle.setAttribute("checked", "");
        } else {
            toggleEle.removeAttribute("checked");
        }

        toggleEle.addEventListener('click', async (event) => {
            toggleCreditWarning();
        });
    }

    const useCompanyEle = document.getElementById("use-company-credits");
    const usePersonalEle = document.getElementById("use-personal-credits");
    if (useCompanyEle && usePersonalEle) {
        const useCompanyCredits = useCompanyEle.getAttribute("val");
        if (useCompanyCredits === "true") {
            useCompanyEle.setAttribute("checked", "");
            usePersonalEle.removeAttribute("checked");
        } else {
            usePersonalEle.setAttribute("checked", "");
            useCompanyEle.removeAttribute("checked");
        }  
    }
}

const getShowCreditWarning = () => {
    let showCreditWarning = true;
    const localSettings = localStorage.getItem("radiusSettings");
    if (localSettings && localSettings !== "undefined") {
        const radiusSettings = JSON.parse(localSettings);
        showCreditWarning = radiusSettings.showCreditWarning;
    }
    return showCreditWarning;
}

const setShowCreditWarning = (showCreditWarning) => {
    let radiusSettings;
    const localSettings = localStorage.getItem("radiusSettings");
    if (localSettings && localSettings !== "undefined") {
        radiusSettings = JSON.parse(localSettings);
        radiusSettings.showCreditWarning = showCreditWarning;
    } else {
        radiusSettings = { showCreditWarning: showCreditWarning };
    }
    localStorage.setItem("radiusSettings", JSON.stringify(radiusSettings));
}

const toggleCreditWarning = (ele) => {
    const previousValue = getShowCreditWarning();
    setShowCreditWarning(!previousValue);
    console.log("Setting showCreditWarning to ", !previousValue);
}

updateRadiusSettings();
