$(document).ready(function() {
    if (document.getElementById("customers")) {
        $('#customers').select2();

        $('#customers').change(e => {
            const address = $('#customers').find(':selected').data('address');
            if (address) {
                $('#address').val(`${address.line1}, ${address.line2 ? address.line2 + ', ': ''}${address.city}, ${address.state} ${address.postal_code}`);
            } else {
                $('#address').val("No billing or shipping address on file.");
            }
        });

        $('#product').change(e => {
            const productInfo = JSON.parse($('#product').find(':selected').data('product-info'));
            $("#prices option").remove();
            $("#prices").children().remove();
            console.log(productInfo.prices);
            for (const price of productInfo.prices) {
                $("#prices").append(`<option value="${price.id}">$${price.unit_amount / 100}${price.recurring ? ' / ' + price.recurring.interval_count + ' ' + price.recurring.interval + '(s)' : ''}</option>`);
            }
            const taxCode = productInfo.taxCode;
            $("#taxCode").val(taxCode);
        });

        const selectedOption = $('#product option:selected');

        if (selectedOption) {
            const productInfo = JSON.parse($('#product').find(':selected').data('product-info'));
            console.log(productInfo);
            $("#prices option").remove();
            $("#prices").children().remove();
            console.log(productInfo.prices);
            for (const price of productInfo.prices) {
                $("#prices").append(`<option value="${price.id}">$${price.unit_amount / 100}${price.recurring ? ' / ' + price.recurring.interval_count + ' ' + price.recurring.interval + '(s)' : ''}</option>`);
            }
            const taxCode = productInfo.taxCode;
            $("#taxCode").val(taxCode);
        }
    }
});