
const findForm = () => {
  let form = null;
  form = document.getElementById('broker-create-company-form') || document.getElementById('broker-edit-company-form')
  return form;
}

const setStates = (country, method) => {
  const statesSelect = document.getElementById(`${method}-company-state`) 
  const allStates = document.getElementById(`${method}-company-state`) && document.getElementById(`${method}-company-state`).dataset["states"]
  const states = JSON.parse(allStates)[country]
  $(`#${method}-company-state`).find('option:not(:first)').remove();
  states.map(state => {
    const option = new Option(state.name, state.abbreviation);
    statesSelect.add(option,undefined)
  })
}

const form = findForm();


if (form) {
  const method = form.id.split('-')[1]

  const country = document.getElementById(`${method}-company-country`)
  const state = document.getElementById(`${method}-company-state`)
  if (country && country.value && !state.value) {
    setStates(country.value, method)
  }

  country.addEventListener('change', (event) => {
    const c = event.target.value
    if (c) {
      setStates(c,method)
    }
  });
}

module.exports = {
  setStates
}