const key = "pk.eyJ1IjoiZGlnaXRhbGdsb2JlIiwiYSI6ImNqaGphNmdzdDJkbjIzNm50YmJzYjEzbDkifQ.m4axiz_t3BE3_nQs5iAaIg";
const url = "https://api.mapbox.com/v4/digitalglobe.nal0g75k/{z}/{x}/{y}@2x.png?access_token=" + key;

const style = {
    "bearing": 0,
    "center": [
        10.184401828277089,
        -1.1368683772161603e-13
    ],
    "glyphs": "https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=4oSA0FHOlYzHRzwp2NSV",
    "layers": [
        {
            "id": "background",
            "paint": {
                "background-color": "rgb(242,243,240)"
            },
            "type": "background"
        },
        {
            "filter": [
                "==",
                "$type",
                "Polygon"
            ],
            "id": "park",
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-color": "rgb(230, 233, 229)"
            },
            "source": "openmaptiles",
            "source-layer": "park",
            "type": "fill"
        },
        {
            "filter": [
                "==",
                "$type",
                "Polygon"
            ],
            "id": "water",
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "fill-antialias": true,
                "fill-color": "rgb(194, 200, 202)"
            },
            "source": "openmaptiles",
            "source-layer": "water",
            "type": "fill"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Polygon"
                ],
                [
                    "==",
                    "subclass",
                    "ice_shelf"
                ]
            ],
            "id": "landcover_ice_shelf",
            "layout": {
                "visibility": "visible"
            },
            "maxzoom": 8,
            "paint": {
                "fill-color": "hsl(0, 0%, 98%)",
                "fill-opacity": 0.7
            },
            "source": "openmaptiles",
            "source-layer": "landcover",
            "type": "fill"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Polygon"
                ],
                [
                    "==",
                    "subclass",
                    "glacier"
                ]
            ],
            "id": "landcover_glacier",
            "layout": {
                "visibility": "visible"
            },
            "maxzoom": 8,
            "paint": {
                "fill-color": "hsl(0, 0%, 98%)",
                "fill-opacity": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            1
                        ],
                        [
                            8,
                            0.5
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "landcover",
            "type": "fill"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Polygon"
                ],
                [
                    "==",
                    "class",
                    "residential"
                ]
            ],
            "id": "landuse_residential",
            "layout": {
                "visibility": "visible"
            },
            "maxzoom": 16,
            "paint": {
                "fill-color": "rgb(234, 234, 230)",
                "fill-opacity": {
                    "base": 0.6,
                    "stops": [
                        [
                            8,
                            0.8
                        ],
                        [
                            9,
                            0.6
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "landuse",
            "type": "fill"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Polygon"
                ],
                [
                    "==",
                    "class",
                    "wood"
                ]
            ],
            "id": "landcover_wood",
            "layout": {
                "visibility": "visible"
            },
            "minzoom": 10,
            "paint": {
                "fill-color": "rgb(220,224,220)",
                "fill-opacity": {
                    "base": 1,
                    "stops": [
                        [
                            8,
                            0
                        ],
                        [
                            12,
                            1
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "landcover",
            "type": "fill"
        },
        {
            "filter": [
                "==",
                "$type",
                "LineString"
            ],
            "id": "waterway",
            "layout": {
                "visibility": "visible"
            },
            "paint": {
                "line-color": "hsl(195, 17%, 78%)"
            },
            "source": "openmaptiles",
            "source-layer": "waterway",
            "type": "line"
        },
        {
            "filter": [
                "==",
                "$type",
                "LineString"
            ],
            "id": "water_name",
            "layout": {
                "symbol-placement": "line",
                "symbol-spacing": 500,
                "text-field": "{name:latin}\n{name:nonlatin}",
                "text-font": [
                    "Metropolis Medium Italic",
                    "Noto Sans Italic"
                ],
                "text-rotation-alignment": "map",
                "text-size": 12
            },
            "paint": {
                "text-color": "rgb(157,169,177)",
                "text-halo-blur": 1,
                "text-halo-color": "rgb(242,243,240)",
                "text-halo-width": 1
            },
            "source": "openmaptiles",
            "source-layer": "water_name",
            "type": "symbol"
        },
        {
            "id": "building",
            "minzoom": 12,
            "paint": {
                "fill-antialias": true,
                "fill-color": "rgb(234, 234, 229)",
                "fill-outline-color": "rgb(219, 219, 218)"
            },
            "source": "openmaptiles",
            "source-layer": "building",
            "type": "fill"
        },
        {
            "id": "building-3d",
            "layout": {
                "visibility": "visible"
            },
            "metadata": {},
            "minzoom": 14,
            "paint": {
                "fill-extrusion-base": {
                    "property": "render_min_height",
                    "type": "identity"
                },
                "fill-extrusion-color": "rgba(189, 185, 181, 1)",
                "fill-extrusion-height": {
                    "property": "render_height",
                    "type": "identity"
                },
                "fill-extrusion-opacity": 0.75
            },
            "source": "openmaptiles",
            "source-layer": "building",
            "type": "fill-extrusion"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "==",
                        "brunnel",
                        "tunnel"
                    ],
                    [
                        "==",
                        "class",
                        "motorway"
                    ]
                ]
            ],
            "id": "tunnel_motorway_casing",
            "layout": {
                "line-cap": "butt",
                "line-join": "miter",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 6,
            "paint": {
                "line-color": "rgb(213, 213, 213)",
                "line-opacity": 1,
                "line-width": {
                    "base": 1.4,
                    "stops": [
                        [
                            5.8,
                            0
                        ],
                        [
                            6,
                            3
                        ],
                        [
                            20,
                            40
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "==",
                        "brunnel",
                        "tunnel"
                    ],
                    [
                        "==",
                        "class",
                        "motorway"
                    ]
                ]
            ],
            "id": "tunnel_motorway_inner",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 6,
            "paint": {
                "line-color": "rgb(234,234,234)",
                "line-width": {
                    "base": 1.4,
                    "stops": [
                        [
                            4,
                            2
                        ],
                        [
                            6,
                            1.3
                        ],
                        [
                            20,
                            30
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "in",
                    "class",
                    "taxiway"
                ]
            ],
            "id": "aeroway-taxiway",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "1444849345966.4436"
            },
            "minzoom": 12,
            "paint": {
                "line-color": "hsl(0, 0%, 88%)",
                "line-opacity": 1,
                "line-width": {
                    "base": 1.55,
                    "stops": [
                        [
                            13,
                            1.8
                        ],
                        [
                            20,
                            20
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "aeroway",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "in",
                    "class",
                    "runway"
                ]
            ],
            "id": "aeroway-runway-casing",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "1444849345966.4436"
            },
            "minzoom": 11,
            "paint": {
                "line-color": "hsl(0, 0%, 88%)",
                "line-opacity": 1,
                "line-width": {
                    "base": 1.5,
                    "stops": [
                        [
                            11,
                            6
                        ],
                        [
                            17,
                            55
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "aeroway",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Polygon"
                ],
                [
                    "in",
                    "class",
                    "runway",
                    "taxiway"
                ]
            ],
            "id": "aeroway-area",
            "layout": {
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "1444849345966.4436"
            },
            "minzoom": 4,
            "paint": {
                "fill-color": "rgba(255, 255, 255, 1)",
                "fill-opacity": {
                    "base": 1,
                    "stops": [
                        [
                            13,
                            0
                        ],
                        [
                            14,
                            1
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "aeroway",
            "type": "fill"
        },
        {
            "filter": [
                "all",
                [
                    "in",
                    "class",
                    "runway"
                ],
                [
                    "==",
                    "$type",
                    "LineString"
                ]
            ],
            "id": "aeroway-runway",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "maxzoom": 24,
            "metadata": {
                "mapbox:group": "1444849345966.4436"
            },
            "minzoom": 11,
            "paint": {
                "line-color": "rgba(255, 255, 255, 1)",
                "line-opacity": 1,
                "line-width": {
                    "base": 1.5,
                    "stops": [
                        [
                            11,
                            4
                        ],
                        [
                            17,
                            50
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "aeroway",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "==",
                    "class",
                    "path"
                ]
            ],
            "id": "highway_path",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "paint": {
                "line-color": "rgb(234, 234, 234)",
                "line-opacity": 0.9,
                "line-width": {
                    "base": 1.2,
                    "stops": [
                        [
                            13,
                            1
                        ],
                        [
                            20,
                            10
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "in",
                    "class",
                    "minor",
                    "service",
                    "track"
                ]
            ],
            "id": "highway_minor",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 8,
            "paint": {
                "line-color": "hsl(0, 0%, 88%)",
                "line-opacity": 0.9,
                "line-width": {
                    "base": 1.55,
                    "stops": [
                        [
                            13,
                            1.8
                        ],
                        [
                            20,
                            20
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "in",
                    "class",
                    "primary",
                    "secondary",
                    "tertiary",
                    "trunk"
                ]
            ],
            "id": "highway_major_casing",
            "layout": {
                "line-cap": "butt",
                "line-join": "miter",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 11,
            "paint": {
                "line-color": "rgb(213, 213, 213)",
                "line-dasharray": [
                    12,
                    0
                ],
                "line-width": {
                    "base": 1.3,
                    "stops": [
                        [
                            10,
                            3
                        ],
                        [
                            20,
                            23
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "in",
                    "class",
                    "primary",
                    "secondary",
                    "tertiary",
                    "trunk"
                ]
            ],
            "id": "highway_major_inner",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 11,
            "paint": {
                "line-color": "#fff",
                "line-width": {
                    "base": 1.3,
                    "stops": [
                        [
                            10,
                            2
                        ],
                        [
                            20,
                            20
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "in",
                    "class",
                    "primary",
                    "secondary",
                    "tertiary",
                    "trunk"
                ]
            ],
            "id": "highway_major_subtle",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "maxzoom": 11,
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "paint": {
                "line-color": "hsla(0, 0%, 85%, 0.69)",
                "line-width": 2
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "!in",
                        "brunnel",
                        "bridge",
                        "tunnel"
                    ],
                    [
                        "==",
                        "class",
                        "motorway"
                    ]
                ]
            ],
            "id": "highway_motorway_casing",
            "layout": {
                "line-cap": "butt",
                "line-join": "miter",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 6,
            "paint": {
                "line-color": "rgb(213, 213, 213)",
                "line-dasharray": [
                    2,
                    0
                ],
                "line-opacity": 1,
                "line-width": {
                    "base": 1.4,
                    "stops": [
                        [
                            5.8,
                            0
                        ],
                        [
                            6,
                            3
                        ],
                        [
                            20,
                            40
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "!in",
                        "brunnel",
                        "bridge",
                        "tunnel"
                    ],
                    [
                        "==",
                        "class",
                        "motorway"
                    ]
                ]
            ],
            "id": "highway_motorway_inner",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 6,
            "paint": {
                "line-color": {
                    "base": 1,
                    "stops": [
                        [
                            5.8,
                            "hsla(0, 0%, 85%, 0.53)"
                        ],
                        [
                            6,
                            "#fff"
                        ]
                    ]
                },
                "line-width": {
                    "base": 1.4,
                    "stops": [
                        [
                            4,
                            2
                        ],
                        [
                            6,
                            1.3
                        ],
                        [
                            20,
                            30
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "==",
                    "class",
                    "motorway"
                ]
            ],
            "id": "highway_motorway_subtle",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "maxzoom": 6,
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "paint": {
                "line-color": "hsla(0, 0%, 85%, 0.53)",
                "line-width": {
                    "base": 1.4,
                    "stops": [
                        [
                            4,
                            2
                        ],
                        [
                            6,
                            1.3
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "==",
                        "class",
                        "transit"
                    ],
                    [
                        "!in",
                        "brunnel",
                        "tunnel"
                    ]
                ]
            ],
            "id": "railway_transit",
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 16,
            "paint": {
                "line-color": "#dddddd",
                "line-width": 3
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "==",
                        "class",
                        "transit"
                    ],
                    [
                        "!in",
                        "brunnel",
                        "tunnel"
                    ]
                ]
            ],
            "id": "railway_transit_dashline",
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 16,
            "paint": {
                "line-color": "#fafafa",
                "line-dasharray": [
                    3,
                    3
                ],
                "line-width": 2
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "==",
                        "class",
                        "rail"
                    ],
                    [
                        "has",
                        "service"
                    ]
                ]
            ],
            "id": "railway_service",
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 16,
            "paint": {
                "line-color": "#dddddd",
                "line-width": 3
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "==",
                    "class",
                    "rail"
                ],
                [
                    "has",
                    "service"
                ]
            ],
            "id": "railway_service_dashline",
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 16,
            "paint": {
                "line-color": "#fafafa",
                "line-dasharray": [
                    3,
                    3
                ],
                "line-width": 2
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "!has",
                        "service"
                    ],
                    [
                        "==",
                        "class",
                        "rail"
                    ]
                ]
            ],
            "id": "railway",
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 13,
            "paint": {
                "line-color": "#dddddd",
                "line-width": {
                    "base": 1.3,
                    "stops": [
                        [
                            16,
                            3
                        ],
                        [
                            20,
                            7
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "!has",
                        "service"
                    ],
                    [
                        "==",
                        "class",
                        "rail"
                    ]
                ]
            ],
            "id": "railway_dashline",
            "layout": {
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 13,
            "paint": {
                "line-color": "#fafafa",
                "line-dasharray": [
                    3,
                    3
                ],
                "line-width": {
                    "base": 1.3,
                    "stops": [
                        [
                            16,
                            2
                        ],
                        [
                            20,
                            6
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "==",
                        "brunnel",
                        "bridge"
                    ],
                    [
                        "==",
                        "class",
                        "motorway"
                    ]
                ]
            ],
            "id": "highway_motorway_bridge_casing",
            "layout": {
                "line-cap": "butt",
                "line-join": "miter",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 6,
            "paint": {
                "line-color": "rgb(213, 213, 213)",
                "line-dasharray": [
                    2,
                    0
                ],
                "line-opacity": 1,
                "line-width": {
                    "base": 1.4,
                    "stops": [
                        [
                            5.8,
                            0
                        ],
                        [
                            6,
                            5
                        ],
                        [
                            20,
                            45
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "all",
                    [
                        "==",
                        "brunnel",
                        "bridge"
                    ],
                    [
                        "==",
                        "class",
                        "motorway"
                    ]
                ]
            ],
            "id": "highway_motorway_bridge_inner",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "minzoom": 6,
            "paint": {
                "line-color": {
                    "base": 1,
                    "stops": [
                        [
                            5.8,
                            "hsla(0, 0%, 85%, 0.53)"
                        ],
                        [
                            6,
                            "#fff"
                        ]
                    ]
                },
                "line-width": {
                    "base": 1.4,
                    "stops": [
                        [
                            4,
                            2
                        ],
                        [
                            6,
                            1.3
                        ],
                        [
                            20,
                            30
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "transportation",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "!=",
                    "class",
                    "motorway"
                ],
                [
                    "==",
                    "$type",
                    "LineString"
                ]
            ],
            "id": "highway_name_other",
            "layout": {
                "symbol-placement": "line",
                "symbol-spacing": 350,
                "text-field": "{name:latin} {name:nonlatin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-max-angle": 30,
                "text-pitch-alignment": "viewport",
                "text-rotation-alignment": "map",
                "text-size": 10,
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "paint": {
                "text-color": "#bbb",
                "text-halo-blur": 1,
                "text-halo-color": "#fff",
                "text-halo-width": 2,
                "text-translate": [
                    0,
                    0
                ]
            },
            "source": "openmaptiles",
            "source-layer": "transportation_name",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "LineString"
                ],
                [
                    "==",
                    "class",
                    "motorway"
                ]
            ],
            "id": "highway_name_motorway",
            "layout": {
                "symbol-placement": "line",
                "symbol-spacing": 350,
                "text-field": "{ref}",
                "text-font": [
                    "Metropolis Light",
                    "Noto Sans Regular"
                ],
                "text-pitch-alignment": "viewport",
                "text-rotation-alignment": "viewport",
                "text-size": 10,
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"
            },
            "paint": {
                "text-color": "rgb(117, 129, 145)",
                "text-halo-blur": 1,
                "text-halo-color": "hsl(0, 0%, 100%)",
                "text-halo-width": 1,
                "text-translate": [
                    0,
                    2
                ]
            },
            "source": "openmaptiles",
            "source-layer": "transportation_name",
            "type": "symbol"
        },
        {
            "filter": [
                "==",
                "admin_level",
                4
            ],
            "id": "boundary_state",
            "layout": {
                "line-cap": "round",
                "line-join": "round",
                "visibility": "visible"
            },
            "metadata": {
                "mapbox:group": "a14c9607bc7954ba1df7205bf660433f"
            },
            "paint": {
                "line-blur": 0.4,
                "line-color": "rgb(230, 204, 207)",
                "line-dasharray": [
                    2,
                    2
                ],
                "line-opacity": 1,
                "line-width": {
                    "base": 1.3,
                    "stops": [
                        [
                            3,
                            1
                        ],
                        [
                            22,
                            15
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "boundary",
            "type": "line"
        },
        {
            "filter": [
                "==",
                "admin_level",
                2
            ],
            "id": "boundary_country",
            "layout": {
                "line-cap": "round",
                "line-join": "round"
            },
            "metadata": {
                "mapbox:group": "a14c9607bc7954ba1df7205bf660433f"
            },
            "paint": {
                "line-blur": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            0.4
                        ],
                        [
                            22,
                            4
                        ]
                    ]
                },
                "line-color": "rgb(230, 204, 207)",
                "line-opacity": 1,
                "line-width": {
                    "base": 1.1,
                    "stops": [
                        [
                            3,
                            1
                        ],
                        [
                            22,
                            20
                        ]
                    ]
                }
            },
            "source": "openmaptiles",
            "source-layer": "boundary",
            "type": "line"
        },
        {
            "filter": [
                "all",
                [
                    "in",
                    "class",
                    "continent",
                    "hamlet",
                    "neighbourhood",
                    "isolated_dwelling"
                ],
                [
                    "==",
                    "$type",
                    "Point"
                ]
            ],
            "id": "place_other",
            "layout": {
                "text-anchor": "center",
                "text-field": "{name:latin}\n{name:nonlatin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-justify": "center",
                "text-offset": [
                    0.5,
                    0
                ],
                "text-size": 10,
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 14,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "text-color": "rgb(117, 129, 145)",
                "text-halo-blur": 1,
                "text-halo-color": "rgb(242,243,240)",
                "text-halo-width": 1
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "==",
                    "class",
                    "suburb"
                ]
            ],
            "id": "place_suburb",
            "layout": {
                "text-anchor": "center",
                "text-field": "{name:latin}\n{name:nonlatin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-justify": "center",
                "text-offset": [
                    0.5,
                    0
                ],
                "text-size": 10,
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 15,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "text-color": "rgb(117, 129, 145)",
                "text-halo-blur": 1,
                "text-halo-color": "rgb(242,243,240)",
                "text-halo-width": 1
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "==",
                    "class",
                    "village"
                ]
            ],
            "id": "place_village",
            "layout": {
                "icon-size": 0.4,
                "text-anchor": "left",
                "text-field": "{name:latin}\n{name:nonlatin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-justify": "left",
                "text-offset": [
                    0.5,
                    0.2
                ],
                "text-size": 10,
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 14,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "icon-opacity": 0.7,
                "text-color": "rgb(117, 129, 145)",
                "text-halo-blur": 1,
                "text-halo-color": "rgb(242,243,240)",
                "text-halo-width": 1
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "==",
                    "class",
                    "town"
                ]
            ],
            "id": "place_town",
            "layout": {
                "icon-image": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            "circle-11"
                        ],
                        [
                            8,
                            ""
                        ]
                    ]
                },
                "icon-size": 0.4,
                "text-anchor": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            "left"
                        ],
                        [
                            8,
                            "center"
                        ]
                    ]
                },
                "text-field": "{name:latin}\n{name:nonlatin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-justify": "left",
                "text-offset": [
                    0.5,
                    0.2
                ],
                "text-size": 10,
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 15,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "icon-opacity": 0.7,
                "text-color": "rgb(117, 129, 145)",
                "text-halo-blur": 1,
                "text-halo-color": "rgb(242,243,240)",
                "text-halo-width": 1
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "all",
                    [
                        "!=",
                        "capital",
                        2
                    ],
                    [
                        "==",
                        "class",
                        "city"
                    ],
                    [
                        ">",
                        "rank",
                        3
                    ]
                ]
            ],
            "id": "place_city",
            "layout": {
                "icon-image": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            "circle-11"
                        ],
                        [
                            8,
                            ""
                        ]
                    ]
                },
                "icon-size": 0.4,
                "text-anchor": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            "left"
                        ],
                        [
                            8,
                            "center"
                        ]
                    ]
                },
                "text-field": "{name:latin}\n{name:nonlatin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-justify": "left",
                "text-offset": [
                    0.5,
                    0.2
                ],
                "text-size": 10,
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 14,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "icon-opacity": 0.7,
                "text-color": "rgb(117, 129, 145)",
                "text-halo-blur": 1,
                "text-halo-color": "rgb(242,243,240)",
                "text-halo-width": 1
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "all",
                    [
                        "==",
                        "capital",
                        2
                    ],
                    [
                        "==",
                        "class",
                        "city"
                    ]
                ]
            ],
            "id": "place_capital",
            "layout": {
                "icon-image": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            "star-11"
                        ],
                        [
                            8,
                            ""
                        ]
                    ]
                },
                "icon-size": 1,
                "text-anchor": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            "left"
                        ],
                        [
                            8,
                            "center"
                        ]
                    ]
                },
                "text-field": "{name:latin}\n{name:nonlatin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-justify": "left",
                "text-offset": [
                    0.5,
                    0.2
                ],
                "text-size": 14,
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 12,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "icon-opacity": 0.7,
                "text-color": "rgb(117, 129, 145)",
                "text-halo-blur": 1,
                "text-halo-color": "rgb(242,243,240)",
                "text-halo-width": 1
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "all",
                    [
                        "!=",
                        "capital",
                        2
                    ],
                    [
                        "<=",
                        "rank",
                        3
                    ],
                    [
                        "==",
                        "class",
                        "city"
                    ]
                ]
            ],
            "id": "place_city_large",
            "layout": {
                "icon-image": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            "circle-11"
                        ],
                        [
                            8,
                            ""
                        ]
                    ]
                },
                "icon-size": 0.4,
                "text-anchor": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            "left"
                        ],
                        [
                            8,
                            "center"
                        ]
                    ]
                },
                "text-field": "{name:latin}\n{name:nonlatin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-justify": "left",
                "text-offset": [
                    0.5,
                    0.2
                ],
                "text-size": 14,
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 12,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "icon-opacity": 0.7,
                "text-color": "rgb(117, 129, 145)",
                "text-halo-blur": 1,
                "text-halo-color": "rgb(242,243,240)",
                "text-halo-width": 1
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "==",
                    "class",
                    "state"
                ]
            ],
            "id": "place_state",
            "layout": {
                "text-field": "{name:latin}\n{name:nonlatin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-size": 10,
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 12,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "text-color": "rgb(113, 129, 144)",
                "text-halo-blur": 1,
                "text-halo-color": "rgb(242,243,240)",
                "text-halo-width": 1
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "==",
                    "class",
                    "country"
                ],
                [
                    "!has",
                    "iso_a2"
                ]
            ],
            "id": "place_country_other",
            "layout": {
                "text-field": "{name:latin}",
                "text-font": [
                    "Metropolis Light Italic",
                    "Noto Sans Italic"
                ],
                "text-size": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            9
                        ],
                        [
                            6,
                            11
                        ]
                    ]
                },
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 8,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "text-color": {
                    "base": 1,
                    "stops": [
                        [
                            3,
                            "rgb(157,169,177)"
                        ],
                        [
                            4,
                            "rgb(153, 153, 153)"
                        ]
                    ]
                },
                "text-halo-color": "rgba(236,236,234,0.7)",
                "text-halo-width": 1.4
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "==",
                    "class",
                    "country"
                ],
                [
                    ">=",
                    "rank",
                    2
                ],
                [
                    "has",
                    "iso_a2"
                ]
            ],
            "id": "place_country_minor",
            "layout": {
                "text-field": "{name:latin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-size": {
                    "base": 1,
                    "stops": [
                        [
                            0,
                            10
                        ],
                        [
                            6,
                            12
                        ]
                    ]
                },
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 8,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "text-color": {
                    "base": 1,
                    "stops": [
                        [
                            3,
                            "rgb(157,169,177)"
                        ],
                        [
                            4,
                            "rgb(153, 153, 153)"
                        ]
                    ]
                },
                "text-halo-color": "rgba(236,236,234,0.7)",
                "text-halo-width": 1.4
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "filter": [
                "all",
                [
                    "==",
                    "$type",
                    "Point"
                ],
                [
                    "<=",
                    "rank",
                    1
                ],
                [
                    "==",
                    "class",
                    "country"
                ],
                [
                    "has",
                    "iso_a2"
                ]
            ],
            "id": "place_country_major",
            "layout": {
                "text-anchor": "center",
                "text-field": "{name:latin}",
                "text-font": [
                    "Metropolis Regular",
                    "Noto Sans Regular"
                ],
                "text-size": {
                    "base": 1.4,
                    "stops": [
                        [
                            0,
                            10
                        ],
                        [
                            3,
                            12
                        ],
                        [
                            4,
                            14
                        ]
                    ]
                },
                "text-transform": "uppercase",
                "visibility": "visible"
            },
            "maxzoom": 6,
            "metadata": {
                "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f"
            },
            "paint": {
                "text-color": {
                    "base": 1,
                    "stops": [
                        [
                            3,
                            "rgb(157,169,177)"
                        ],
                        [
                            4,
                            "rgb(153, 153, 153)"
                        ]
                    ]
                },
                "text-halo-color": "rgba(236,236,234,0.7)",
                "text-halo-width": 1.4
            },
            "source": "openmaptiles",
            "source-layer": "place",
            "type": "symbol"
        },
        {
            "id": "satellite-tiles",
            "type": "raster",
            "source": "satellite",
            "source-layer": "DigitalGlobe Satellite Imagery",
            "minzoom": 0,
            "maxzoom": 22,
            "layout": {
                "visibility": "none"
            }
        }
    ],
    "metadata": {
        "mapbox:autocomposite": false,
        "mapbox:groups": {
            "101da9f13b64a08fa4b6ac1168e89e5f": {
                "collapsed": false,
                "name": "Places"
            },
            "a14c9607bc7954ba1df7205bf660433f": {
                "name": "Boundaries"
            },
            "b6371a3f2f5a9932464fa3867530a2e5": {
                "collapsed": false,
                "name": "Transportation"
            }
        },
        "mapbox:type": "template",
        "openmaptiles:mapbox:owner": "openmaptiles",
        "openmaptiles:mapbox:source:url": "mapbox://openmaptiles.4qljc88t",
        "openmaptiles:version": "3.x"
    },
    "name": "Positron",
    "pitch": 0,
    "sources": {
        "satellite": {
            "type": "raster",
            "url": "https://api.maptiler.com/tiles/satellite/tiles.json?key=4oSA0FHOlYzHRzwp2NSV"
        },
        "openmaptiles": {
            "type": "vector",
            "url": "https://api.maptiler.com/tiles/v3/tiles.json?key=4oSA0FHOlYzHRzwp2NSV"
        }
    },
    "sprite": "https://api.maptiler.com/maps/positron/sprite",
    "version": 8,
    "zoom": 0.8902641636539237
};

module.exports = {
    style
}