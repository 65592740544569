const io = require('socket.io-client');

if (page && page === "payment") {
  document.addEventListener("DOMContentLoaded", (event) => {
    const params = new URLSearchParams(document.location.search);
    const reportId = params.get("id");
    if (!reportId) return;
    
    const progressElement = document.getElementById("report-progress");

    // activity indicator
    let count = 1;
    const intervalId = setInterval(() => {
      progressElement.innerText = `Generating${'.'.repeat(count)}`;
      count++;
      if (count > 3) count = 1;
    }, 1000);

    const socket = io('/radius-events', { query: { reportId }, transports:['websocket']});
    socket.on('event', data => {
      if (data.status === 'report-failed') {
        clearInterval(intervalId);
        progressElement.style.display = 'none';
        const failureElement = document.getElementById("report-failure");
        failureElement.innerText = 'Report generation was unsuccessful. You will not be charged for this report. Please contact us via Intercom for support.'
        failureElement.style.display = 'block';
      }

      if (data.status === 'report-generated') {
        clearInterval(intervalId);
        progressElement.innerText = 'Generated';
      }

      if (data.status === 'payment-success') {
        clearInterval(intervalId);
        progressElement.disabled = false;
        progressElement.innerText = 'View Report';
      }

      if (data.status === 'payment-failure') {
        clearInterval(intervalId);
        progressElement.style.display = 'none';
        const failureElement = document.getElementById("report-failure");
        failureElement.innerText = 'Payment was unsuccessful. Please try again with an alternate payment method.'
        failureElement.style.display = 'block';
      }
    });
  });
}