const { beginAutocomplete } = require("../common/autocomplete.js")

$(function () {
    const findForm = () => {
        let form = null;
        form = document.getElementById("reports-compass-form");
        return form;
    }

    const form = findForm();
    if (form) {
        const autocompleteInputFormId = "address-lookup";
        beginAutocomplete(autocompleteInputFormId, chosenAddressAction)
    }
})

$(function () {
    const findPurchaseCreditsBtn = () => {
        let purchaseCreditsBtn = null;
        purchaseCreditsBtn = document.getElementById("btn-purchase-credits");
        return purchaseCreditsBtn;
    }
    const findNoPurchaseBtn = () => {
        let noPurchaseBtn = null;
        noPurchaseBtn = document.getElementById("delete-report-btn");
        return noPurchaseBtn;
    }

    const purchaseCreditsBtn = findPurchaseCreditsBtn();
    if (purchaseCreditsBtn) {
        purchaseCreditsBtn.addEventListener("click", async (event) => {
            noPurchaseBtn = findNoPurchaseBtn();
            if (noPurchaseBtn) {
                noPurchaseBtn.style = "display:none";
                const hintEle = document.getElementById("do-not-refresh-hint");
                if (hintEle) {
                    hintEle.style = "display:visible;";
                }
            }
        });
    }
})

const trySetLatLngFromHereId = (parentElement, getLatLng, autocompleteAddress) => {
    if (!parentElement) return;

    let idElement = parentElement.querySelector('input[name="id"]');
    let id = idElement?.value; //here.com address id
    if (!id) return;

    getLatLng(id, (result) => {
        let latLng = { latitude: result.lat, longitude: result.lng };
        let encodedLatLng = JSON.stringify(latLng);
        let latLngElement = document.querySelector('input[id="latLng"]');
        if (latLngElement) latLngElement.value = encodedLatLng;

        let countryCode = autocompleteAddress.countryCode || autocompleteAddress.countryName
        let countryCodeElement = document.querySelector('input[id="countryCode"]');
        if (countryCodeElement) countryCodeElement.value = countryCode;
    });
}

const chosenAddressAction = (e, getLatLng, autocompleteAddress, closeAllLists, inputPrefix) => {
    trySetLatLngFromHereId(e.currentTarget, getLatLng, autocompleteAddress);

    $("#address-lookup").val(`${autocompleteAddress.houseNumber ? autocompleteAddress.houseNumber : ''} ${autocompleteAddress.street}, ${autocompleteAddress.city}, ${autocompleteAddress.stateCode} ${autocompleteAddress.postalCode}, ${autocompleteAddress.countryName}`);
    closeAllLists();
}
