// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const environment = {
    production: false,
    intercomAppId: "toyvxtbw",
    tileHostingUrl: "https://api.maptiler.com/maps/positron/style.json?key=4oSA0FHOlYzHRzwp2NSV",
    tileHostingStreetsUrl: "https://api.maptiler.com/maps/streets/style.json?key=4oSA0FHOlYzHRzwp2NSV",
    websocketEndpoint: "http://localhost:8010",
    googleApiKey: "AIzaSyC8CCI-Bv1AqIlii5rcywTqqgLRi55zAUU",
    route360Key: "M58V7QEJTNS650YOTNC3KD5",
    stripePublicKey: "pk_test_Su53JeNedoeo0MmfovJyHPzy",
    amplify: {
        Auth: {
            identityPoolId: "us-east-1:f90860d6-c2bb-44f3-a401-7e8d0e836651",
            region: "us-east-1",
            userPoolId: "us-east-1_ZYDroocUG",
            userPoolWebClientId: "7737k2ngefe7ko92eff5drqss2"
        }
    },
    mapQuestApiKey: "CCFxQ54oNpVNRFQYgv6wDaySzWhxs4OZ",
    NODE_ENV: "dev",

    hereApiKey: "bvcr-TmRisbw2w2j-xLfMoMvyMfTjCKIJ77NwCa-SNc"
};

module.exports = {
    environment,
}