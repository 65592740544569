const environmentFile = require("../environments/environment");

const findForm = () => {
    let form = null;
    form = document.getElementById('lf-general-info');
    return form;
}

const form = findForm();

let labels = [];
let addrComponents = [];
let currentFocus;

const autocomplete = (inp, arr, addrComponents) => {
    inp.addEventListener("input", function (e) {
        var a, b, i, val = this.value;
        closeAllLists();
        if (!val) { return false; }
        currentFocus = -1;
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "-autocomplete-list");
        a.setAttribute("class", "autocomplete-items autocomplete-w-75");
        this.parentNode.appendChild(a);
        for (i = 0; i < arr.length; i++) {
            b = document.createElement("DIV");
            b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
            b.innerHTML += arr[i].substr(val.length);
            b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
            const autocompleteAddress = addrComponents[i];
            b.addEventListener("click", function (e) {
                $("#line1").val(autocompleteAddress.houseNumber + ' ' + autocompleteAddress.street);
                $("#city").val(autocompleteAddress.city);
                $("#country").val(autocompleteAddress.countryCode);
                setStates(autocompleteAddress.countryCode)
                $("#county").val(autocompleteAddress.county);
                $("#postal").val(autocompleteAddress.postalCode);
                $("#state").val(autocompleteAddress.state);
                closeAllLists();
            });
            a.appendChild(b);
        }
    });


    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
}

const getSuggestions = (address) => {
    const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${address}&in=countryCode:USA,CAN,AUS,GBR&apiKey=${environmentFile.environment.hereApiKey}`;

    $.get(url, (response) => {
        const suggestions = response["items"];
        labels = [];
        addrComponents = [];
        for (let i = 0; i < 4; i++) {
            if (suggestions[i].address.postalCode) {
                suggestions[i].address.postalCode = suggestions[i].address.postalCode.split("-")[0]
            }
            if (suggestions[i]
                && suggestions[i].address.houseNumber
                && suggestions[i].address.street
                && suggestions[i].address.city
                && suggestions[i].address.stateCode
                && suggestions[i].address.postalCode) {
                const label = suggestions[i].address.houseNumber + ' ' + suggestions[i].address.street + ', ' + suggestions[i].address.city + ', ' + suggestions[i].address.stateCode + ' ' + suggestions[i].address.postalCode + ', ' + suggestions[i].address.countryName;
                labels.push(label);
                addrComponents.push(suggestions[i].address);
            }
        }
        autocomplete(document.getElementById("line1"), labels, addrComponents);
    });
};



const setStates = (country) => {
  const statesSelect = document.getElementById("state")
  const allStates = document.getElementById("state").dataset["states"]
  const states = JSON.parse(allStates)[country]
  $('#state').find('option:not(:first)').remove();
  states.map(state => {
    const option = new Option(state.name, state.name);
    statesSelect.add(option,undefined)
  })
}



if (form) {
    const addressInput = document.getElementById("line1");

    // const country = document.getElementById("country")
    country.addEventListener('change', (event) => {
      const c = event.target.value
      if (c) {
        setStates(c)
      }
    });
    addressInput.addEventListener("input", (e) => {
        let query;
        if (e.path) {
            query = e.path[0].value;
        } else {
            query = e.composedPath()[0].value;
        }
        if (query.length > 0) {
            getSuggestions(query);
        }
    });

    /*execute a function presses a key on the keyboard:*/
    addressInput.addEventListener("keydown", function (e) {
        var x = document.getElementById(this.id + "-autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode == 40) {
            currentFocus++;
            addActive(x);
        } else if (e.keyCode == 38) {
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click();
            }
        }
    });

    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }

    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

}