const findForm = () => {
  let form = null;
  form = document.getElementById('broker-profile-form')
  return form;
}

const setStatesProfile = (country) => {
  const statesSelect = document.getElementById(`update-profile-state`) 
  const allStates = document.getElementById(`update-profile-state`) && document.getElementById(`update-profile-state`).dataset["states"]
  const states = JSON.parse(allStates)[country]
  $(`#update-profile-state`).find('option:not(:first)').remove();
  states.map(state => {
    const option = new Option(state.name, state.abbreviation);
    statesSelect.add(option,undefined)
  })
}

const form = findForm();

if (form) {
  const country = document.getElementById(`update-profile-country`)
  const state = document.getElementById(`update-profile-state`)
  if (country && country.value && !state.value) {
    setStatesProfile(country.value)
  }

  country.addEventListener('change', (event) => {
    const c = event.target.value
    if (c) {
      setStatesProfile(c)
    }
  });
}

module.exports = {
  setStatesProfile
}