if (page && page === "profile") {
  // user = JSON.parse(user.replace(/(&#34;)/gm, '\"'));
  let existingPhoneNumber = user && user.phoneNumber && !user.isPhoneVerified ? user.phoneNumber : ""
  let error = document.querySelector(".phone-error");
  const phoneInputField = document.querySelector("#phone");
  // console.log(phoneInputField)
  const realPhoneInput = document.querySelector("#realPhoneInput")
  console.log(`E64 phone - ${realPhoneInput}`)
  if (phoneInputField) {
    const phoneVal = window.intlTelInput(phoneInputField, {
      initialCountry: "us",
      // preferredCountries: ["us", "ca", "gb"],
      customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
        const { iso2 } = selectedCountryData
        let placeholder = ""
        if (iso2 === "us" || iso2 === "ca") {
          placeholder = "(XXX)-XXX-XXXX"
        } else if (iso2 === "gb") {
          placeholder = "XXXXX XXXXXX"
        } else {
          placeholder = selectedCountryPlaceholder
        }
        return "e.g. " + placeholder;
      },
       onlyCountries: ["us", "ca"],
      //  nationalMode: true,
      numberType: "MOBILE",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });

  
    phoneInputField.addEventListener('keyup', function () {
      if (phoneInputField.value.trim()) {
        if (phoneVal.isValidNumber()) {
          error.innerHTML = ""
          let number = phoneVal.getNumber()
          phoneVal.setNumber(number);
          console.log(number)
          realPhoneInput.value = number
        } else {
          if (phoneVal.getNumber()) {
            let text = "Please enter a valid phone number for the country selected."
            var textNode = document.createTextNode(text);
            error.innerHTML = "";
            error.appendChild(textNode);
          } 
          resetPhoneNumValue()
        }
      } else {
        if (error && error.lastChild) {
          error.lastChild.remove()
        }

        resetPhoneNumValue()
      }
    })
    if (existingPhoneNumber) {
      phoneVal.setNumber(existingPhoneNumber);
      realPhoneInput.value = existingPhoneNumber
    }
  }

  function resetPhoneNumValue() {
    realPhoneInput.value = null 
    console.log("Resetting phone number to null ", realPhoneInput.value)
  }
}