const findForm = () => {
  let form = null;
  form = document.getElementById('org-invite-form');
  return form;
}

const form = findForm();

if (form) {
  let typingTimer;    
  let doneTypingInterval = 1000;
  const companyEmailDomainInput = document.getElementById('company_domain')
  const emailInput = document.getElementById('email')
  const seatLimit = document.getElementById('seat-limit')
  const companyEmailDomain = companyEmailDomainInput.value

  const hasReachedSeatLimit = (seatLimit && seatLimit.value === 'true')

  if (companyEmailDomain && !hasReachedSeatLimit) {
    const patternStr = `^[A-Za-z0-9]+(.|_)+[A-Za-z0-9]+@+${companyEmailDomain}$`
    const regex = new RegExp(patternStr, 'g')
  
    let messages = document.querySelector(".messages");

    $(emailInput).keyup(function () {
      let value = $(this).val()
      clearTimeout(typingTimer);
      if (value) {
        typingTimer = setTimeout(function () {
          regex.lastIndex = 0
            
          validateCompanyDomain(value, regex, tblData)
        }, doneTypingInterval);
      } else {
        $('#invite-btn').prop('disabled', true)
      }
      clearMessages(messages)
    });
  } else {
    let messages = document.querySelector(".messages");
    let disableMsg = hasReachedSeatLimit ? "Your company has reached the maximum number of users allowed. If you'd still like to add another user, please contact Radius+ admin for help." : "Please reach out to the Radius+ team for help, we apologize for the inconvenience."
    addError(messages, disableMsg)
    emailInput.setAttribute('disabled', true)
  }

    const tblDataStr = document.getElementById('ctbl').getAttribute('data-emails')

    const tblData = JSON.parse(tblDataStr)

    const searchInput = document.getElementById('search-text')

    searchInput.addEventListener('keyup', (e) => {
      refreshTbl(search(tblData, searchInput.value), e.target.value)
    })
}

function validateCompanyDomain(emailInput, regex, membersArr) {
    let messages = document.querySelector(".messages");
    let errMsg = ''
    let isCompanyDomain = regex.test(emailInput)
    let isMember = checkIfMember(membersArr, emailInput)
  

  // not valid but a member 
  if ((!isCompanyDomain && isMember) || (isCompanyDomain && isMember)) {
    errorMsg = `${emailInput} is member already!`
    addError(messages, errorMsg)
    $('#invite-btn').prop('disabled', true)
  } else if (!isCompanyDomain && !isMember) {
    errorMsg = `You are trying to invite a new user outside your company. Please try again using their company email address or contact the Radius+ team for help.`
    addError(messages, errorMsg)
    $('#invite-btn').prop('disabled', true)
  } else {
    showSuccess(messages)
    $('#invite-btn').prop('disabled', false)
  }
  
  
}
function addError(messages, error) {
  clearMessages(messages)
  if (!messages.childElementCount) {
    let block = document.createElement("p");
    block.classList.add("help-block");
    block.classList.add("error");
    block.innerText = error;
    messages.appendChild(block);
  } else {
    let eles = document.querySelectorAll('.help-block')
    if (!error) {
      messages.removeChild(eles[0])
    }
  }
}

function showSuccess(messages) {
  clearMessages(messages)
  let block = document.createElement("p");
  block.classList.add("has-success");
  block.classList.add("help-block");
  block.setAttribute("style", "color: #21E501;");
  block.innerText = "Looks good!";
  messages.appendChild(block);
}

function clearMessages(messages) {
  if (messages.childElementCount) {
    let eles = document.querySelectorAll('.help-block')
    messages.removeChild(eles[0])
  } 
}

function search(arr, term) {
  if (!term) return arr;

  return arr.filter(item => item.email.toLowerCase().includes(term.toLowerCase()))
}

function checkIfMember(arr, term) {
  return arr.find(item => item.email === term.toLowerCase())
}
function refreshTbl(data) {
  const tableBody = document.getElementById('ctbl').children[1]

  tableBody.innerHTML = ''

  data.forEach(row => {
    let currentRow = document.createElement('tr')
    let currentCell = document.createElement('td')
    currentCell.textContent = row.email
    currentRow.appendChild(currentCell)
    tableBody.appendChild(currentRow)
  })
}

