const { startLadda } = require("./laddaStart.js");
// import * as upload from "./fileUpload.js";
// import * as moment from "moment";
// import * as validate from "validate.js";

const upload = require("./fileUpload.js");
const moment = require("moment");
const validate = require("validate.js");

const formValidation = (form, element) => {

    if (form) {

        // Before using it we must add the parse and format functions
        // Here is a sample implementation using moment.js
        validate.extend(validate.validators.datetime, {
            // The value is guaranteed not to be null or undefined but otherwise it
            // could be anything.
            parse: function (value, options) {
                return +moment.utc(value);
            },
            // Input is a unix timestamp
            format: function (value, options) {
                var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
                return moment.utc(value).format(format);
            }
        });

        let constraints;

        // These are the constraints used to validate the form
        const fullReportConstraints = {
            "address-lookup": {
                presence: {
                    message: "^Address field can't be blank.",
                }
            },
            "email": {
                presence: true,
                email: true,
            },
            "line1": {
                presence: {
                    message: "^Street address can't be blank",
                },
                length: {
                    maximum: 255,
                    message: "^Your address line cannot be longer than 255 characters"
                },
                // format: {
                //     // numbers followed by a name
                //     pattern: "^[0-9]+ .+$",
                //     message: "^The street address must be a valid street name"
                // },
            },
            "line2": {
                length: {
                    maximum: 255,
                    // message: "^Your address line 2 cannot be longer than 255 characters"
                }
            },
            "city": {
                presence: true,
                length: {
                    maximum: 50,
                    message: "^Your city cannot be longer than 50 characters"
                },
            },
            "state": {
                presence: true,
            },
            "postalCode": {
                presence: true,
                length: {
                    minimum: 3,
                    maximum: 10,
                    message: "^Your postal code should be between 3 and 10 characters"
                },
            },
        };

        const addressConstraints = {
            "line1": {
                presence: {
                    message: "^Street address can't be blank",
                },
                length: {
                    maximum: 255,
                    message: "^Your address line cannot be longer than 255 characters"
                },
            },
            "line2": {
                length: {
                    maximum: 255,
                    // message: "^Your address line 2 cannot be longer than 255 characters"
                }
            },
            "city": {
                presence: true,
                length: {
                    maximum: 50,
                    message: "^Your city cannot be longer than 50 characters"
                },
            },
            "state": {
                presence: true,
            },
            "postalCode": {
                presence: true,
                length: {
                    minimum: 3,
                    maximum: 10,
                    message: "^Your postal code should be between 3 and 10 characters"
                },
            },
        };

        const addressOnlyReportConstraints = {
            "address-lookup": {
                presence: {
                    message: "^Address field can't be blank.",
                }
            }
        };

        const profileConstraints = {
            "familyName": {
                presence: true,
                length: {
                    maximum: 75,
                    message: "^Your last name cannot be longer than 75 characters"
                }
            },
            "givenName": {
                presence: true,
                length: {
                    maximum: 75,
                    message: "^Your first name cannot be longer than 75 characters"
                }
            },
            "username": {
                presence: true,
                length: {
                    minimum: 6,
                    maximum: 35,
                    message: "^Your username should be between 6 and 35 characters"
                },
                format: {
                    pattern: "^[a-zA-Z0-9_-]*$",
                    message: "^Your username can only contain letters, numbers, \"-\" and \"_\""
                },
            },
            "email": {
                presence: true,
                email: true,
            },
            "line1": {
                presence: {
                    message: "^Street address can't be blank",
                },
                length: {
                    maximum: 255,
                    message: "^Your address line cannot be longer than 255 characters"
                },
                // format: {
                //     pattern: "^[0-9]+ .+$",
                //     message: "^The street address must be a valid street name"
                // },
            },
            "line2": {
                length: {
                    maximum: 255,
                    // message: "^Your address line 2 cannot be longer than 255 characters"
                }
            },
            "city": {
                presence: true,
                length: {
                    maximum: 50,
                    message: "^Your city cannot be longer than 50 characters"
                },
            },
            "state": {
                presence: true,
            },
            "postalCode": {
                presence: true,
                length: {
                    minimum: 3,
                    maximum: 10,
                    message: "^Your postal code should be between 3 and 10 characters"
                },
            },
        };

        const pricingConstraints = {
            "line1": {
                presence: {
                    message: "^Street address can't be blank",
                },
                length: {
                    maximum: 255,
                    message: "^Your address line cannot be longer than 255 characters"
                },
                // format: {
                //     // numbers followed by a name
                //     pattern: "^[0-9]+ .+$",
                //     message: "^The street address must be a valid street name"
                // },
            },
            "line2": {
                length: {
                    maximum: 255,
                    // message: "^Your address line 2 cannot be longer than 255 characters"
                }
            },
            "city": {
                presence: true,
                length: {
                    maximum: 50,
                    message: "^Your city cannot be longer than 50 characters"
                },
            },
            "state": {
                presence: true,
            },
            "postalCode": {
                presence: true,
                length: {
                    minimum: 3,
                    maximum: 10,
                    message: "^Your postal code should be between 3 and 10 characters"
                },
            },
            "product": {
                presence: true
            }
        };

        const listingGenInfo = {
            "propertyName": {
                presence: true,
            },
            "propertyType": {
                // presence: true,
            },
            "price": {
                presence: true,
            },
            "listingStatus": {
                presence: true,
            },
            "displayStartDate": {
                presence: true,
                datetime: {
                    earliest: moment.utc().subtract(1, "day"),
                    message: "^The date should be in the future"
                },
            },
            "line1": {
                presence: true,
            },
            "line2": {
                // presence: true,
            },
            "city": {
                presence: true,
            },
            "state": {
                presence: true,
            },
            "postal": {
                presence: true,
            },
            "county": {
                presence: true,
            },
            "country": {
                presence: true,
            },
        };

        const listingDemo = {
            "yearBuilt": {
                presence: true,
            },
            "netRentSqFt": {
                presence: true,
            }
        };

        const listingContacts = {
            "contactName": {
                presence: true,
            },
            "email": {
                presence: true,
                email: true
            },
            "email2": {
                email: true
            },
        };

        const promotedEmailBidConstraints = {
            "enteredBid": {
                presence: true,
                numericality: {
                    greaterThan: 0,
                    message: "^Bid Price has to be greater than Current bid. Minimum bid is $10."
                }
            },
            "enteredMaxBid": {
                numericality: {
                    greaterThan: 0,
                    message: "^Max Bid Price has to be greater than Bid Price."
                }
            }
        }

        const createBrokerCompanyConstraints = {
            "companyName": {
                presence: true
            },
            "email": {
                presence: true,
                email: true,
            },
            "address1": {
                presence: {
                    message: "^Street address can't be blank",
                },
                length: {
                    maximum: 255,
                    message: "^Your address line cannot be longer than 255 characters"
                },
                // format: {
                //     pattern: "^[0-9]+ .+$",
                //     message: "^The street address must be a valid street name"
                // },
            },
            "address2": {
                length: {
                    maximum: 255,
                    // message: "^Your address line 2 cannot be longer than 255 characters"
                }
            },
            "city": {
                presence: true,
                length: {
                    maximum: 50,
                    message: "^Your city cannot be longer than 50 characters"
                },
            },
            "state": {
                presence: true,
            },
            "zip": {
                presence: true,
                length: {
                    minimum: 3,
                    maximum: 10,
                    message: "^Your postal code should be between 3 and 10 characters"
                },
            },

        };

        const brokerProfileConstraints = {
            "familyName": {
                presence: true,
                length: {
                    maximum: 75,
                    message: "^Your last name cannot be longer than 75 characters"
                }
            },
            "givenName": {
                presence: true,
                length: {
                    maximum: 75,
                    message: "^Your first name cannot be longer than 75 characters"
                }
            },
            "jobTitle": {
                presence: true
            },    
            "phoneNum": {
                presence: {
                    message: "^Phone number can't be blank",
                },
            },
            "email": {
                presence: true,
                email: true,
            },
            "address1": {
                presence: {
                    message: "^Street address can't be blank",
                },
                length: {
                    maximum: 255,
                    message: "^Your address line cannot be longer than 255 characters"
                },
                // format: {
                //     pattern: "^[0-9]+ .+$",
                //     message: "^The street address must be a valid street name"
                // },
            },
            "address2": {
                length: {
                    maximum: 255,
                    // message: "^Your address line 2 cannot be longer than 255 characters"
                }
            },
            "city": {
                presence: true,
                length: {
                    maximum: 50,
                    message: "^Your city cannot be longer than 50 characters"
                },
            },
            "state": {
                presence: true,
            },
            "zip": {
                presence: true,
                length: {
                    minimum: 3,
                    maximum: 10,
                    message: "^Your postal code should be between 3 and 10 characters"
                },
            },
        };

        if (form.id === "reports-compass-form") {
            const emailField = document.getElementById("email");
            if (emailField) {
                constraints = fullReportConstraints;
            } else {
                constraints = addressOnlyReportConstraints;
            }
        }

        if (form.id === "profile-form") {
            constraints = profileConstraints;
        }

        if (form.id === "pricing-form") {
            constraints = pricingConstraints;
        }

        if (form.id === "lf-general-info") {
            constraints = listingGenInfo;
        }

        if (form.id === "lf-demographics") {
            constraints = listingDemo;
        }

        if (form.id === "lf-contacts") {
            constraints = listingContacts;
        }

        if (form.id === "bid-form") {
            constraints = promotedEmailBidConstraints;
        }

        if (form.id === "broker-create-company-form" || form.id === "broker-edit-company-form") {
            constraints = createBrokerCompanyConstraints;
        }

        if (form.id === "broker-profile-form") {
            constraints = brokerProfileConstraints;
        }

        if (form.id === "subscription-checkout-form") {
            constraints = addressConstraints;
        }


        // Hook up the form so we can prevent it from being posted
        form.addEventListener("submit", function (ev) {
            ev.preventDefault();
            handleFormSubmit(form);
        });

        if (form.id === "bid-form") {
            let currentBid = parseFloat(form.elements["formCurrentBid"].value);
            currentBid = currentBid && currentBid >= 10? currentBid: 9.99;
            promotedEmailBidConstraints.enteredBid.numericality.greaterThan = currentBid;
            let maxBid = form.elements["enteredMaxBid"].value;
            if (maxBid) {
                maxBid = parseFloat(parseFloat);
                promotedEmailBidConstraints.enteredMaxBid.numericality.greaterThan = currentBid;
            }
        }

        // Hook up the inputs to validate on the fly
        let inputs;
        if (form.id === "lf-demographics") {
            inputs = document.querySelectorAll('input[type=text], input[type=number], textarea, select');
        } else if (form.id === "lf-contacts") {
            inputs = document.querySelectorAll('input[type=text], textarea');
        } else {
            inputs = document.querySelectorAll('input[type=text], textarea, select');
        }

        for (var i = 0; i < inputs.length; ++i) {
            inputs.item(i).addEventListener("change", function (ev) {
                console.log(constraints);
                var errors = validate(form, constraints) || {};
                showErrorsForInput(this, errors[this.name])
            });
        }

        function handleFormSubmit(form, input) {
            // validate the form aainst the constraints
            var errors = validate(form, constraints);
            // // then we update the form to reflect the results
            showErrors(form, errors || {});
            if (!errors) {
                showSuccess();
            }
        }

        // Updates the inputs with the validation errors
        function showErrors(form, errors) {
            console.log(errors);
            // We loop through all the inputs and show the errors for that input
            let inputs;
            if (form.id === "lf-demographics" || form.id === "bid-form" || form.id === "lf-general-info") {
                inputs = form.querySelectorAll("input[type=text], input[type=number], select[name]");
            } else if (form.id === "lf-contacts") {
                inputs = form.querySelectorAll("input[type=text], input[type=number], input[type=tel]");
            } else {
                inputs = form.querySelectorAll("input[type=text], select[name], input[type=tel]");
            }
            if (inputs.length > 0) {
                inputs.forEach(function (input) {
                    // Since the errors can be null if no errors were found we need to handle
                    // that
                    showErrorsForInput(input, errors && errors[input.name]);
                });
            } else {
                form.submit();
            }
        }

        // Shows the errors for a specific input
        function showErrorsForInput(input, errors) {
            // This is the root of the input
            var formGroup = closestParent(input.parentNode, "form-group")
                // Find where the error messages will be insert into
                , messages = formGroup.querySelector(".messages");
            // First we remove any old messages and resets the classes
            resetFormGroup(formGroup);
            // If we have errors
            if (errors) {
                // we first mark the group has having errors
                formGroup.classList.add("has-error");
                // then we append all the errors
                errors.forEach(function (error) {
                    addError(messages, error);
                });
            } else {
                // otherwise we simply mark it as success
                formGroup.classList.add("has-success");
            }
        }

        // Recusively finds the closest parent that has the specified class
        function closestParent(child, className) {
            if (!child || child == document) {
                return null;
            }
            if (child.classList.contains(className)) {
                return child;
            } else {
                return closestParent(child.parentNode, className);
            }
        }

        function resetFormGroup(formGroup) {
            // Remove the success and error classes
            formGroup.classList.remove("has-error");
            formGroup.classList.remove("has-success");
            // and remove any old messages
            formGroup.querySelectorAll(".help-block.error").forEach(function (el) {
                el.parentNode.removeChild(el);
            });
        }

        // Adds the specified error with the following markup
        // <p class="help-block error">[message]</p>
        function addError(messages, error) {
            var block = document.createElement("p");
            block.classList.add("help-block");
            block.classList.add("error");
            block.innerText = error;
            messages.appendChild(block);
        }

        function showSuccess() {
            startLadda(element);
            if (form.id === "broker-create-company-form" || form.id === "broker-edit-company-form") {
                upload.processFormFiles(form);
            } else {
                form.submit();
            }
        }
    }
}

module.exports = {
    formValidation
}