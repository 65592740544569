const laddaStart = require('../common/laddaStart')

if (page === "billing") {

    const openTab = (e, tabName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tabName).style.display = "block";
        e.currentTarget.className += " active";
    }

    const tabLinks = document.getElementsByClassName("tablinks");
    for (const t of tabLinks) {
        t.addEventListener("click", (e) => {
            const target = t.getAttribute("data-target");
            openTab(e, target);
        });
    }

    const cancelSub = () => {
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: '/profile/cancel-subscription',
            success: function (data) {
                window.location.href = '/profile/billing';
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error({
                    "Failed to cancel sub: ": errorThrown
                });
            }
        });
    }

    const cancelSubBtn = document.getElementById("subscribeCancel");
    if (cancelSubBtn) {
        cancelSubBtn.addEventListener("click", e => {
            cancelSub();
            laddaStart.startLadda(cancelSubBtn);
        });
    }
}