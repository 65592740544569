$(function () {
    const findForm = () => {
        let form = null;
        form = document.getElementById('billing-address-form');
        return form;
    }

    const form = findForm();

    if (form) {
        validate.extend(validate.validators.datetime, {
            parse: function (value, options) {
                return +moment.utc(value);
            },
            format: function (value, options) {
                var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
                return moment.utc(value).format(format);
            }
        });

        var constraints = {
            "line1": {
                presence: true,
                length: {
                    maximum: 255,
                    message: "^Your address line cannot be longer than 255 characters"
                },
                format: {
                    // numbers followed by a name
                    pattern: "^[0-9]+ .+$",
                    message: "^The street address must be a valid street name"
                }
            },
            "line2": {
                length: {
                    maximum: 255,
                    message: "^Your address line 2 cannot be longer than 255 characters"
                }
            },
            "city": {
                presence: true,
                length: {
                    maximum: 50,
                    message: "^Your city cannot be longer than 50 characters"
                },
            },
            "state": {
                presence: true,
            },
            "postalCode": {
                presence: true,
                length: {
                    minimum: 3,
                    maximum: 10,
                    message: "^Your postal code should be between 3 and 10 characters"
                },
                // format: {
                //     // match US zip code pattern
                //     pattern: "^[0-9]{5}(?:-[0-9]{4})?$",
                //     message: "^Please enter a valid US zip code"
                // }
            },
        };

        // Hook up the form so we can prevent it from being posted
        form.addEventListener("submit", function (ev) {
            ev.preventDefault();
            handleFormSubmit(form);
        });

        // Hook up the inputs to validate on the fly
        var inputs = document.querySelectorAll("input, textarea, select")
        for (var i = 0; i < inputs.length; ++i) {
            inputs.item(i).addEventListener("change", function (ev) {
                var errors = validate(form, constraints) || {};
                showErrorsForInput(this, errors[this.name])
            });
        }

        function handleFormSubmit(form, input) {
            // validate the form aainst the constraints
            var errors = validate(form, constraints);
            // then we update the form to reflect the results
            showErrors(form, errors || {});
            if (!errors) {
                showSuccess();
            }
        }

        // Updates the inputs with the validation errors
        function showErrors(form, errors) {
            // We loop through all the inputs and show the errors for that input
            form.querySelectorAll("input[name], select[name]").forEach(function (input) {
                // Since the errors can be null if no errors were found we need to handle
                // that
                showErrorsForInput(input, errors && errors[input.name]);
            });
        }

        // Shows the errors for a specific input
        function showErrorsForInput(input, errors) {
            // This is the root of the input
            var formGroup = closestParent(input.parentNode, "form-group")
                // Find where the error messages will be insert into
                , messages = formGroup.querySelector(".messages");
            // First we remove any old messages and resets the classes
            console.log(formGroup);
            resetFormGroup(formGroup);
            // If we have errors
            if (errors) {
                // we first mark the group has having errors
                formGroup.classList.add("has-error");
                // then we append all the errors
                errors.forEach(function (error) {
                    addError(messages, error);
                });
            } else {
                // otherwise we simply mark it as success
                formGroup.classList.add("has-success");
            }
        }

        // Recusively finds the closest parent that has the specified class
        function closestParent(child, className) {
            if (!child || child == document) {
                return null;
            }
            if (child.classList.contains(className)) {
                return child;
            } else {
                return closestParent(child.parentNode, className);
            }
        }

        function resetFormGroup(formGroup) {
            // Remove the success and error classes
            formGroup.classList.remove("has-error");
            formGroup.classList.remove("has-success");
            // and remove any old messages
            formGroup.querySelectorAll(".help-block.error").forEach(function (el) {
                el.parentNode.removeChild(el);
            });
        }

        // Adds the specified error with the following markup
        // <p class="help-block error">[message]</p>
        function addError(messages, error) {
            var block = document.createElement("p");
            block.classList.add("help-block");
            block.classList.add("error");
            block.innerText = error;
            messages.appendChild(block);
        }

        function showSuccess() {
            form.submit();
        }
    }
})
