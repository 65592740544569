// import { setStatesProfile } from "./updateBrokerProfileForm";
const updateBrokerProfileForm = require("./updateBrokerProfileForm")

const chatEnabledToggle = document.getElementById("broker-chat-toggle");
const toggleUpdateBtn = document.getElementById('broker-update-btn');
const copyAddressBtn = document.getElementById('copy-address-btn');
const companyForm = document.getElementById('broker-edit-company-form');
const brokerForm = document.getElementById('broker-profile-form');

const toggleUpdateProfileBtn = (btn) => {
    const formPanel = document.getElementById('profile-update-panel');
    if (formPanel) {
        const property = formPanel.style.getPropertyValue("display") === "none" ? "block" : "none";
        formPanel.style.setProperty("display", property);
        if (property === "block") {
            btn.innerHTML = `<i class="fas fa-chevron-up"></i>`;
        } else {
            btn.innerHTML = "Edit";
        }
    }
};

const getChatEnabled = (chatEnabledToggle) => {
    let val = chatEnabledToggle.getAttribute("value");
    if (!val) {
        chatEnabledToggle.setAttribute("value", "false");
    } else {
        const newVal = val === "true" ? "false" : "true";
        chatEnabledToggle.setAttribute("value", newVal);
    }
    val = chatEnabledToggle.getAttribute("value");
    console.log("Set chatEnabled to ", val);
    return val;
};

const toggleChatEnabled = () => {
    const chatEnabled = getChatEnabled();
    setChatEnabled(!chatEnabled);
};

$(function () {
    if (toggleUpdateBtn) {
        toggleUpdateBtn.addEventListener("click", async (event) => {
            toggleUpdateProfileBtn(toggleUpdateBtn);
        });
    }

    if (chatEnabledToggle) {
        chatEnabledToggle.addEventListener('click', async (event) => {
            const enabled = getChatEnabled(chatEnabledToggle);
            $.ajax({
                url: `/broker/update`,
                type: "POST",
                data: JSON.stringify({chatEnabled: enabled}),
                contentType: 'application/json',
                success: function (data) {
                    console.log('Sent!');
                    console.log(data);
                    window.location = `/broker`;
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.error({
                        "Failed posting: ": errorThrown
                    });
                }
            });
        });
    }

    if (copyAddressBtn) {
        copyAddressBtn.addEventListener('click', async (event) => {
            if (companyForm && brokerForm) { 
                brokerForm.elements["update-profile-address1"].value = companyForm.elements['address1'].value;
                brokerForm.elements["update-profile-address2"].value = companyForm.elements['address2'].value;
                brokerForm.elements["update-profile-city"].value = companyForm.elements['city'].value;
                brokerForm.elements["update-profile-country"].value = companyForm.elements['country'].value
                updateBrokerProfileForm.setStatesProfile(companyForm.elements['country'].value)
                brokerForm.elements["update-profile-state"].value = companyForm.elements['state'].value;
                brokerForm.elements["update-profile-zip"].value = companyForm.elements['zip'].value;
               
            }
        });
    }
});