// import * as mapStyle from "./mapStyle.js";
// import * as buffer from "buffer";
// import * as wkx from "wkx";

const mapStyle = require("./mapStyle.js");
const buffer = require("buffer");
const wkx = require("wkx");

// copy post summary for social media
const copyToClipboard = () => {
    const copyText = `${title}.\n\n${excerpt}\n\n${url}\n\n${hashtags}`;

    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = copyText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
}

$(".admin-post-copy").click(function(e) {
   copyToClipboard();
  });

// zoning page
const zoningMap = document.getElementById("zoning-coverage-map");

const jsonFromWKB = (wkb) => {
    const wkbBuffer = new buffer.Buffer(wkb, "hex");
    const geometry = wkx.Geometry.parse(wkbBuffer);

    return geometry.toGeoJSON();
};

const flyToCityRow = (cityId) => {
    const cityRows = document.getElementsByClassName("city-row");
    for (const row of cityRows) {
        row.style.backgroundColor = "#fff";
    }
    const ele = document.getElementById("row-city-id-" + cityId);
    ele.scrollIntoView({ block: 'end', behavior: 'smooth' });
    ele.style.backgroundColor = "#f3ffed";
};

const drawZoningPolygons = (data, map, visibleByDefault = false) => {

    const geo =  {
        type: "FeatureCollection",
        features: data.map((d) => {
            const json = jsonFromWKB(d.g);

            return {
                "type": "Feature",
                "properties": {
                    "id": d.i,
                    "name": d.n
                },
                "geometry": json
            };
        })
    };

    map.addSource("zoning", {
        "type": "geojson",
        "data": geo
    });

    map.addLayer({
        "id": "zoning-fills",
        "type": "fill",
        "source": "zoning",
        "layout": {},
        "paint": {
            "fill-color": "#4dd802",
            "fill-opacity": 0.5
        }
    });

    map.addLayer({
        "id": "zoning-fills-hover",
        "type": "fill",
        "source": "zoning",
        "layout": {},
        "paint": {
            "fill-color": "#4dd802",
            "fill-opacity": 1
        },
        "filter": ["==", "name", ""]
    });

    map.on("mousemove", "zoning-fills", function (e) {
        map.setFilter("zoning-fills-hover", ["==", "id", e.features[0].properties.id]);
    });

    // Reset the state-fills-hover layer's filter when the mouse leaves the layer.
    map.on("mouseleave", "zoning-fills", function () {
        map.setFilter("zoning-fills-hover", ["==", "id", ""]);
    });

    map.on("click", "zoning-fills", function (e) {
        const id = e.features[0].properties.id;
        flyToCityRow(id);
    });
}

if (zoningMap) {

    let geo = geoString.replace(/(&#34;)/gm, '\"')
    geo = JSON.parse(geo);

    mapboxgl.accessToken = "pk.eyJ1IjoiZGlnaXRhbGdsb2JlIiwiYSI6ImNqaGphNmdzdDJkbjIzNm50YmJzYjEzbDkifQ.m4axiz_t3BE3_nQs5iAaIg";

    let map;
    const initializeListMap = (container) => {
        const bounds = [[-128, 23], [-66, 50]];
    
        map = new mapboxgl.Map({
            container: container,
            style: 'mapbox://styles/mapbox/streets-v9',
            center: [-100, 40],
            zoom: 3,
            style: mapStyle.style,
            bounds: bounds
        });
    
        map.on('load', function () {
            console.log("map initialized");
            drawZoningPolygons(geo, map);
        });
    
    
    };
    
    initializeListMap("zoning-coverage-map");
}
