const profileFormAutocomplete = require("./profileFormAutocomplete.js");

if (page && page === "profile") {
    const loadNext = () => {
        const hiddenRows = document.querySelectorAll('#reports-table-body > div[data-hidden="true"]');
        const targetRows = Array.from(hiddenRows).slice(0, 5);
        for (const targetRow of targetRows) {
          targetRow.style.removeProperty('display');
          delete targetRow.dataset.hidden;
        }

        if (hiddenRows.length <= 5) {
          //no more rows to unhide
          const ele = document.querySelector('#load-report-history-btn');
          ele.style.display = 'none';
        }
    };
  
    const ele = document.getElementById("load-report-history-btn");
    if (ele) {
        ele.addEventListener("click", e => {
            loadNext();
        });
    }

    const verifyPhoneBtn = document.getElementById("verify-phone-btn");

    const body = document.body;
    if (verifyPhoneBtn) {
        verifyPhoneBtn.addEventListener("click", () => {
            let modalWrap = document.createElement("div");
            let modalContent = document.createElement("div");
            modalWrap.classList.add("welcome-modal");
            modalContent.classList.add("modal-content");
            modalContent.innerHTML = `
      <img src="/assets/images/new/logos/r_blue.png" alt="" class="my-2r" style="width:75px; height:75px;">
      <h5>Please verify your phone number to earn 5 free credits.</h5>
      <div>
        <h6 class="highlight" style="margin-block-end: 8px;"> Terms of Service</h6>
        <p class="text-sm">By submitting your mobile telephone number to Union Realtime you agree that a representative of Union Realtime can contact you at the number you provide, potentially using automated technology (including texts/SMS messaging) or a pre-recorded message. By clicking “Yes” below, you consent to the use of such technology to deliver messages with marketing and promotional purposes to your mobile telephone number. Standard message and data rates may apply. Reply STOP to unsubscribe.</p>
        <a href="/user-license-agreement" target="_blank" class="c-orange">Read Full Radius+ End User License Agreement</a>
      </div>  
      <br>
      <div>
        <a href="/profile/pre-verify-phone"><button ladda-button class="ladda-button mx-1r"><span class="ladda-label">Yes please!</span></button></a>
        <a href="/profile/pricing"><button ladda-button class="ladda-button mx-1r" style="background-color: #888;"><span class="ladda-label">No, thanks.</span></button></a>
      </div>
      `;
            modalWrap.appendChild(modalContent);
            body.append(modalWrap);
        });
    }

    // let parsedStates = JSON.parse(states.replace(/(&#34;)/gm, '"').replace(/	/g, ""));
  
    parsedStates =  JSON.parse(JSON.stringify(states).replace(/(&#34;)/gm, '"').replace(/	/g, ""))
    console.log(parsedStates)
    const setStateRef = country => {
        const stateMap = {
            "United States": "USA",
            Australia: "AUS",
            "United Kingdom": "GBR",
            Canada: "CAN",
        };
        // this sets params in call to here.com
        profileFormAutocomplete.setCountryCode(stateMap[country]);
        const states = parsedStates[stateMap[country]];
        const stateEle = document.getElementById("state");
        const initValue = stateEle.value
        let first = stateEle.firstElementChild;
        while (first) {
            first.remove();
            first = stateEle.firstElementChild;
        }
        for (const state of states) {
            const opt = document.createElement("option");
            opt.value = state.abbreviation;
            opt.innerHTML = state.name;
            stateEle.appendChild(opt);
        }
      
      if (initValue) {
          stateEle.value = initValue;
        }
    };

    const country = document.getElementById("country").value;
    setStateRef(country);

    document.getElementById("country").addEventListener("change", event => {
        const country = event.target.value;
        setStateRef(country);
    });
}
