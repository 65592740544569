const mapStyle = require("../common/mapStyle.js");
const _ = require("lodash");
const nvd3 = require("nvd3");
const d3 = require("d3");
const moment = require("moment");
const turf = require("@turf/turf");
// const am4core = require("@amcharts/amcharts4/core");
// const am4charts = require("@amcharts/amcharts4/charts");
// const am4themes_animated = require("@amcharts/amcharts4/themes/animated");
// maps

// facility overhead map

const nvd3charts = [];
let center;

const buildPlacePolygonMap = () => {

    const element = "facilityOverheadViewMap";
    const lat = data.facilityOverheadViewMap.facilityOverheadViewMapData.lat;
    const lng = data.facilityOverheadViewMap.facilityOverheadViewMapData.lng;

    mapboxgl.accessToken = "pk.eyJ1IjoiZGlnaXRhbGdsb2JlIiwiYSI6ImNqaGphNmdzdDJkbjIzNm50YmJzYjEzbDkifQ.m4axiz_t3BE3_nQs5iAaIg";

    initializeFacilityOverheadMap(element, [lng, lat]);
}

const drawPolygon = (feature, map) => {

    const geo = {
        type: "FeatureCollection",
        features: [feature]
    };

    map.addSource("polygon", {
        "type": "geojson",
        "data": geo
    });

    map.addLayer({
        "id": "polygon-fills",
        "type": "fill",
        "source": "polygon",
        "layout": {},
        "paint": {
            "fill-color": "#3085D6",
            "fill-opacity": 0.5
        }
    });

    map.addLayer({
        "id": "polygon-lines",
        "type": "line",
        "source": "polygon",
        "layout": {},
        "paint": {
            "line-color": "#3085D6",
            "line-opacity": 1
        }
    });

}

let map;
const initializeFacilityOverheadMap = (container, center) => {

    map = new mapboxgl.Map({
        container: container,
        center: center,
        zoom: 17,
        style: mapStyle.style
    });

    map.on('load', function () {
        drawPolygon(data.facilityOverheadViewMap.facilityOverheadViewMapData.layer1, map);
    });
};

// radius map

let printRequested = false;

const buildRadiusMap = () => {

    const element = "radiusMap";
    const lat = data.originalParams.lat;
    const lng = data.originalParams.lng;
    const isMiles = data.isMiles;
    const isRv = data.isRv

    mapboxgl.accessToken = "pk.eyJ1IjoiZGlnaXRhbGdsb2JlIiwiYSI6ImNqaGphNmdzdDJkbjIzNm50YmJzYjEzbDkifQ.m4axiz_t3BE3_nQs5iAaIg";

    initializeRadiusMap(element, [lng, lat], isMiles, isRv);
}

const drawFacilities = (map, center, isMiles, isRv) => {

  center = center;

  const geo = data.radiusMap.radiusMapData.facilities;

    map.addSource("facilities", {
        "type": "geojson",
        "data": geo
    });

    const supplyIds = [];
    const colorSettings = [
        'match',
        ['get', 'id'],
        supplyIds, '#D02B4F', '#3085D6'
    ];

    map.addLayer({
        "id": "pipeline-layer",
        "type": "circle",
        "source": "facilities",
        "filter": ["in", "g", 16, 256, 2048, 4096],
        "paint": {
            "circle-radius": 7,
            'circle-color': "#3085D6",
            "circle-stroke-width": 1,
        },
    });

    map.addLayer({
        "id": "supply-layer",
        "type": "circle",
        "source": "facilities",
        "filter": ["in", "g", 1, 2, 4, 8192, 8],
        "paint": {
            "circle-radius": 7,
            'circle-color': "#D02B4F",
            "circle-stroke-width": 1,
        },
    });

    map.addLayer({
        id: "number-layer",
        type: "symbol",
        source: "facilities",
        paint: {
            "text-halo-color": "#FFFFFF",
            "text-halo-width": 2
        },
        layout: {
            "text-field": "{o}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-offset": [0, 2],
            "text-anchor": "bottom",
            "text-size": 11
        }
    });

    map.moveLayer("number-layer");

    const options = {
        steps: 80,
        units: 'meters'
    };

    let circle1, circle3, circle5
    if (isMiles) {
        if (isRv) {
            circle1 = turf.circle(turf.point(center), 4828.032, options);
            circle3 = turf.circle(turf.point(center), 8046.720, options);
            circle5 = turf.circle(turf.point(center), 16093.440, options);
        } else {
            circle1 = turf.circle(turf.point(center), 1609.344, options);
            circle3 = turf.circle(turf.point(center), 4828.032, options);
            circle5 = turf.circle(turf.point(center), 8046.720, options);
        }
    } else {
      circle1 = turf.circle(turf.point(center), 3000, options);
      circle3 = turf.circle(turf.point(center), 5000, options);
      circle5 = turf.circle(turf.point(center), 10000, options);
    }

    map.addLayer({
        "id": "circle-outline-1",
        "type": "line",
        "source": {
            "type": "geojson",
            "data": circle1
        },
        "paint": {
            "line-color": "#D02B4F",
            "line-opacity": 1,
            "line-width": 2,
            "line-offset": 5
        },
        "layout": {

        }
    });

    map.addLayer({
        "id": "circle-outline-3",
        "type": "line",
        "source": {
            "type": "geojson",
            "data": circle3
        },
        "paint": {
            "line-color": "#2DB700",
            "line-opacity": 1,
            "line-width": 2,
            "line-offset": 5
        },
        "layout": {

        }
    });


    map.addLayer({
        "id": "circle-outline-5",
        "type": "line",
        "source": {
            "type": "geojson",
            "data": circle5
        },
        "paint": {
            "line-color": "#3085D6",
            "line-opacity": 1,
            "line-width": 2,
            "line-offset": 5
        },
        "layout": {

        }
    });


}

let radiusMap;
const initializeRadiusMap = (container, center, isMiles, isRv) => {
    let zoom = 11;

    if (isRv || !isMiles) {
        zoom = 10;
    }

    radiusMap = new mapboxgl.Map({
        container: container,
        // style: 'mapbox://styles/mapbox/streets-v9',
        center: center,
        zoom: zoom,
        style: mapStyle.style,
        preserveDrawingBuffer: true
    });

    radiusMap.on('load', function () {
        drawFacilities(radiusMap, center, isMiles, isRv);
        radiusMap.focus();

    });
};

// charts

// employment chart

const addEmploymentChart = (zipped, titleName, source, titleEl, sourceElem, chartDiv) => {
    const self = this;
    const uniqueDates = _.sortBy(_.uniq(_.map(zipped, function (r) {
        return r.date;
    })));

    const uniqueCompanies = _.uniq(_.map(zipped, "name"));

    const organized = {};

    _.forEach(uniqueDates, function (dt) {
        _.forEach(uniqueCompanies, function (uc) {
            const rec = _.find(zipped, function (r) {
                return r.name === uc && dt === r["date"];
            });

            if (rec) {
                if (!_.has(organized, dt)) {
                    organized[dt] = {};
                }

                organized[dt][uc] = rec["cbsa_growth"];
            }
        });
    });

    const dataset = [];
    _.forEach(organized, function (o, key) {
        o.dateKey = key;
        dataset.push(o);
    });

    const seriesNames = uniqueCompanies;
    const displayNames = uniqueCompanies;
    const tickerColors = ["#2DB700", "#3085D6"];
    const colorIndex = ["USA", "CBSA"];

    const newData = _.map(seriesNames, function (series, index) {
        return {
            key: displayNames[index],
            color: tickerColors[_.indexOf(colorIndex, series)],
            values: _.map(dataset, (r) => {
                return { x: uniqueDates.indexOf(r.dateKey), y: r[series] };
            })
        };
    });
    let chart = null;
    nvd3.addGraph(function () {
        chart = nvd3.models.lineChart();

        chart.margin({ top: 30, right: 40, bottom: 40, left: 40 });
        chart.showLegend(true);
        chart.tooltip.enabled(false);

        chart.xAxis
            .axisLabel("Date")
            .tickFormat(function (d) {
                return new Date(uniqueDates[d]).toLocaleDateString("en-US", { month: "short", year: "numeric" });
            });

        chart.yAxis
            .tickFormat(function (d) {
                return d3.format(",.1%")(d);
            });

        nvd3.utils.windowResize(chart.update);

        const chartData = d3.select(chartDiv).datum(newData);
        d3.select(sourceElem).text(source);
        d3.select(titleEl).html(titleName);
        chartData.transition().duration(0).call(chart);

        nvd3charts.push(chart);

        return chart;
    });
}

const initializeEmpoymentChart = (countryCode) => {

  let source = ""
  let titleName = "";
    const chartDiv = `#employmentChart`,
        sourceElem = `#md-caption-employment`,
        titleEl = `#employmentChartName`
    if (countryCode === 'USA') {
        source = "Source: US Bureau of Labor Statistics",
        titleName = `${data.employmentChart.cbsa_name} - <br>Employment`;
    } else if (countryCode === 'CAN') {
        source = "Source: Statistics Canada",
        titleName = `${data.employmentChart.name} - <br>Employment`;
    }

    const zipped = data.employmentChart.employment[0];
    addEmploymentChart(zipped, titleName, source, titleEl, sourceElem, chartDiv);

}

// pricing chart

const addPricingChart = (allDates, newData, sourceElem, source, titleEl, titleName, chartDiv) => {
    let chart = null;
    nvd3.addGraph(function () {
        chart = nvd3.models.lineChart();
        chart.margin({ top: 30, right: 40, bottom: 40, left: 40 });
        chart.showLegend(true);
        chart.tooltip.enabled(false);

        chart.xAxis
            .axisLabel("Date")
            .tickFormat(function (d) {
                return new Date(allDates[d]).toLocaleDateString("en-US", { month: "short", year: "numeric" }); // MMM YYYY
            });

        chart.yAxis
            .tickFormat(function (d) {
                return "$" + d3.format(",.2f")(d);
            });

        nvd3.utils.windowResize(chart.update);

        const chartData = d3.select(chartDiv).datum(newData);
        d3.select(sourceElem).text(source);
        d3.select(titleEl).html(titleName);
        chartData.transition().duration(0).call(chart);

        nvd3charts.push(chart);

        return chart;
    });
}

const initializePricingChart = () => {

    const chartDiv = `#pricingChart`,
        sourceElem = `#md-caption-pricing`,
        source = "Source: Union Realtime",
        titleEl = `#pricingChartName`,
        titleName = `${data.pricingChart.cbsa_name} - <br>Pricing`;

    const zipped = data.pricingChart.pricing;
    const allDates = zipped.map((unzip) => unzip.date);
    const displayNames = ["Average Price"];

    const seriesNames = ["avgprice"];
    const tickerColors = ["#3085D6"];
    const newData = seriesNames.map((series, index) => {
        return {
            key: displayNames[index],
            color: tickerColors[index],

            values: zipped.map((r) => {
                return { x: allDates.indexOf(r.date), y: r[series] };
            })
        };
    });

    addPricingChart(allDates, newData, sourceElem, source, titleEl, titleName, chartDiv);
}

// pricing pivot charts

const drawPricingPivotMonthly = (inbound, chartNum) => {
    const chartDiv = `#pricingPivotChart${chartNum}`;

    console.log("drawing pricing pivot over time in buildmonthlyPricingCharts =>", inbound, "chartNum =", chartNum, ", chartDiv =", chartDiv);

    const zipped = inbound;

    const uniqueDates = _.sortBy(_.uniq(_.map(zipped, function (r) {
        return r.date;
    })));

    const uniqueDimensions = _.uniq(_.map(zipped, "dimension"));

    const organized = {};

    _.forEach(uniqueDates, function (dt) {
        _.forEach(uniqueDimensions, function (ud) {
            const rec = _.find(zipped, function (r) {
                return r.dimension === ud && dt === r["date"];
            });

            if (rec) {
                if (!_.has(organized, dt)) {
                    organized[dt] = {};
                }

                organized[dt][ud] = rec["avgprice"];
            }
        });
    });

    const dataset = [];
    _.forEach(organized, function (o, key) {
        o.dateKey = key;
        dataset.push(o);
    });

    const seriesNames = uniqueDimensions;
    const displayNames = uniqueDimensions;
    const tickerColors = ["#D02B4F", "#3085D6", "#67B7DC", "#2DB700", "#8D2BD0", "#CCCC88"];
    const colorIndex = ["5x5", "5x10", "10x10", "10x15", "10x20", "10x25", "10x30"];

    const newData = _.map(seriesNames, function (series, index) {
        return {
            key: displayNames[index],
            color: tickerColors[_.indexOf(colorIndex, series)],
            values: _.map(dataset, (r) => {
                return { x: uniqueDates.indexOf(r.dateKey), y: r[series] };
            })
        };
    });

    let chart = null;

    nvd3.addGraph(function () {
        chart = nvd3.models.lineChart();
        chart.margin({ top: 30, right: 40, bottom: 40, left: 40 });
        chart.showLegend(true);
        chart.tooltip.enabled(false);

        chart.xAxis
            .axisLabel("Date")
            .tickFormat(function (d) {
                return moment(uniqueDates[d]).format("MMM YYYY");
            });

        chart.yAxis
            .tickFormat(function (d) {
                return d3.format(",.0f")(d);
            });

        nvd3.utils.windowResize(chart.update);

        const chartData = d3.select(chartDiv).datum(newData);
        chartData.transition().duration(0).call(chart);

        nvd3charts.push(chart);

        return chart;
    });
}

const drawPricingPivotYoY = (inbound, chartNum) => {

    const chartDiv = `#pricingPivotChart${chartNum}`;

    console.log("drawing pricing pivot over time in buildmonthlyPricingCharts =>", inbound, "chartNum =", chartNum, ", chartDiv =", chartDiv);

    let zipped = inbound;

    zipped = _.map(zipped, z => {
        const date = moment(z.date);
        const oneYearBack = date.subtract(1, "years").format("YYYY-MM-DD");
        const yoy = _.find(zipped, { dimension: z.dimension.toString(), date: oneYearBack.toString() });
        z.yoy = yoy ? yoy["avgprice"] : null;
        z.yoychange = yoy && z["avgprice"] && z["yoy"] && z["avgprice"] !== " " && z["yoy"] != " " ? ((z["avgprice"] - z["yoy"]) / z["avgprice"]).toFixed(4) : null;
        return z;
    });

    zipped = _.filter(zipped, z => z.yoy);

    const uniqueDates = _.sortBy(_.uniq(_.map(zipped, function (r) {
        return r.date;
    })));

    const uniqueDimensions = _.uniq(_.map(zipped, "dimension"));

    const organized = {};
    _.forEach(uniqueDates, function (dt) {
        _.forEach(uniqueDimensions, function (ud) {
            const rec = _.find(zipped, function (r) {
                return r.dimension === ud && dt === r["date"];
            });

            if (rec) {
                if (!_.has(organized, dt)) {
                    organized[dt] = {};
                }

                organized[dt][ud] = +rec["yoychange"];
            }
        });
    });

    const dataset = [];
    _.forEach(organized, function (o, key) {
        o.dateKey = key;
        dataset.push(o);
    });

    const seriesNames = uniqueDimensions;
    const displayNames = uniqueDimensions;
    const tickerColors = ["#D02B4F", "#3085D6", "#67B7DC", "#2DB700", "#8D2BD0", "#CCCC88"];
    const colorIndex = ["5x5", "5x10", "10x10", "10x15", "10x20", "10x25", "10x30"];

    const newData = _.map(seriesNames, function (series, index) {
        return {
            key: displayNames[index],
            color: tickerColors[_.indexOf(colorIndex, series)],
            values: _.map(dataset, (r) => {
                return { x: uniqueDates.indexOf(r.dateKey), y: r[series] };
            })
        };
    });

    let chart = null;

    nvd3.addGraph(function () {
        chart = nvd3.models.lineChart();
        chart.margin({ top: 30, right: 40, bottom: 40, left: 40 });
        chart.showLegend(true);
        chart.tooltip.enabled(false);

        chart.xAxis
            .axisLabel("Date")
            .tickFormat(function (d) {
                return moment(uniqueDates[d]).format("MMM YYYY");
            });

        chart.yAxis
            // .axisLabel("YoY Change By Dimension Category")
            .tickFormat(function (d) {
                return d3.format(".0%")(d);
            });

        nvd3.utils.windowResize(chart.update);

        const chartData = d3.select(chartDiv).datum(newData);
        chartData.transition().duration(0).call(chart);

        nvd3charts.push(chart);

        return chart;
    });
}

const drawLatestPricing = (data, chartNum, places, featureNum) => {

    data = _.filter(data, d => d.featureValues && d.featureValues.length);

    console.log("Data ==>", data);

    const chartDiv = `pricingPivotChart${chartNum}`;
    const source = "Source: Union Realtime",
        sourceElem = `#md-caption-pricing-pivot${chartNum}`,
        titleEl = `#pricingPivotChart${chartNum}Title`;

    am4core.useTheme(am4themes_animated);

    const pricingFeatureCategoryId1 = data[0].featureValues[0].pricingFeatureCategoryId;
    const pricingFeatureCategoryId2 = data[0].featureValues.find(v => v.pricingFeatureCategoryId !== pricingFeatureCategoryId1) ? data[0].featureValues.find(v => v.pricingFeatureCategoryId !== pricingFeatureCategoryId1).pricingFeatureCategoryId : null;

    const dimensions = {
        "1": "5x5",
        "2": "5x10",
        "3": "10x10",
        "4": "10x15",
        "5": "10x20"
    };

    const getFacilityName = (addressId) => {
      let place = _.find(places, function (p) {
        return p.address_id === addressId;
      });
      if (place) {
        place = place.name
        let specialCharsInString = [{
          html :'&amp;',
          char: '&'
        }]
        for (let i = 0; i < specialCharsInString.length; i += 1) {
          if (place.includes(specialCharsInString[i].html)) {
            let formatted = place.replace(specialCharsInString[i].html, specialCharsInString[i].char)
            place = formatted
          } 
        }
      } else {
        place = "(n/a)";
      }
      return place;
    };

    const pricingFeatureCategoryId = featureNum === 1 ? pricingFeatureCategoryId1 : pricingFeatureCategoryId2;

    console.log(`Chart for pricing feat category ID ${pricingFeatureCategoryId}`);

    // find nearest location with pricing
    let nearestLocation;
    for (const location of data) {
        if (location.featureValues.find(v => v.avg)) {
            nearestLocation = location;
            break;
        }
    }

    // format pricing for nearest address to later be retrieved  by dimension IDs
    const nearest = {};
    for (const dimensionCategoryId in dimensions) {
        if (dimensions.hasOwnProperty(dimensionCategoryId)) {
            if (nearestLocation.featureValues.find(n => +n.dimensionCategoryId === +dimensionCategoryId && +n.pricingFeatureCategoryId === +pricingFeatureCategoryId)) {
                nearest[dimensionCategoryId] = nearestLocation.featureValues.find(n => +n.dimensionCategoryId === +dimensionCategoryId && +n.pricingFeatureCategoryId === +pricingFeatureCategoryId);
            }
        }
    }

    // format pricing for all addresses to later be retrieved  by dimension IDs
    const all = [];
    for (const f of data) {
        const obj = {};
        for (const dimensionCategoryId in dimensions) {
            if (dimensions.hasOwnProperty(dimensionCategoryId)) {
                const value = f.featureValues.find(v => +v.dimensionCategoryId === +dimensionCategoryId && +v.pricingFeatureCategoryId === +pricingFeatureCategoryId);
                obj[dimensionCategoryId] = value ? value.last : null;
            }
        }
        all.push(obj);
    }

    console.log(all);

    function findMax(objArray, property) {
        const arr = [];
        for (const o of objArray) {
            arr.push(o[property]);
        }
        const max = Math.max.apply(Math, arr);
        if (max !== 0) {
            return max;
        } else {
            return null;
        }
    }

    function findMin(objArray, property) {
        const arr = [];
        for (const o of objArray) {
            arr.push(o[property]);
        }
        const min = Math.min.apply(Math, arr.map(function (o) {
            return o == null ? Infinity : o;
        }));
        if (min !== Infinity) {
            return min;
        } else {
            return null;
        }
    }

    function findAvg(objArray, property) {
        let arr = [];
        for (const o of objArray) {
            arr.push(o[property]);
        }
        arr = arr.filter(function (o) {
            return !!o;
        });
        const arrAvg = (array) => array.reduce((a, b) => a + b) / array.length;
        if (arr.length > 0) {
            return arrAvg(arr).toFixed(0);
        } else {
            return null;
        }
    }

    console.log(nearest);
    console.log(all);

    // create formatted data to be used by the chart
    const d = [];
    for (const dimensionCategoryId in dimensions) {
      if (dimensions.hasOwnProperty(dimensionCategoryId)) {
        d.push({
          dimension: dimensions[dimensionCategoryId],
          high: findMax(all, dimensionCategoryId),
          low: findMin(all, dimensionCategoryId),
          avg: findAvg(all, dimensionCategoryId),
          nearest: nearest[dimensionCategoryId.toString()].last
        });
      }
    }

    console.log(d);

    // Create chart instance
    const chart = am4core.create(chartDiv, am4charts.XYChart);

    chart.numberFormatter.numberFormat = "$#";

    // Add data
    chart.data = d;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "dimension";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.fontSize = 16;
    categoryAxis.fontWeight = "bold";


    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = -20;
    valueAxis.max = findMax(d, "high") + findMax(d, "high") * 0.2;
    valueAxis.fontSize = 16;

    // Create series
    function createSeries(field1, field2, name) {

        const series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field1;
        series.dataFields.openValueY = field2;
        series.dataFields.categoryX = "dimension";
        series.sequencedInterpolation = true;
        series.clustered = false;

        series.columns.template.width = am4core.percent(35);
        series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:12px]{categoryX}: {valueY}";
        if (field1 === "high") {
            series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:12px]{categoryX}: {openValueY}-{valueY}";
        }
        if (field1 === "avg") {
            series.columns.template.column.fill = am4core.color("#3085d6");
            series.columns.template.column.stroke = am4core.color("#3085d6");
            series.columns.template.column.fillOpacity = 0;
            series.columns.template.propertyFields.stroke = "stroke";
            series.columns.template.propertyFields.strokeWidth = "0";
        }

        if (field1 === "low") {
            series.columns.template.column.stroke = am4core.color("#67b7dc");
            series.columns.template.column.fillOpacity = 0;
        }

        const labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.fontSize = 16;
        labelBullet.label.fontWeight = 600;

        if (field1 === "nearest") {
            const circleBullet = series.bullets.push(new am4charts.CircleBullet());
            circleBullet.circle.stroke = am4core.color("#fff");
            circleBullet.circle.strokeWidth = 2;
            circleBullet.circle.dx = 15;
        }

        if (field1 === "avg") {
            const circleBullet = series.bullets.push(new am4charts.CircleBullet());
            circleBullet.circle.stroke = am4core.color("#fff");
            circleBullet.circle.strokeWidth = 2;
            circleBullet.circle.fill = am4core.color("#3085d6");
            circleBullet.circle.dx = -15;
        }

        // series.columns.template.dx = -20;
        labelBullet.label.dx = 0;
        labelBullet.label.dy = -10;
        labelBullet.label.fill = am4core.color("#D02B4F");
        if (field1 === "avg") {
            labelBullet.label.dy = 0;
            labelBullet.label.dx = 45;
            labelBullet.label.align = "right";
            labelBullet.label.fill = am4core.color("#3085d6");
        }
        if (field1 === "nearest") {
            labelBullet.label.dy = 0;
            labelBullet.label.dx = -45;
            labelBullet.label.align = "left";
            labelBullet.label.fill = am4core.color("#8d2bd0");
        }
        if (field1 === "low") {
            labelBullet.label.dy = 11;
            labelBullet.label.fill = am4core.color("#31C900");
            // labelBullet.label.fontWeight = 400;
        }
        labelBullet.label.hideOversized = false;

        return series;
    }

    createSeries("high", "low", "Range");
    createSeries("low", "low", "Low");
    createSeries("avg", "avg", "Average");
    createSeries("nearest", "nearest", `Nearest (${getFacilityName(nearestLocation.addressId)})`);

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.fontSize = 16;
    chart.legend.data = [chart.legend.data[0], chart.legend.data[2], chart.legend.data[3]];
    chart.legend.data[1].fill = "#3085d6";

    // Responsive
    chart.responsive.useDefault = false;
    chart.responsive.enabled = true;

    chart.responsive.rules.push({
        relevant: function (target) {

            if (target.pixelWidth <= 850) {
                return true;
            }
            return false;
        },
        state: function (target, stateId) {
            if (target instanceof am4charts.Column) {
                const state = target.states.create(stateId);
                state.properties.width = 40;
                return state;
            }
            return null;
        }
    });

}

const initializePricingPivotOverTime = () => {

    const feature1Pricing = data.monthlyPricingCharts.feature1Pricing;
    const feature2Pricing = data.monthlyPricingCharts.feature2Pricing;
    const places = data.pricingPivotFacilitiesTable.places;
    const pricingPivotData = data.pricingPivotFacilitiesTable.pricingResult.facilities;

    drawPricingPivotMonthly(feature1Pricing, 1);
    drawPricingPivotMonthly(feature2Pricing, 2);
    drawPricingPivotYoY(feature1Pricing, 3);
    drawPricingPivotYoY(feature2Pricing, 4);
    drawLatestPricing(pricingPivotData, 5, places, 1);
    drawLatestPricing(pricingPivotData, 6, places, 2);
}

const activeCloseTip = () => {
    document.getElementById("close-report-info-btn").addEventListener("click", e => {
        document.getElementById("report-info-banner").style.display = "none";
    });

    const btnName = window.navigator.platform.toLowerCase().includes("mac") ? "CMD" : "CTRL";

    document.getElementById("key-name").innerHTML = btnName;

    document.getElementById("report-info-banner").style.display = "block";

    document.getElementById("print-report-btn").addEventListener("click", e => {
        window.print();
    });
};

if (page === "compassReport") {

    activeCloseTip();

    data = data.replace(/(&#34;)/gm, '\"').replace(/[\u0000-\u001F\u007F-\u009F]/g, "");

    console.log(data);

    data = JSON.parse(data);

    if (data.facilityOverheadViewMap) {
        buildPlacePolygonMap(data);
    }
  
    let addressComponents = data.originalParams.address.split(',')
    let countryCode = addressComponents[addressComponents.length - 1]
    countryCode = countryCode.trim()
    buildRadiusMap(data);
    initializeEmpoymentChart(countryCode);
    initializePricingChart();
    initializePricingPivotOverTime();

}

if (page === "competitorReport") {

    activeCloseTip();

    data = data.replace(/(&#34;)/gm, '\"').replace(/[\u0000-\u001F\u007F-\u009F]/g, "");

    data = JSON.parse(data);

    initializePricingPivotOverTime();

}

// resize map and charts for print
(function () {
    const redrawCharts = () => {
        for (const c of nvd3charts) {
            c.margin({ top: 30, right: 30, bottom: 40, left: 40 });
            c.update();
        }
        // drawLatestPricing(pricingPivotData, 5, places, 1);
        // drawLatestPricing(pricingPivotData, 6, places, 2);
    };

    const resizeMap = () => {
        for (const c of nvd3charts) {
            c.margin({ top: 30, right: 30, bottom: 40, left: 40 });
            c.update();
        }
        document.getElementById("radiusMap").style["max-width"] = "100%";
        radiusMap.resize();
    };

    if (window.matchMedia) {
        const printMediaQueryList = window.matchMedia('print');
        printMediaQueryList.addListener(function (mql) {
            if (mql.matches) {
                redrawCharts();
                radiusMap.focus();
                document.getElementById("radiusMap").style["max-width"] = "8in";
                radiusMap.resize();
                document.getElementById("report-info-banner").style.display = "none";
            }
        });

    }

    const beforePrint = () => {
        radiusMap.focus();
        redrawCharts();
    }

    window.onbeforeprint = beforePrint;
    window.onafterprint = resizeMap;
}());