let uploadImages = [];

const fixUrl = (url) => {
    if (!!url && !!url.trim()) {
        url = url.trim();
        if (!/^(https?:)?\/\//i.test(url)) {
            url = 'https://' + url;
            return url
        }
    } else {

    }
};

const formArrayToJSON = (arr) => {
    let json = {};
    for (var i = 0; i < arr.length; i++) {
        json[arr[i]['name']] = arr[i]['value'];
    }
    return json;
};

const processFormFiles = (form) => {

    const post_url = $(form).attr("action");
    const request_method = $(form).attr("method");
    let form_data = $(form).serializeArray();

    form_data = formArrayToJSON(form_data);
    form_data["images"] = uploadImages;

    if (form.id === "broker-create-company-form" || form.id === "broker-edit-company-form") {
        // prepend https://
        const websiteInput = document.getElementById("website");
        if (websiteInput) {
            let websiteUrl = websiteInput.value;
            form_data["website"] = fixUrl(websiteUrl);
            switch (form.id) {
                case "broker-create-company-form":
                    form_data["mode"] = "create";
                    break;
                case "broker-edit-company-form":
                    form_data["mode"] = "edit";
                    break;
            }
        }
    }

    const origin = window.location.href;

    $.ajax({
        url: post_url,
        type: "POST",
        data: JSON.stringify(form_data),
        contentType: 'application/json',
        success: function (data) {
            console.log('Sent!');
            window.location = origin;
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.error({
                "Failed posting: ": errorThrown
            });
        }
    });
}


const uploadImage = (files, endpoint, destUrl) => {

    let data = {};
    data["images"] = files;

    // timeout to allow dataURL to be appended
    setTimeout(() => {
        $.ajax({
            url: endpoint,
            type: "POST",
            data: JSON.stringify(data),
            contentType: 'application/json',
            success: function (data) {
                console.log('Sent!');
                window.location = destUrl;
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error({
                    "Failed posting: ": errorThrown
                });
            }
        });
    }, 1000);

};


const initiateDropzone = (container, imageOnly, context = undefined) => {

    if (typeof Dropzone !== 'undefined') {
        Dropzone.autoDiscover = false;
        $(container).dropzone({
            url: "broker-files",
            paramName: function () { return "images"; },
            autoProcessQueue: false,
            uploadMultiple: true,
            parallelUploads: 100,
            maxFiles: 1,
            init: function () {

                const wrapperThis = this;

                this.on("addedfile", function (file) {
                    // add dataURL prop missing from non-image files
                    const fileReader = new FileReader();
                    let base64;
                    fileReader.onload = (fileLoadedEvent) => {
                        base64 = fileLoadedEvent.target.result;
                        file.dataURL = base64;
                    };
                    fileReader.readAsDataURL(file);

                    // Push image to array when added
                    console.log(uploadImages);

                    console.log("Data URL is ", file.dataURL);

                    if (uploadImages.length === 1) {
                        return;
                    }
                    uploadImages.push(file);
                    console.log(uploadImages);

                    if (imageOnly) {
                        if (context === "edit-logo") {
                            const overlayEle = document.getElementById("logo-overlay");
                            uploadImage(uploadImages, "/broker/edit-logo", "/broker");
                            file.previewElement.style.display = "none";
                            if (overlayEle) overlayEle.style.display = "flex";
                            if (overlayEle) overlayEle.style.display = "flex";
                            if (document.getElementById("edit-logo-btn")) document.getElementById("edit-logo-btn").style.display = "none";
                            document.getElementById("loading-logo").style.display = "block";
                        } else if (context === "edit-broker-photo") {
                            const overlayEle = document.getElementById("photo-overlay");
                            uploadImage(uploadImages, "/broker/edit-broker-photo", "/broker");
                            file.previewElement.style.display = "none";
                            if (overlayEle) overlayEle.style.display = "flex";
                            if (document.getElementById("edit-broker-photo-btn")) document.getElementById("edit-broker-photo-btn").style.display = "none";
                            document.getElementById("loading-photo").style.display = "block";
                        }
                    } else {
                        // Add a remove button
                        const removeButton = Dropzone.createElement('<button class="remove-btn"><i class="fas fa-trash-alt"></i></button>');
                        // Handle image removals
                        removeButton.addEventListener("click", function (e) {
                            // Make sure the button click doesn't submit the form
                            e.preventDefault();
                            e.stopPropagation();
                            // Remove the image preview
                            wrapperThis.removeFile(file);
                            // Remove image from array
                            uploadImages = uploadImages.filter(e => e.upload.uuid !== file.upload.uuid);
                        });
                        // Add the button to the file preview element.
                        file.previewElement.appendChild(removeButton);
                    }

                });
            }
        });
    }

}

module.exports = {
    processFormFiles,
    initiateDropzone
}