// import * as nvd3 from "nvd3";
// import * as d3 from "d3";
// import * as _ from "lodash";
// import * as moment from "moment";
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const nvd3 = require("nvd3");
const d3 = require("d3");
const _ = require("lodash");
const moment = require("moment");
// const am4core = require("@amcharts/amcharts4/core");
// const am4charts = require("@amcharts/amcharts4/charts");
// const am4themes_animated = require("@amcharts/amcharts4/themes/animated");

function radiusAmChartTheme(target) {
    if (target instanceof am4core.ColorSet) {
      target.list = [
        am4core.color("#51626F"),
        am4core.color("#A1D88B"),
        am4core.color("#548575"),
      ];
    }
  }

function drawConstructionSpend(data) {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    var chart = am4core.create("construction-spend-chart", am4charts.XYChart);

    chart.data = data;
    chart.numberFormatter.numberFormat = "$#";

    let title = chart.titles.create();
    title.text = "Construction Spend";
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.align = 'start';
    title.color = '#FF8037';

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.fontSize = 12;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.fontSize = 12;
    valueAxis.min = 0;
    valueAxis.max = findMax(data, "dollars");

    function findMax(objArray, property) {
        let arr = [];
        for (let o of objArray) {
            arr.push(o[property]);
        }
        return Math.max.apply(Math, arr);
    }

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "dollars";
    series.dataFields.dateX = "date";
    series.tooltipText = "{value}";

    series.tooltip.pointerOrientation = "vertical";

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.snapToSeries = series;
    chart.cursor.xAxis = dateAxis;

    //chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarX = new am4core.Scrollbar();
}

const drawDevelopmentGrowth = data => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    data = data.slice(0, 100);

    const pipelineStatus = {
        "1": "Planning",
        "2": "Permitted",
        "3": "Under construction",
        "4": "Planning + Permitted",
    };

    let supply = {
        "TOP 1-50": [],
        "TOP 50-100": [],
    };

    for (let i = 0; i < data.length; i++) {
        if (i < 50) {
            supply["TOP 1-50"].push([
                data[i]["name"],
                data[i]["pct_1"],
                data[i]["pct_2"],
                data[i]["pct_3"],
                data[i]["pct_4"],
            ]);
        }
        if (i >= 50) {
            supply["TOP 50-100"].push([
                data[i]["name"],
                data[i]["pct_1"],
                data[i]["pct_2"],
                data[i]["pct_3"],
                data[i]["pct_4"],
            ]);
        }
    }

    supply["TOP 1-50"] = supply["TOP 1-50"].sort(function(a, b) {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] == b[0]) {
            return 0;
        }
        return 1;
    });
    supply["TOP 50-100"] = supply["TOP 50-100"].sort(function(a, b) {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] == b[0]) {
            return 0;
        }
        return 1;
    });

    let generatedData;

    let startStatus = 1;
    let endStatus = 4;
    let currentStatus = 3;
    let colorSet = new am4core.ColorSet();

    let chart = am4core.create("implied-growth-chart", am4charts.RadarChart);
    chart.numberFormatter.numberFormat = "#.0%";
    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 12;

    chart.startAngle = 270 - 180;
    chart.endAngle = 270 + 180;

    chart.padding(0, 0, 0, 150);
    chart.radius = am4core.percent(65);
    chart.innerRadius = am4core.percent(40);

    let title = chart.titles.create();
    title.text = "CBSA Implied Growth";
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.dx = -100;
    title.align = 'start';
    title.color = '#FF8037';

    // year label goes in the middle
    let statusLabel = chart.radarContainer.createChild(am4core.Label);
    statusLabel.horizontalCenter = "middle";
    statusLabel.verticalCenter = "middle";
    statusLabel.fill = am4core.color("#673AB7");
    statusLabel.fontSize = 16;
    statusLabel.wrap = true;
    statusLabel.maxWidth = 100;
    statusLabel.textAlign = "middle";
    statusLabel.text = pipelineStatus[currentStatus];

    // zoomout button
    let zoomOutButton = chart.zoomOutButton;
    zoomOutButton.dx = 0;
    zoomOutButton.dy = 0;
    zoomOutButton.marginBottom = 15;
    zoomOutButton.parent = chart.rightAxesContainer;

    // scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.rightAxesContainer;
    chart.scrollbarX.orientation = "vertical";
    chart.scrollbarX.align = "center";
    chart.scrollbarX.exportable = false;

    // vertical orientation for zoom out button and scrollbar to be positioned properly
    chart.rightAxesContainer.layout = "vertical";
    chart.rightAxesContainer.padding(120, 0, 120, 20);
    chart.rightAxesContainer.marginLeft = 150;

    // category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "country";

    let categoryAxisRenderer = categoryAxis.renderer;
    let categoryAxisLabel = categoryAxisRenderer.labels.template;
    categoryAxisLabel.location = 0.5;
    categoryAxisLabel.radius = 28;
    categoryAxisLabel.relativeRotation = 90;

    categoryAxisRenderer.fontSize = 11;
    categoryAxisRenderer.minGridDistance = 10;
    categoryAxisRenderer.grid.template.radius = -25;
    categoryAxisRenderer.grid.template.strokeOpacity = 0.05;
    categoryAxisRenderer.grid.template.interactionsEnabled = false;

    categoryAxisRenderer.ticks.template.disabled = true;
    categoryAxisRenderer.axisFills.template.disabled = true;
    categoryAxisRenderer.line.disabled = true;

    categoryAxisRenderer.tooltipLocation = 0.5;
    categoryAxis.tooltip.defaultState.properties.opacity = 0;

    // value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 0.15;
    valueAxis.strictMinMax = true;
    valueAxis.tooltip.defaultState.properties.opacity = 0;
    valueAxis.tooltip.animationDuration = 0;
    valueAxis.cursorTooltipEnabled = true;
    valueAxis.zIndex = 10;

    let valueAxisRenderer = valueAxis.renderer;
    valueAxisRenderer.axisFills.template.disabled = true;
    valueAxisRenderer.ticks.template.disabled = true;
    valueAxisRenderer.minGridDistance = 20;
    valueAxisRenderer.grid.template.strokeOpacity = 0.05;

    // series
    let series = chart.series.push(new am4charts.RadarColumnSeries());
    series.columns.template.width = am4core.percent(90);
    series.columns.template.strokeOpacity = 0;
    series.dataFields.valueY = "value" + currentStatus;
    series.dataFields.categoryX = "country";
    series.tooltipText = "{categoryX}:{valueY.value}";
    series.tooltip.background.fill = am4core.color("#fff");
    series.tooltip.label.fill = am4core.color("#000");
    series.tooltip.label.fontSize = 12;

    // this makes columns to be of a different color, depending on value
    series.heatRules.push({
        target: series.columns.template,
        property: "fill",
        minValue: -3,
        maxValue: 6,
        min: am4core.color("#673AB7"),
        max: am4core.color("#F44336"),
        dataField: "valueY",
    });

    // cursor
    let cursor = new am4charts.RadarCursor();
    chart.cursor = cursor;
    cursor.behavior = "zoomX";

    cursor.xAxis = categoryAxis;
    cursor.innerRadius = am4core.percent(40);
    cursor.lineY.disabled = true;

    cursor.lineX.fillOpacity = 0.2;
    cursor.lineX.fill = am4core.color("#000000");
    cursor.lineX.strokeOpacity = 0;
    cursor.fullWidthLineX = true;

    // status slider
    let statusSliderContainer = chart.createChild(am4core.Container);
    statusSliderContainer.layout = "vertical";
    statusSliderContainer.padding(0, 60, 0, 0);
    statusSliderContainer.width = am4core.percent(100);

    let statusSlider = statusSliderContainer.createChild(am4core.Slider);
    statusSlider.events.on("rangechanged", function() {
        updateRadarData(
            startStatus +
                Math.round(statusSlider.start * (endStatus - startStatus))
        );
    });
    statusSlider.orientation = "horizontal";
    statusSlider.start = 0.5;
    statusSlider.exportable = false;

    chart.data = generateRadarData();

    function generateRadarData() {
        let data = [];
        let i = 0;
        for (var continent in supply) {
            let continentData = supply[continent];

            continentData.forEach(function(country) {
                let rawDataItem = { country: country[0] };

                for (var y = 1; y < country.length; y++) {
                    rawDataItem["value" + (startStatus + y - 1)] = country[y];
                }

                data.push(rawDataItem);
            });
            if (continentData.length) {
                createRange(continent, continentData, i);
            }
            i++;
        }

        generatedData = data;
        return data;
    }

    function findMax(objArray, property) {
        let arr = [];
        for (let o of objArray) {
            arr.push(o[property]);
        }
        return Math.max(arr);
    }

    function updateRadarData(status) {
        if (currentStatus != status) {
            currentStatus = status;
            statusLabel.text = pipelineStatus[currentStatus];
            series.dataFields.valueY = "value" + currentStatus;
            valueAxis.max = findMax(generatedData, "value" + currentStatus);
            chart.orderByField = "value" + currentStatus;
            chart.data.sort(function(a, b) {
                if (a[chart.orderByField] > b[chart.orderByField]) {
                    return -1;
                } else if (a[chart.orderByField] == b[chart.orderByField]) {
                    return 0;
                }
                return 1;
            });
            chart.invalidateRawData();
        }
    }

    function createRange(name, continentData, index) {
        let axisRange = categoryAxis.axisRanges.create();
        axisRange.axisFill.interactionsEnabled = true;
        axisRange.text = name;
        // first country
        axisRange.category = continentData[0][0];
        // last country
        axisRange.endCategory = continentData[continentData.length - 1][0];

        // every 3rd color for a bigger contrast
        axisRange.axisFill.fill = colorSet.getIndex(index * 3);
        axisRange.grid.disabled = true;
        axisRange.label.interactionsEnabled = false;
        axisRange.label.bent = true;

        let axisFill = axisRange.axisFill;
        axisFill.innerRadius = -0.001; // almost the same as 100%, we set it in pixels as later we animate this property to some pixel value
        axisFill.radius = -20; // negative radius means it is calculated from max radius
        axisFill.disabled = false; // as regular fills are disabled, we need to enable this one
        axisFill.fillOpacity = 1;
        axisFill.togglable = true;

        axisFill.showSystemTooltip = true;
        axisFill.readerTitle = "click to zoom";
        axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        axisFill.events.on("hit", function(event) {
            let dataItem = event.target.dataItem;
            if (!event.target.isActive) {
                categoryAxis.zoom({ start: 0, end: 1 });
            } else {
                categoryAxis.zoomToCategories(
                    dataItem.category,
                    dataItem.endCategory
                );
            }
        });

        // hover state
        let hoverState = axisFill.states.create("hover");
        hoverState.properties.innerRadius = -10;
        hoverState.properties.radius = -25;

        let axisLabel = axisRange.label;
        axisLabel.location = 0.5;
        axisLabel.fill = am4core.color("#ffffff");
        axisLabel.radius = 3;
        axisLabel.relativeRotation = 0;
    }

    let slider = statusSliderContainer.createChild(am4core.Slider);
    slider.start = 1;
    slider.exportable = false;
    slider.events.on("rangechanged", function() {
        let start = slider.start;

        chart.startAngle = 270 - start * 179 - 1;
        chart.endAngle = 270 + start * 179 + 1;

        valueAxis.renderer.axisAngle = chart.startAngle;
    });
};

const drawCBSADevelopment = data => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    const pipelineStatus = {
        "1": "Planning",
        "2": "Permitted",
        "3": "Under construction",
        "4": "Planning + Permitted",
    };

    data = data.slice(0, 100);

    let supply = {
        "TOP 1-50": [],
        "TOP 50-100": [],
    };

    for (let i = 0; i < data.length; i++) {
        if (i < 50) {
            supply["TOP 1-50"].push([
                data[i]["name"],
                data[i]["total_1"],
                data[i]["total_2"],
                data[i]["total_3"],
                data[i]["total_4"],
            ]);
        }
        if (i >= 50) {
            supply["TOP 50-100"].push([
                data[i]["name"],
                data[i]["total_1"],
                data[i]["total_2"],
                data[i]["total_3"],
                data[i]["total_4"],
            ]);
        }
    }

    supply["TOP 1-50"] = supply["TOP 1-50"].sort(function(a, b) {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] == b[0]) {
            return 0;
        }
        return 1;
    });
    supply["TOP 50-100"] = supply["TOP 50-100"].sort(function(a, b) {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] == b[0]) {
            return 0;
        }
        return 1;
    });

    let generatedData;

    let startStatus = 1;
    let endStatus = 4;
    let currentStatus = 3;
    let colorSet = new am4core.ColorSet();

    let chart = am4core.create("cbsa-development-chart", am4charts.RadarChart);
    chart.numberFormatter.numberFormat = "#";
    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 12;

    chart.startAngle = 270 - 180;
    chart.endAngle = 270 + 180;

    chart.padding(0, 0, 0, 150);
    chart.radius = am4core.percent(65);
    chart.innerRadius = am4core.percent(40);

    let title = chart.titles.create();
    title.text = "CBSA Deliveries";
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.dx = -100;
    title.align = 'start';
    title.color = '#FF8037';

    // year label goes in the middle
    let statusLabel = chart.radarContainer.createChild(am4core.Label);
    statusLabel.horizontalCenter = "middle";
    statusLabel.verticalCenter = "middle";
    statusLabel.fill = am4core.color("#673AB7");
    statusLabel.fontSize = 16;
    statusLabel.wrap = true;
    statusLabel.maxWidth = 100;
    statusLabel.textAlign = "middle";
    statusLabel.text = pipelineStatus[currentStatus];

    // zoomout button
    let zoomOutButton = chart.zoomOutButton;
    zoomOutButton.dx = 0;
    zoomOutButton.dy = 0;
    zoomOutButton.marginBottom = 15;
    zoomOutButton.parent = chart.rightAxesContainer;

    // scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.rightAxesContainer;
    chart.scrollbarX.orientation = "vertical";
    chart.scrollbarX.align = "center";
    chart.scrollbarX.exportable = false;

    // vertical orientation for zoom out button and scrollbar to be positioned properly
    chart.rightAxesContainer.layout = "vertical";
    chart.rightAxesContainer.padding(120, 0, 120, 20);
    chart.rightAxesContainer.marginLeft = 150;

    // category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "country";

    let categoryAxisRenderer = categoryAxis.renderer;
    let categoryAxisLabel = categoryAxisRenderer.labels.template;
    categoryAxisLabel.location = 0.5;
    categoryAxisLabel.radius = 28;
    categoryAxisLabel.relativeRotation = 90;

    categoryAxisRenderer.fontSize = 11;
    categoryAxisRenderer.minGridDistance = 10;
    categoryAxisRenderer.grid.template.radius = -25;
    categoryAxisRenderer.grid.template.strokeOpacity = 0.05;
    categoryAxisRenderer.grid.template.interactionsEnabled = false;

    categoryAxisRenderer.ticks.template.disabled = true;
    categoryAxisRenderer.axisFills.template.disabled = true;
    categoryAxisRenderer.line.disabled = true;

    categoryAxisRenderer.tooltipLocation = 0.5;
    categoryAxis.tooltip.defaultState.properties.opacity = 0;

    // value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    valueAxis.strictMinMax = true;
    valueAxis.tooltip.defaultState.properties.opacity = 0;
    valueAxis.tooltip.animationDuration = 0;
    valueAxis.cursorTooltipEnabled = true;
    valueAxis.zIndex = 10;

    let valueAxisRenderer = valueAxis.renderer;
    valueAxisRenderer.axisFills.template.disabled = true;
    valueAxisRenderer.ticks.template.disabled = true;
    valueAxisRenderer.minGridDistance = 20;
    valueAxisRenderer.grid.template.strokeOpacity = 0.05;

    // series
    let series = chart.series.push(new am4charts.RadarColumnSeries());
    series.columns.template.width = am4core.percent(90);
    series.columns.template.strokeOpacity = 0;
    series.dataFields.valueY = "value" + currentStatus;
    series.dataFields.categoryX = "country";
    series.tooltipText = "{categoryX}:{valueY.value}";
    series.tooltip.background.fill = am4core.color("#fff");
    series.tooltip.label.fill = am4core.color("#000");
    series.tooltip.label.fontSize = 12;

    // this makes columns to be of a different color, depending on value
    series.heatRules.push({
        target: series.columns.template,
        property: "fill",
        minValue: -3,
        maxValue: 6,
        min: am4core.color("#673AB7"),
        max: am4core.color("#F44336"),
        dataField: "valueY",
    });

    // cursor
    let cursor = new am4charts.RadarCursor();
    chart.cursor = cursor;
    cursor.behavior = "zoomX";

    cursor.xAxis = categoryAxis;
    cursor.innerRadius = am4core.percent(40);
    cursor.lineY.disabled = true;

    cursor.lineX.fillOpacity = 0.2;
    cursor.lineX.fill = am4core.color("#000000");
    cursor.lineX.strokeOpacity = 0;
    cursor.fullWidthLineX = true;

    // status slider
    let statusSliderContainer = chart.createChild(am4core.Container);
    statusSliderContainer.layout = "vertical";
    statusSliderContainer.padding(0, 60, 0, 0);
    statusSliderContainer.width = am4core.percent(100);

    let statusSlider = statusSliderContainer.createChild(am4core.Slider);
    statusSlider.events.on("rangechanged", function() {
        updateRadarData(
            startStatus +
                Math.round(statusSlider.start * (endStatus - startStatus))
        );
    });
    statusSlider.orientation = "horizontal";
    statusSlider.start = 0.5;
    statusSlider.exportable = false;

    chart.data = generateRadarData();

    function generateRadarData() {
        let data = [];
        let i = 0;
        for (var continent in supply) {
            let continentData = supply[continent];

            continentData.forEach(function(country) {
                let rawDataItem = { country: country[0] };

                for (var y = 1; y < country.length; y++) {
                    rawDataItem["value" + (startStatus + y - 1)] = country[y];
                }

                data.push(rawDataItem);
            });
            if (continentData.length) {
                createRange(continent, continentData, i);
            }
            i++;
        }

        generatedData = data;
        return data;
    }

    valueAxis.max = findMax(generatedData, "value" + currentStatus);

    function findMax(objArray, property) {
        let arr = [];
        for (let o of objArray) {
            arr.push(o[property]);
        }
        return Math.max(arr);
    }

    function updateRadarData(status) {
        if (currentStatus != status) {
            currentStatus = status;
            statusLabel.text = pipelineStatus[currentStatus];
            series.dataFields.valueY = "value" + currentStatus;
            valueAxis.max = findMax(generatedData, "value" + currentStatus);
            chart.orderByField = "value" + currentStatus;
            chart.data.sort(function(a, b) {
                if (a[chart.orderByField] > b[chart.orderByField]) {
                    return -1;
                } else if (a[chart.orderByField] == b[chart.orderByField]) {
                    return 0;
                }
                return 1;
            });
            chart.invalidateRawData();
        }
    }

    function createRange(name, continentData, index) {
        let axisRange = categoryAxis.axisRanges.create();
        axisRange.axisFill.interactionsEnabled = true;
        axisRange.text = name;
        // first country
        axisRange.category = continentData[0][0];
        // last country
        axisRange.endCategory = continentData[continentData.length - 1][0];

        // every 3rd color for a bigger contrast
        axisRange.axisFill.fill = colorSet.getIndex(index * 3);
        axisRange.grid.disabled = true;
        axisRange.label.interactionsEnabled = false;
        axisRange.label.bent = true;

        let axisFill = axisRange.axisFill;
        axisFill.innerRadius = -0.001; // almost the same as 100%, we set it in pixels as later we animate this property to some pixel value
        axisFill.radius = -20; // negative radius means it is calculated from max radius
        axisFill.disabled = false; // as regular fills are disabled, we need to enable this one
        axisFill.fillOpacity = 1;
        axisFill.togglable = true;

        axisFill.showSystemTooltip = true;
        axisFill.readerTitle = "click to zoom";
        axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        axisFill.events.on("hit", function(event) {
            let dataItem = event.target.dataItem;
            if (!event.target.isActive) {
                categoryAxis.zoom({ start: 0, end: 1 });
            } else {
                categoryAxis.zoomToCategories(
                    dataItem.category,
                    dataItem.endCategory
                );
            }
        });

        // hover state
        let hoverState = axisFill.states.create("hover");
        hoverState.properties.innerRadius = -10;
        hoverState.properties.radius = -25;

        let axisLabel = axisRange.label;
        axisLabel.location = 0.5;
        axisLabel.fill = am4core.color("#ffffff");
        axisLabel.radius = 3;
        axisLabel.relativeRotation = 0;
    }

    let slider = statusSliderContainer.createChild(am4core.Slider);
    slider.start = 1;
    slider.exportable = false;
    slider.events.on("rangechanged", function() {
        let start = slider.start;

        chart.startAngle = 270 - start * 179 - 1;
        chart.endAngle = 270 + start * 179 + 1;

        valueAxis.renderer.axisAngle = chart.startAngle;
    });
};

const drawCBSADevelopmentTopMarkets = data => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    // Create chart instance
    var chart = am4core.create("cbsa-development-top-chart", am4charts.XYChart);

    const d = [];

    const pipelineStatus = {
        "1": "Planning",
        "2": "Permitted",
        "3": "Under construction",
        "4": "Planning + Permitted",
    };

    const startStatus = 1;
    const endStatus = 4;

    for (let i = startStatus; i <= endStatus; i++) {
        const obj = {
            status: pipelineStatus[i],
        };
        for (let c of data) {
            obj[c.name] = c["total_" + i];
        }
        d.push(obj);
    }

    // Add data
    chart.data = d;
    chart.fontSize = 12;

    let title = chart.titles.create();
    title.text = "Self Storage Facilities";
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 30;
    title.align = 'start';
    title.color = '#FF8037';

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "status";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.max = findMax(d);
    valueAxis.fontSize = 12;

    function findMax(objArray) {
        let arr = [];
        for (let o of objArray) {
            arr.push(
                o["All Other Markets"] +
                    o["Top 1-50 CBSA"] +
                    o["Top 50-100 CBSA"]
            );
        }
        return Math.max.apply(Math, arr);
    }

    // Create series
    function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "status";
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText =
            "[bold]{name}[/]\n[font-size:16px]{categoryX}: {valueY}";
        series.tooltip.background.fill = am4core.color("#fff");
        series.tooltip.label.fill = am4core.color("#000");
        series.tooltip.label.fontSize = 12;

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;

        return series;
    }

    function createSeriesWithTotal(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "status";
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText =
            "[bold]{name}[/]\n[font-size:16px]{categoryX}: {valueY}";
        series.tooltip.background.fill = am4core.color("#fff");
        series.tooltip.label.fill = am4core.color("#000");
        series.tooltip.label.fontSize = 12;

        // Enable .total and .totalPercent placeholders for stacked series
        valueAxis.calculateTotals = true;

        // Allow labels to show beyond chart area
        chart.maskBullets = false;

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;

        // Create a LabelBullet for the top, stacked series
        var labelBulletTotal = series.bullets.push(new am4charts.LabelBullet());
        var label = labelBulletTotal.label;
        label.text = "{valueY.total}";
        label.fontWeight = 600;
        label.fill = am4core.color("#D02B4F");
        label.dy = -20;
        label.hideOversized = false;

        return series;
    }

    if (region && region === "canada") {
        createSeries("Top 1-50 CMA", "Top 1-50 CMA");
        createSeries("Top 50-100 CMA", "Top 50-100 CMA");
        createSeriesWithTotal("All Other Markets", "All Other Markets");
    } else {
        createSeries("Top 1-50 CBSA", "Top 1-50 CBSA");
        createSeries("Top 50-100 CBSA", "Top 50-100 CBSA");
        createSeriesWithTotal("All Other Markets", "All Other Markets");
    }

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.fontSize = 12;
};

const drawImpliedSqftGrowth = data => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    const pipelineStatus = {
        "1": "Planning",
        "2": "Permitted",
        "3": "Under construction",
        "4": "Planning + Permitted",
    };

    // Create chart instance
    var chart = am4core.create(
        "implied-square-footage-growth-chart",
        am4charts.XYChart
    );

    const d = [];

    const startStatus = 1;
    const endStatus = 4;

    for (let i = startStatus; i <= endStatus; i++) {
        const obj = {
            status: pipelineStatus[i],
        };
        for (let c of data) {
            obj[c.name] = c["pct_" + i];
        }
        d.push(obj);
    }

    // Add data
    chart.data = d;

    chart.numberFormatter.numberFormat = "#.0%";
    chart.fontSize = 12;

    let title = chart.titles.create();
    title.text = "Implied Square Footage Growth";
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 30;
    title.align = 'start';
    title.color = '#FF8037';

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "status";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.fontSize = 12;

    // Create series
    function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "status";
        series.sequencedInterpolation = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        if (field === "Total U.S." || field === "Total Canada") {
            series.columns.template.dx = -15;
        }
        if (field === "Top 50 CBSA" || field === "Top 50 CMA") {
            series.columns.template.dx = 15;
        }
        series.columns.template.tooltipText =
            "[bold]{name}[/]\n[font-size:16px]{categoryX}: {valueY}";
        series.tooltip.background.fill = am4core.color("#fff");
        series.tooltip.label.fill = am4core.color("#000");
        series.tooltip.label.fontSize = 12;

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;
        if (field === "Total U.S." || field === "Total Canada") {
            labelBullet.dx = -15;
        }
        if (field === "Top 50 CBSA" || field === "Top 50 CMA") {
            labelBullet.dx = 15;
        }

        return series;
    }

    if (region && region === "canada") {
        createSeries("Top 50 CMA", "Top 50 CBSA");
        createSeries("Top 100 CMA", "Top 100 CMA");
        createSeries("Total Canada", "Total Canada");
    } else {
        createSeries("Top 50 CBSA", "Top 50 CBSA");
        createSeries("Top 100 CBSA", "Top 100 CBSA");
        createSeries("Total U.S.", "Total U.S.");
    }

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.fontSize = 12;
};

if (page === "development-activity") {
    data = data.replace(/(&#34;)/gm, '"');
    data = JSON.parse(data);
    drawDevelopmentGrowth(data.detailed);
    drawCBSADevelopment(data.detailed);
    drawImpliedSqftGrowth(data.totalPct);
    drawCBSADevelopmentTopMarkets(data.totalDelivery);
    drawConstructionSpend(data.spend[0]);
}

if (page === "development-activity-canada") {
    data = data.replace(/(&#34;)/gm, '"');
    data = JSON.parse(data);
    drawDevelopmentGrowth(data.detailed);
    drawCBSADevelopment(data.detailed);
    drawImpliedSqftGrowth(data.totalPct);
    drawCBSADevelopmentTopMarkets(data.totalDelivery);
}

function drawEmployment(inbound) {
    console.log("drawEmployment", inbound);

    let chart;
    let chartData;
    let payrollByCompanyData;

    var zipped = _.map(inbound, i => {
        i.date = i.date.toString().split("T")[0];
        return i;
    });
    payrollByCompanyData = zipped;
    var uniqueDates = _.sortBy(
        _.uniq(
            _.map(zipped, function(r) {
                return r.date;
            })
        )
    );

    var uniqueCompanies = _.uniq(_.map(zipped, "name"));

    var organized = {};

    _.forEach(uniqueDates, function(dt) {
        _.forEach(uniqueCompanies, function(uc) {
            var rec = _.find(zipped, function(r) {
                return r.name == uc && dt == r["date"];
            });

            if (rec) {
                if (!_.has(organized, dt)) organized[dt] = {};

                organized[dt][uc] = rec["growth"];
            }
        });
    });

    var dataset = [];
    _.forEach(organized, function(o, key) {
        o.dateKey = key;
        dataset.push(o);
    });

    var seriesNames = uniqueCompanies;
    var displayNames = uniqueCompanies;
    var tickerColors = [
        "#FF6B06",
        "#91C713",
        "#FF0000",
        "#FC5858",
        "#0000FF",
        "#6060FA",
        "#9898F9",
        "#CCCC88",
        "#0000FF",
        "#6060FA",
        "#9898F9",
        "#FAD106",
    ];
    var colorIndex = [
        "Public Storage",
        "Extra Space",
        "CubeSmart (WO)",
        "CubeSmart (JV)",
        "Uncle Bob's (WO)",
        "Uncle Bob's (JV&M)",
        "Life Storage",
        "Employment",
        "Life Storage (WO)",
        "Life Storage (JV&M)",
        "Life Storage (acq. assets)",
        "Canada Employment",
    ];

    var newData = _.map(seriesNames, function(series, index) {
        return {
            key: displayNames[index],
            color: tickerColors[_.indexOf(colorIndex, series)],

            values: _.map(dataset, function(r) {
                return { x: uniqueDates.indexOf(r.dateKey), y: r[series] };
            }),
        };
    });

    nvd3.addGraph(function() {
        chart = nvd3.models
            .lineChart()
            .margin({ top: 30, right: 40, bottom: 40, left: 70 })
            .showLegend(true);

        chart.xAxis.axisLabel("Date").tickFormat(function(d) {
            return moment(uniqueDates[d]).format("MMM YYYY");
        });

        chart.yAxis.axisLabel("Growth YoY").tickFormat(function(d) {
            return d3.format(",.1%")(d);
        });

        nvd3.utils.windowResize(function() {
            chart.update();
        });

        chartData = d3.select("#employment-chart svg").datum(newData);
        chartData
            .transition()
            .duration(0)
            .call(chart);

        return chart;
    });
}

if (page === "employment-growth") {
    data = data.replace(/(&#34;)/gm, '"');
    data = JSON.parse(data);
    drawEmployment(data);
}

function drawCapRateIndex(inbound, eleId) {
    var zipped = inbound;
    let chart;
    let chartData;
    var allDates = _.map(zipped, "date");

    var seriesNames = ["reit_spread", "reit_index", "ten_year"];
    var displayNames = ["Spread to 10-Year", "REIT Index", "10-Year"];
    var tickerColors = ["#FF6B06", "#91C713", "#FF0000", "#0000FF", "#d8e03b"];

    var newData = _.map(seriesNames, function(series, index) {
        return {
            key: displayNames[index],
            color: tickerColors[index],

            values: _.map(zipped, function(r) {
                return { x: allDates.indexOf(r.date), y: r[series] };
            }),
        };
    });

    nvd3.addGraph(function() {
        chart = nvd3.models
            .lineChart()
            .margin({ top: 30, right: 40, bottom: 50, left: 70 });

        chart.xAxis.axisLabel("Date").tickFormat(function(d) {
            return moment(allDates[d]).format("MMM YYYY");
        });

        chart.yAxis.axisLabel("Yield").tickFormat(function(d) {
            return d3.format(",.1%")(d);
        });

        nvd3.utils.windowResize(function() {
            chart.update();
        });

        chartData = d3.select(`#${eleId} svg`).datum(newData);
        chartData
            .transition()
            .duration(0)
            .call(chart);

        return chart;
    });
}

function drawCapRates(inbound, eleId) {
    console.log("drawCapRates", inbound);

    var zipped = inbound;
    let chart;
    let chartData;

    var allDates = _.map(zipped, "date");
    var seriesNames = [
        "psa_cap_rate",
        "exr_cap_rate",
        "cube_cap_rate",
        "lsi_cap_rate",
    ];
    var tickerNames = ["PSA", "EXR", "CUBE", "LSI"];
    var tickerColors = ["#FF6B06", "#91C713", "#FF0000", "#0000FF"];
    var newData = _.map(seriesNames, function(series, index) {
        return {
            key: tickerNames[index],
            color: tickerColors[index],
            values: _.map(zipped, function(r) {
                return { x: allDates.indexOf(r.date), y: r[series] };
            }),
        };
    });

    nvd3.addGraph(function() {
        chart = nvd3.models
            .lineChart()
            .margin({ top: 30, right: 40, bottom: 50, left: 70 });

        chart.xAxis.axisLabel("Date").tickFormat(function(d) {
            return moment(allDates[d]).format("DD MMM YYYY");
        });

        chart.yAxis.axisLabel("Yield").tickFormat(function(d) {
            return d3.format(",.1%")(d);
        });

        nvd3.utils.windowResize(function() {
            chart.update();
        });

        chartData = d3.select(`#${eleId} svg`).datum(newData);
        chartData
            .transition()
            .duration(0)
            .call(chart);

        return chart;
    });
}

if (page === "cap-rate") {
    data = data.replace(/(&#34;)/gm, '"');
    data = JSON.parse(data);
    drawCapRates(data.capRates[0], "cap-rate-chart");
    drawCapRateIndex(data.capRateIndex[0], "cap-rate-index-chart");
}

function drawCompanyMetricChart(
    inbound,
    fieldName,
    axisLabel,
    eleId,
    startQuarter
) {
    var data = inbound;
    let chart;
    let chartData;

    var validRecords = _.filter(data, function(r) {
        return _.isUndefined(startQuarter) || r.quarter_key >= startQuarter;
    });

    var uniqueDates = _.sortBy(
        _.uniq(
            _.map(validRecords, function(r) {
                return r.y + " Q" + r.q;
            })
        )
    );

    var uniqueCompanies = _.uniq(_.map(data, "company_name"));

    var organized = {};

    _.forEach(uniqueDates, function(dt) {
        _.forEach(uniqueCompanies, function(uc) {
            var rec = _.find(data, function(r) {
                var dateKey = r.y + " Q" + r.q;
                return r.company_name == uc && dt == dateKey;
            });

            if (rec) {
                if (!_.has(organized, dt)) organized[dt] = {};

                organized[dt][uc] = rec[fieldName];
            }
        });
    });

    var dataset = [];
    _.forEach(organized, function(o, key) {
        o.dateKey = key;
        dataset.push(o);
    });

    var seriesNames = uniqueCompanies;
    var displayNames = uniqueCompanies;
    var tickerColors = [
        "#FF6B06",
        "#91C713",
        "#FF0000",
        "#FC5858",
        "#0000FF",
        "#6060FA",
        "#9898F9",
        "#CCCC88",
        "#0000FF",
        "#6060FA",
        "#9898F9",
    ];
    var colorIndex = [
        "Public Storage",
        "Extra Space",
        "CubeSmart (WO)",
        "CubeSmart (JV)",
        "Uncle Bob's (WO)",
        "Uncle Bob's (JV&M)",
        "Life Storage",
        "Employment",
        "Life Storage (WO)",
        "Life Storage (JV&M)",
        "Life Storage (acq. assets)",
    ];

    var newData = _.map(seriesNames, function(series, index) {
        return {
            key: displayNames[index],
            color: tickerColors[_.indexOf(colorIndex, series)],

            values: _.map(dataset, function(r) {
                return { x: uniqueDates.indexOf(r.dateKey), y: r[series] };
            }),
        };
    });

    nvd3.addGraph(function() {
        chart = nvd3.models
            .lineChart()
            .margin({ top: 30, right: 40, bottom: 50, left: 70 });

        chart.xAxis.axisLabel("Date").tickFormat(function(d) {
            var dk = uniqueDates[d];
            return dk.substring(0, 4) + " " + dk.substring(5, 7);
        });

        chart.yAxis.axisLabel(axisLabel).tickFormat(function(d) {
            if (fieldName == "percent_change") return d3.format(".1%")(d / 100);
            else return d3.format(".1%")(d);
        });

        nvd3.utils.windowResize(function() {
            chart.update();
        });

        chartData = d3.select(`#${eleId} svg`).datum(newData);
        chartData
            .transition()
            .duration(0)
            .call(chart);

        return chart;
    });
}

if (page === "same-store-metrics") {
    data = data.replace(/(&#34;)/gm, '"');
    data = JSON.parse(data);
    drawCompanyMetricChart(
        data.occupancyOverTime[0],
        "occupancy",
        "Growth YoY",
        "same-store-occupancy-chart"
    );
    drawCompanyMetricChart(
        data.sameStoreMetrics[0],
        "pricing",
        "Growth YoY",
        "same-store-pricing-chart",
        200501
    );
    drawCompanyMetricChart(
        data.sameStoreMetrics[0],
        "rev_yoy",
        "Growth YoY",
        "same-store-revenue-chart",
        201101
    );
    drawCompanyMetricChart(
        data.sameStoreMetrics[0],
        "noi_yoy",
        "Growth YoY",
        "same-store-noi-chart",
        200901
    );
}

const drawSupplyGrowth = (data) => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    data = data.slice(0, 100);

    let supply = {
        "TOP 1-50": [],
        "TOP 50-100": [],
    };

    for (let i = 0; i < data.length; i++) {
        if (i < 50) {
            supply["TOP 1-50"].push([
                data[i]["name"],
                data[i]["pct_2016"],
                data[i]["pct_2017"],
                data[i]["pct_2018"],
                data[i]["pct_2019"],
                data[i]["pct_2020"],
                data[i]["pct_2021"],
                data[i]["pct_2022"],
                data[i]["pct_2023"],
                ,
                data[i]["pct_2024"],
            ]);
        }
        if (i >= 50) {
            supply["TOP 50-100"].push([
                data[i]["name"],
                data[i]["pct_2016"],
                data[i]["pct_2017"],
                data[i]["pct_2018"],
                data[i]["pct_2019"],
                data[i]["pct_2020"],
                data[i]["pct_2021"],
                data[i]["pct_2022"],
                data[i]["pct_2023"],
                ,
                data[i]["pct_2024"],
            ]);
        }
    }

    supply["TOP 1-50"] = supply["TOP 1-50"].sort(function(a, b) {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] == b[0]) {
            return 0;
        }
        return 1;
    });
    supply["TOP 50-100"] = supply["TOP 50-100"].sort(function(a, b) {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] == b[0]) {
            return 0;
        }
        return 1;
    });

    let generatedData;

    let startYear = 2016;
    let endYear = 2024;
    let currentYear = 2021;
    let colorSet = new am4core.ColorSet();

    let chart = am4core.create(
        "supply-growth-chart-main",
        am4charts.RadarChart
    );
    chart.numberFormatter.numberFormat = "#.0%";
    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 12;

    chart.startAngle = 270 - 180;
    chart.endAngle = 270 + 180;

    chart.padding(0, 0, 0, 150);
    chart.radius = am4core.percent(65);
    chart.innerRadius = am4core.percent(40);

    let title = chart.titles.create();
    title.text = "CBSA Supply Growth";
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.dx = -100;
    title.align = 'start';
    title.color = '#FF8037';

    // year label goes in the middle
    let yearLabel = chart.radarContainer.createChild(am4core.Label);
    yearLabel.horizontalCenter = "middle";
    yearLabel.verticalCenter = "middle";
    yearLabel.fill = am4core.color("#673AB7");
    yearLabel.fontSize = 30;
    yearLabel.text = String(currentYear);

    // zoomout button
    let zoomOutButton = chart.zoomOutButton;
    zoomOutButton.dx = 0;
    zoomOutButton.dy = 0;
    zoomOutButton.marginBottom = 15;
    zoomOutButton.parent = chart.rightAxesContainer;

    // scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.rightAxesContainer;
    chart.scrollbarX.orientation = "vertical";
    chart.scrollbarX.align = "center";
    chart.scrollbarX.exportable = false;

    // vertical orientation for zoom out button and scrollbar to be positioned properly
    chart.rightAxesContainer.layout = "vertical";
    chart.rightAxesContainer.padding(120, 0, 120, 20);
    chart.rightAxesContainer.marginLeft = 150;

    // category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "country";

    let categoryAxisRenderer = categoryAxis.renderer;
    let categoryAxisLabel = categoryAxisRenderer.labels.template;
    categoryAxisLabel.location = 0.5;
    categoryAxisLabel.radius = 28;
    categoryAxisLabel.relativeRotation = 90;

    categoryAxisRenderer.fontSize = 11;
    categoryAxisRenderer.minGridDistance = 10;
    categoryAxisRenderer.grid.template.radius = -25;
    categoryAxisRenderer.grid.template.strokeOpacity = 0.05;
    categoryAxisRenderer.grid.template.interactionsEnabled = false;

    categoryAxisRenderer.ticks.template.disabled = true;
    categoryAxisRenderer.axisFills.template.disabled = true;
    categoryAxisRenderer.line.disabled = true;

    categoryAxisRenderer.tooltipLocation = 0.5;
    categoryAxis.tooltip.defaultState.properties.opacity = 0;

    // value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 0.15;
    valueAxis.strictMinMax = true;
    valueAxis.tooltip.defaultState.properties.opacity = 0;
    valueAxis.tooltip.animationDuration = 0;
    valueAxis.cursorTooltipEnabled = true;
    valueAxis.zIndex = 10;

    let valueAxisRenderer = valueAxis.renderer;
    valueAxisRenderer.axisFills.template.disabled = true;
    valueAxisRenderer.ticks.template.disabled = true;
    valueAxisRenderer.minGridDistance = 20;
    valueAxisRenderer.grid.template.strokeOpacity = 0.05;

    // series
    let series = chart.series.push(new am4charts.RadarColumnSeries());
    series.columns.template.width = am4core.percent(90);
    series.columns.template.strokeOpacity = 0;
    series.dataFields.valueY = "value" + currentYear;
    series.dataFields.categoryX = "country";
    series.tooltipText = "{categoryX}:{valueY.value}";
    series.tooltip.background.fill = am4core.color("#fff");
    series.tooltip.label.fill = am4core.color("#000");
    series.tooltip.label.fontSize = 12;

    // this makes columns to be of a different color, depending on value
    series.heatRules.push({
        target: series.columns.template,
        property: "fill",
        minValue: -3,
        maxValue: 6,
        min: am4core.color("#673AB7"),
        max: am4core.color("#F44336"),
        dataField: "valueY",
    });

    // cursor
    let cursor = new am4charts.RadarCursor();
    chart.cursor = cursor;
    cursor.behavior = "zoomX";

    cursor.xAxis = categoryAxis;
    cursor.innerRadius = am4core.percent(40);
    cursor.lineY.disabled = true;

    cursor.lineX.fillOpacity = 0.2;
    cursor.lineX.fill = am4core.color("#000000");
    cursor.lineX.strokeOpacity = 0;
    cursor.fullWidthLineX = true;

    // year slider
    let yearSliderContainer = chart.createChild(am4core.Container);
    yearSliderContainer.layout = "vertical";
    yearSliderContainer.padding(0, 60, 0, 0);
    yearSliderContainer.width = am4core.percent(100);

    let yearSlider = yearSliderContainer.createChild(am4core.Slider);
    yearSlider.events.on("rangechanged", function() {
        updateRadarData(
            startYear + Math.round(yearSlider.start * (endYear - startYear))
        );
    });
    yearSlider.orientation = "horizontal";
    yearSlider.start = 0.75;
    yearSlider.exportable = false;

    chart.data = generateRadarData();

    function generateRadarData() {
        let data = [];
        let i = 0;

        for (var group in supply) {
            let groupData = supply[group];

            groupData.forEach(function(cbsa) {
                let rawDataItem = { country: cbsa[0] };

                for (var y = 1; y < cbsa.length; y++) {
                    rawDataItem["value" + (startYear + y - 1)] = cbsa[y];
                }

                data.push(rawDataItem);
            });
            if (groupData.length) {
                createRange(group, groupData, i);
            }
            i++;
        }

        generatedData = data;
        return data;
    }

    function findMax(objArray, property) {
        let arr = [];
        for (let o of objArray) {
            arr.push(o[property]);
        }
        return Math.max(arr);
    }

    function updateRadarData(year) {
        if (currentYear != year) {
            currentYear = year;
            yearLabel.text = String(currentYear);
            series.dataFields.valueY = "value" + currentYear;
            valueAxis.max = findMax(generatedData, "value" + currentYear);
            chart.orderByField = "value" + currentYear;
            chart.data.sort(function(a, b) {
                if (a[chart.orderByField] > b[chart.orderByField]) {
                    return -1;
                } else if (a[chart.orderByField] == b[chart.orderByField]) {
                    return 0;
                }
                return 1;
            });
            chart.invalidateRawData();
        }
    }

    function createRange(name, continentData, index) {
        let axisRange = categoryAxis.axisRanges.create();
        axisRange.axisFill.interactionsEnabled = true;
        axisRange.text = name;
        // first country
        axisRange.category = continentData[0][0];
        // last country
        axisRange.endCategory = continentData[continentData.length - 1][0];

        // every 3rd color for a bigger contrast
        axisRange.axisFill.fill = colorSet.getIndex(index * 3);
        axisRange.grid.disabled = true;
        axisRange.label.interactionsEnabled = false;
        axisRange.label.bent = true;

        let axisFill = axisRange.axisFill;
        axisFill.innerRadius = -0.001; // almost the same as 100%, we set it in pixels as later we animate this property to some pixel value
        axisFill.radius = -20; // negative radius means it is calculated from max radius
        axisFill.disabled = false; // as regular fills are disabled, we need to enable this one
        axisFill.fillOpacity = 1;
        axisFill.togglable = true;

        axisFill.showSystemTooltip = true;
        axisFill.readerTitle = "click to zoom";
        axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        axisFill.events.on("hit", function(event) {
            let dataItem = event.target.dataItem;
            if (!event.target.isActive) {
                categoryAxis.zoom({ start: 0, end: 1 });
            } else {
                categoryAxis.zoomToCategories(
                    dataItem.category,
                    dataItem.endCategory
                );
            }
        });

        // hover state
        let hoverState = axisFill.states.create("hover");
        hoverState.properties.innerRadius = -10;
        hoverState.properties.radius = -25;

        let axisLabel = axisRange.label;
        axisLabel.location = 0.5;
        axisLabel.fill = am4core.color("#ffffff");
        axisLabel.radius = 3;
        axisLabel.relativeRotation = 0;
    }

    let slider = yearSliderContainer.createChild(am4core.Slider);
    slider.start = 1;
    slider.exportable = false;
    slider.events.on("rangechanged", function() {
        let start = slider.start;

        chart.startAngle = 270 - start * 179 - 1;
        chart.endAngle = 270 + start * 179 + 1;

        valueAxis.renderer.axisAngle = chart.startAngle;
    });
};

const drawCBSADeliveries = data => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    data = data.slice(0, 100);
    console.log("Data ===> ", data);
    let supply = {
        "TOP 1-50": [],
        "TOP 50-100": [],
    };

    for (let i = 0; i < data.length; i++) {
        if (i < 50) {
            supply["TOP 1-50"].push([
                data[i]["name"],
                data[i]["delivery_2016"],
                data[i]["delivery_2017"],
                data[i]["delivery_2018"],
                data[i]["delivery_2019"],
                data[i]["delivery_2020"],
                data[i]["delivery_2021"],
                data[i]["delivery_2022"],
                data[i]["delivery_2023"],
                ,
                data[i]["delivery_2024"],
            ]);
        }
        if (i >= 50) {
            supply["TOP 50-100"].push([
                data[i]["name"],
                data[i]["delivery_2016"],
                data[i]["delivery_2017"],
                data[i]["delivery_2018"],
                data[i]["delivery_2019"],
                data[i]["delivery_2020"],
                data[i]["delivery_2021"],
                data[i]["delivery_2022"],
                data[i]["delivery_2023"],
                ,
                data[i]["delivery_2024"],
            ]);
        }
    }

    supply["TOP 1-50"] = supply["TOP 1-50"].sort(function(a, b) {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] == b[0]) {
            return 0;
        }
        return 1;
    });
    supply["TOP 50-100"] = supply["TOP 50-100"].sort(function(a, b) {
        if (a[0] < b[0]) {
            return -1;
        } else if (a[0] == b[0]) {
            return 0;
        }
        return 1;
    });

    let generatedData;

    let startYear = 2016;
    let endYear = 2024;
    let currentYear = 2021;
    let colorSet = new am4core.ColorSet();

    let chart = am4core.create("cbsa-deliveries-chart", am4charts.RadarChart);
    chart.numberFormatter.numberFormat = "#";
    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 12;

    chart.startAngle = 270 - 180;
    chart.endAngle = 270 + 180;

    chart.padding(0, 0, 0, 150);
    chart.radius = am4core.percent(65);
    chart.innerRadius = am4core.percent(40);

    let title = chart.titles.create();
    title.text = "CBSA Deliveries";
    if (region && region === "canada") {
        title.text = "CMA Deliveries";
    }
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 10;
    title.dx = -100;
    title.align = 'start';
    title.color = '#FF8037';

    // year label goes in the middle
    let yearLabel = chart.radarContainer.createChild(am4core.Label);
    yearLabel.horizontalCenter = "middle";
    yearLabel.verticalCenter = "middle";
    yearLabel.fill = am4core.color("#673AB7");
    yearLabel.fontSize = 30;
    yearLabel.text = String(currentYear);

    // zoomout button
    let zoomOutButton = chart.zoomOutButton;
    zoomOutButton.dx = 0;
    zoomOutButton.dy = 0;
    zoomOutButton.marginBottom = 15;
    zoomOutButton.parent = chart.rightAxesContainer;

    // scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.rightAxesContainer;
    chart.scrollbarX.orientation = "vertical";
    chart.scrollbarX.align = "center";
    chart.scrollbarX.exportable = false;

    // vertical orientation for zoom out button and scrollbar to be positioned properly
    chart.rightAxesContainer.layout = "vertical";
    chart.rightAxesContainer.padding(120, 0, 120, 20);
    chart.rightAxesContainer.marginLeft = 150;

    // category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "country";

    let categoryAxisRenderer = categoryAxis.renderer;
    let categoryAxisLabel = categoryAxisRenderer.labels.template;
    categoryAxisLabel.location = 0.5;
    categoryAxisLabel.radius = 28;
    categoryAxisLabel.relativeRotation = 90;

    categoryAxisRenderer.fontSize = 11;
    categoryAxisRenderer.minGridDistance = 10;
    categoryAxisRenderer.grid.template.radius = -25;
    categoryAxisRenderer.grid.template.strokeOpacity = 0.05;
    categoryAxisRenderer.grid.template.interactionsEnabled = false;

    categoryAxisRenderer.ticks.template.disabled = true;
    categoryAxisRenderer.axisFills.template.disabled = true;
    categoryAxisRenderer.line.disabled = true;

    categoryAxisRenderer.tooltipLocation = 0.5;
    categoryAxis.tooltip.defaultState.properties.opacity = 0;

    // value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    valueAxis.strictMinMax = true;
    valueAxis.tooltip.defaultState.properties.opacity = 0;
    valueAxis.tooltip.animationDuration = 0;
    valueAxis.cursorTooltipEnabled = true;
    valueAxis.zIndex = 10;

    let valueAxisRenderer = valueAxis.renderer;
    valueAxisRenderer.axisFills.template.disabled = true;
    valueAxisRenderer.ticks.template.disabled = true;
    valueAxisRenderer.minGridDistance = 20;
    valueAxisRenderer.grid.template.strokeOpacity = 0.05;

    // series
    let series = chart.series.push(new am4charts.RadarColumnSeries());
    series.columns.template.width = am4core.percent(90);
    series.columns.template.strokeOpacity = 0;
    series.dataFields.valueY = "value" + currentYear;
    series.dataFields.categoryX = "country";
    series.tooltipText = "{categoryX}:{valueY.value}";
    series.tooltip.background.fill = am4core.color("#fff");
    series.tooltip.label.fill = am4core.color("#000");
    series.tooltip.label.fontSize = 12;

    // this makes columns to be of a different color, depending on value
    series.heatRules.push({
        target: series.columns.template,
        property: "fill",
        minValue: -3,
        maxValue: 6,
        min: am4core.color("#673AB7"),
        max: am4core.color("#F44336"),
        dataField: "valueY",
    });

    // cursor
    let cursor = new am4charts.RadarCursor();
    chart.cursor = cursor;
    cursor.behavior = "zoomX";

    cursor.xAxis = categoryAxis;
    cursor.innerRadius = am4core.percent(40);
    cursor.lineY.disabled = true;

    cursor.lineX.fillOpacity = 0.2;
    cursor.lineX.fill = am4core.color("#000000");
    cursor.lineX.strokeOpacity = 0;
    cursor.fullWidthLineX = true;

    // year slider
    let yearSliderContainer = chart.createChild(am4core.Container);
    yearSliderContainer.layout = "vertical";
    yearSliderContainer.padding(0, 60, 0, 0);
    yearSliderContainer.width = am4core.percent(100);

    let yearSlider = yearSliderContainer.createChild(am4core.Slider);
    yearSlider.events.on("rangechanged", function() {
        updateRadarData(
            startYear + Math.round(yearSlider.start * (endYear - startYear))
        );
    });
    yearSlider.orientation = "horizontal";
    yearSlider.start = 0.75;
    yearSlider.exportable = false;

    chart.data = generateRadarData();

    function generateRadarData() {
        let data = [];
        let i = 0;
        for (var continent in supply) {
            let continentData = supply[continent];

            continentData.forEach(function(country) {
                let rawDataItem = { country: country[0] };

                for (var y = 1; y < country.length; y++) {
                    rawDataItem["value" + (startYear + y - 1)] = country[y];
                }

                data.push(rawDataItem);
            });
            if (continentData.length) {
                createRange(continent, continentData, i);
            }
            i++;
        }

        generatedData = data;
        return data;
    }

    valueAxis.max = findMax(generatedData, "value" + currentYear);

    function updateData() {
        let data = [];
        let i = 0;
        for (var continent in supply) {
            let continentData = supply[continent];

            continentData.forEach(function(country) {
                let rawDataItem = { country: country[0] };

                for (var y = 1; y < country.length; y++) {
                    rawDataItem["value" + (startYear + y - 1)] = country[y];
                }

                data.push(rawDataItem);
            });
        }

        generatedData = data;
        return data;
    }

    function findMax(objArray, property) {
        let arr = [];
        for (let o of objArray) {
            arr.push(o[property]);
        }
        return Math.max(arr);
    }

    function updateRadarData(year) {
        if (currentYear != year) {
            currentYear = year;
            yearLabel.text = String(currentYear);
            series.dataFields.valueY = "value" + currentYear;
            valueAxis.max = findMax(generatedData, "value" + currentYear);
            chart.orderByField = "value" + currentYear;
            chart.data.sort(function(a, b) {
                if (a[chart.orderByField] > b[chart.orderByField]) {
                    return -1;
                } else if (a[chart.orderByField] == b[chart.orderByField]) {
                    return 0;
                }
                return 1;
            });
            chart.invalidateRawData();
        }
    }

    function createRange(name, continentData, index) {
        let axisRange = categoryAxis.axisRanges.create();
        axisRange.axisFill.interactionsEnabled = true;
        axisRange.text = name;
        // first country
        axisRange.category = continentData[0][0];
        // last country
        axisRange.endCategory = continentData[continentData.length - 1][0];

        // every 3rd color for a bigger contrast
        axisRange.axisFill.fill = colorSet.getIndex(index * 3);
        axisRange.grid.disabled = true;
        axisRange.label.interactionsEnabled = false;
        axisRange.label.bent = true;

        let axisFill = axisRange.axisFill;
        axisFill.innerRadius = -0.001; // almost the same as 100%, we set it in pixels as later we animate this property to some pixel value
        axisFill.radius = -20; // negative radius means it is calculated from max radius
        axisFill.disabled = false; // as regular fills are disabled, we need to enable this one
        axisFill.fillOpacity = 1;
        axisFill.togglable = true;

        axisFill.showSystemTooltip = true;
        axisFill.readerTitle = "click to zoom";
        axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        axisFill.events.on("hit", function(event) {
            let dataItem = event.target.dataItem;
            if (!event.target.isActive) {
                categoryAxis.zoom({ start: 0, end: 1 });
            } else {
                categoryAxis.zoomToCategories(
                    dataItem.category,
                    dataItem.endCategory
                );
            }
        });

        // hover state
        let hoverState = axisFill.states.create("hover");
        hoverState.properties.innerRadius = -10;
        hoverState.properties.radius = -25;

        let axisLabel = axisRange.label;
        axisLabel.location = 0.5;
        axisLabel.fill = am4core.color("#ffffff");
        axisLabel.radius = 3;
        axisLabel.relativeRotation = 0;
    }

    let slider = yearSliderContainer.createChild(am4core.Slider);
    slider.start = 1;
    slider.exportable = false;
    slider.events.on("rangechanged", function() {
        let start = slider.start;

        chart.startAngle = 270 - start * 179 - 1;
        chart.endAngle = 270 + start * 179 + 1;

        valueAxis.renderer.axisAngle = chart.startAngle;
    });
};

const drawStorageDeliveries = data => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    // Create chart instance
    var chart = am4core.create(
        "self-storage-deliveries-chart",
        am4charts.XYChart
    );

    const d = [];

    const startYear = 2017;
    const endYear = 2024;

    for (let i = startYear; i <= endYear; i++) {
        const obj = {
            year: i.toString(),
        };
        for (let c of data) {
            obj[c.name] = c["delivery_" + i];
        }
        d.push(obj);
    }

    // Add data
    chart.data = d;
    chart.fontSize = 12;

    let title = chart.titles.create();
    title.text = "Self Storage Deliveries";
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 50;
    title.align = 'start';
    title.color = am4core.color('#FF8037');

    function getMaxValue(data) {
        let max = 0;
        for (const item of data) {
            let total = 0;
            for (const key in item) {
                if (key !== "year") {
                    total += item[key];
                }
            }
            if (total > max) {
                max = total;
            }
        }
        return max;
    }

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.max = getMaxValue(d);
    valueAxis.fontSize = 12;

    // Create series
    function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "year";
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:16px]{categoryX}: {valueY}";
        series.tooltip.background.fill = am4core.color("#fff");
        series.tooltip.label.fill = am4core.color("#000");
        series.tooltip.label.fontSize = 12;

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;

        series.columns.template.events.on("sizechanged", function(ev) {
            if (ev.target.dataItem && ev.target.dataItem.bullets) {
                var height = ev.target.pixelHeight;
                ev.target.dataItem.bullets.each(function(id, bullet) {
                    if (height > 10) {
                        bullet.show();
                    } else {
                        bullet.hide();
                    }
                });
            }
        });

        return series;
    }

    function createSeriesWithTotal(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "year";
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText =
            "[bold]{name}[/]\n[font-size:16px]{categoryX}: {valueY}";
        series.tooltip.background.fill = am4core.color("#fff");
        series.tooltip.label.fill = am4core.color("#000");
        series.tooltip.label.fontSize = 12;

        // Enable .total and .totalPercent placeholders for stacked series
        valueAxis.calculateTotals = true;

        // Allow labels to show beyond chart area
        chart.maskBullets = false;

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;

        // Create a LabelBullet for the top, stacked series
        const totalBullet = new am4charts.LabelBullet();
        var labelBulletTotal = series.bullets.push(totalBullet);
        var label = labelBulletTotal.label;
        label.text = "{valueY.total}";
        label.fontWeight = 600;
        label.fill = am4core.color("#D02B4F");
        label.dy = -20;
        label.hideOversized = false;
        label.name = "total";
        label.configField = "total";


        series.columns.template.events.on("sizechanged", function(ev) {
            if (ev.target.dataItem && ev.target.dataItem.bullets) {
                var height = ev.target.pixelHeight;
                ev.target.dataItem.bullets.each(function(id, bullet) {
                
                    if (height > 10) {
                        bullet.show();
                    } else {
                        if (bullet.label.configField !== "total") {
                            bullet.hide();
                        }
                        
                    }
                });
                totalBullet.show();
            }
            totalBullet.show();
        });

        // series.columns.template.events.on("sizechanged", function(ev) {
        //     if (ev.target.dataItem && ev.target.dataItem.bullets) {
        //         var height = ev.target.pixelHeight;
        //         const bullet = ev.target.dataItem.bullets[0];
        //         if (bullet && height > 20) {
        //             bullet.show();
        //         } else if (bullet) {
        //             bullet.hide();
        //         }
        //     }
        // });

        totalBullet.show();

        return series;
    }

    if (region && region === "canada") {
        createSeries("Top 1-50 CMA", "Top 1-50 CMA");
        createSeries("Top 50-100 CMA", "Top 50-100 CMA");
        createSeriesWithTotal("All Other Markets", "All Other Markets");
    } else {
        createSeries("Top 1-50 CBSA", "Top 1-50 CBSA");
        createSeries("Top 50-100 CBSA", "Top 50-100 CBSA");
        createSeriesWithTotal("All Other Markets", "All Other Markets");
    }

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.fontSize = 12;
};

const drawSqftGrowth = data => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    // Create chart instance
    var chart = am4core.create(
        "square-footage-growth-chart",
        am4charts.XYChart
    );

    const d = [];

    const startYear = 2017;
    const endYear = 2024;

    for (let i = startYear; i <= endYear; i++) {
        const obj = {
            year: i.toString(),
        };
        for (let c of data) {
            obj[c.name] = c["pct_" + i];
        }
        d.push(obj);
    }

    // Add data
    chart.data = d;
    chart.numberFormatter.numberFormat = "#.0%";
    chart.fontSize = 12;

    let title = chart.titles.create();
    title.text = "Square Footage Growth";
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 30;
    title.align = 'start';
    title.color = '#FF8037';

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.fontSize = 12;

    // Create series
    function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "year";
        series.sequencedInterpolation = true;

        // Configure columns
        series.columns.template.width = am4core.percent(80);
        if (field === "Total U.S." || field === "Total Canada") {
            series.columns.template.dx = -5;
        }
        if (field === "Top 50 CBSA" || field === "Top 50 CMA") {
            series.columns.template.dx = 5;
        }
        // Configure pop-up when hovering over bar with cursor
        series.columns.template.tooltipText =
            "[bold]{name}[/]\n[font-size:16px]{categoryX}: {valueY}";
        series.tooltip.background.fill = am4core.color("#fff");
        series.tooltip.label.fill = am4core.color("#000");
        series.tooltip.label.fontSize = 12;

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;
        if (field === "Total U.S." || field === "Total Canada") {
            labelBullet.dx = -4.5;
        }
        if (field === "Top 50 CBSA" || field === "Top 50 CMA") {
            labelBullet.dx = 4.5;
        }
        labelBullet.label.dy = -20;

        console.log(series);

        return series;
    }

    if (region && region === "canada") {
        createSeries("Top 50 CMA", "Top 50 CMA");
        createSeries("Top 100 CMA", "Top 100 CMA");
        createSeries("Total Canada", "Total Canada");
    } else {
        createSeries("Top 50 CBSA", "Top 50 CBSA");
        createSeries("Top 100 CBSA", "Top 100 CBSA");
        createSeries("Total U.S.", "Total U.S.");
    }

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.fontSize = 12;

    // chart.legend.dx = 50;
};

if (page === "supply-growth" || page === "supply-growth-canada") {
    detailed = detailed.replace(/(&#34;)/gm, '"');
    detailed = JSON.parse(detailed);
    totalPct = totalPct.replace(/(&#34;)/gm, '"');
    totalPct = JSON.parse(totalPct);
    totalDelivery = totalDelivery.replace(/(&#34;)/gm, '"');
    totalDelivery = JSON.parse(totalDelivery);
    drawSupplyGrowth(detailed);
    drawCBSADeliveries(detailed);
    drawSqftGrowth(totalPct);
    drawStorageDeliveries(totalDelivery);
}

const drawCBSA = data => {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(radiusAmChartTheme);

    var chart = am4core.create("cbsa-chart", am4charts.XYChart);

    data = _.orderBy(data, ["sqft_capita"], ["asc"]);

    chart.data = data;

    chart.numberFormatter.numberFormat = "#.0";

    let title = chart.titles.create();
    title.text = "Square Footage per Capita";
    title.fontSize = 24;
    title.fontWeight = 600;
    title.marginBottom = 30;
    title.align = 'start';
    title.color = '#FF8037';

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.title.text = "Top 50 Markets";
    categoryAxis.title.fontSize = 14;
    categoryAxis.title.fontWeight = 600;
    categoryAxis.title.marginTop = 30;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.labels.template.adapter.add("dy", function(
        dy,
        target
    ) {
        if (target.dataItem && target.dataItem.index & (2 == 2)) {
            return dy + 25;
        }
        return dy;
    });

    categoryAxis.renderer.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = data[data.length - 1]["sqft_capita"] + 1;
    valueAxis.fontSize = 12;

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "sqft_capita";
    series.dataFields.categoryX = "name";
    series.name = "Square Footage per Capita";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#fff");
    series.tooltip.label.fill = am4core.color("#000");
    series.tooltip.label.fontSize = 12;

    var range = valueAxis.axisRanges.create();
    range.value = calculateGuide();
    range.grid.stroke = am4core.color("#D02B4F");
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 1;

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 0;
    columnTemplate.strokeOpacity = 1;

    function calculateGuide() {
        let totalSqft = 0;
        let totalPop = 0;
        for (let c of data) {
            totalSqft += c.supply_sqft;
            totalPop += c.population;
        }
        return totalSqft / totalPop;
    }
};

if (page === "cbsa") {
    data = data.replace(/(&#34;)/gm, '"');
    data = JSON.parse(data);
    drawCBSA(data);
}
