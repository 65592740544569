const environmentFile = require("../environments/environment.js");
const referenceService = require("../common/referenceService.js");

let autocompleteCountyCode = "USA";

const setCountryCode = (countryCode) => {
    autocompleteCountyCode = countryCode;
};

const findForm = () => {
    let form = null;
    form = document.getElementById('profile-form') || document.getElementById('pricing-form')
        || document.getElementById('reports-compass-form') || document.getElementById('subscription-checkout-form');
    return form;
}

const form = findForm();
const addressInput = document.getElementById("line1");

let labels = [];
let addrComponents = [];
let currentFocus;

const autocomplete = (inp, arr, addrComponents) => {
    inp.addEventListener("input", function (e) {
        var a, b, i, val = this.value;
        closeAllLists();
        if (!val) { return false; }
        currentFocus = -1;
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "-autocomplete-list");
        a.setAttribute("class", "autocomplete-items autocomplete-w-75");
        this.parentNode.appendChild(a);
        for (i = 0; i < arr.length; i++) {
            b = document.createElement("DIV");
            b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
            b.innerHTML += arr[i].substr(val.length);
            b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
            const autocompleteAddress = addrComponents[i];
            b.addEventListener("click", function (e) {
                const referenceStates = referenceService.states[autocompleteAddress.countryName];
                // Breaks autocomplete when labels are removed
                // if (autocompleteAddress.country === "United Kingdom") {
                //     document.getElementById("state-label").textContent = "Country"
                // } else {
                //     document.getElementById("state-label").textContent = "State"
                // }
                document.getElementById("state").options.length = 0;
                for (let j = 0; j < referenceStates.length; j++) {
                    const state = referenceStates[j];
                    var option = document.createElement("option");
                    option.text = state.name;
                    option.value = state.abbreviation;
                    document.getElementById("state").add(option);
                }
                $("#line1").val(autocompleteAddress.houseNumber + ' ' + autocompleteAddress.street);
                $("#city").val(autocompleteAddress.city);
                $("#postalCode").val(autocompleteAddress.postalCode);
                $("#state").val(autocompleteAddress.stateCode);
                $("#country").val(autocompleteAddress.countryName);
                closeAllLists();
            });
            a.appendChild(b);
        }
    });


    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
}

const getSuggestions = (address) => {
    const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${address}&in=countryCode:${autocompleteCountyCode}&apiKey=${environmentFile.environment.hereApiKey}`;

    $.get(url, (response) => {
        const suggestions = response["items"];
        labels = [];
        addrComponents = [];
        for (let i = 0; i < suggestions.length; i++) {
            if (suggestions[i].address.postalCode) {
                suggestions[i].address.postalCode = suggestions[i].address.postalCode.split("-")[0]
            }
            if (suggestions[i].address
                && suggestions[i].address.houseNumber
                && suggestions[i].address.street
                && suggestions[i].address.city
                && suggestions[i].address.stateCode
                && suggestions[i].address.postalCode) {
                const label = suggestions[i].address.houseNumber + ' ' + suggestions[i].address.street + ', ' + suggestions[i].address.city + ', ' + suggestions[i].address.stateCode + ' ' + suggestions[i].address.postalCode + ', ' + suggestions[i].address.countryName;
                labels.push(label);
                addrComponents.push(suggestions[i].address);
            }
        }
        autocomplete(document.getElementById("line1"), labels, addrComponents);
    });
};

if (form && addressInput) {

    addressInput.addEventListener("input", (e) => {
        let query;
        if (e.path) {
            query = e.path[0].value;
        } else {
            query = e.composedPath()[0].value;
        }
        if (query.length > 0) {
            getSuggestions(query);
        }
    });

    /*execute a function presses a key on the keyboard:*/
    addressInput.addEventListener("keydown", function (e) {
        var x = document.getElementById(this.id + "-autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode == 40) {
            currentFocus++;
            addActive(x);
        } else if (e.keyCode == 38) {
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click();
            }
        }
    });

    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }

    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

}

module.exports = {
    setCountryCode,
}