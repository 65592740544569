const laddaStart = require("../common/laddaStart.js");

$("#monthy-report-widget-btn").click(function(e) {
   const email = $("#monthy-report-widget-email").val();
   const event = e;
   laddaStart.startLadda(event.currentTarget);
   $.ajax({
    type: 'POST',
    data: JSON.stringify({email}),
    contentType: 'application/json',
    url: '/email/monthly-download-request-widget',
    success: function (data) {
        console.log('success');
        event.currentTarget.laddaInstance.stop();
        const label = event.currentTarget.children[0];
        label.style = "display:block";
    },
    error: function (jqXHR, textStatus, errorThrown) {
        event.currentTarget.laddaInstance.stop();
        const label = event.currentTarget.children[0];
        label.style = "display:block";
        console.error({
            "Failed to cancel sub: ": errorThrown
        });
    }
});
});