let uploadImages = [];
let uploadFiles = [];
let keywords = [];
let photosToDelete = [];
let docsToDelete = [];

const findForm = () => {
    let form = null;
    form = document.getElementById('lf-uploads') || document.getElementById('edit-listing-form');
    return form;
}

const form = findForm();

const formArrayToJSON = (arr) => {
    let json = {};
    for (var i = 0; i < arr.length; i++) {
        json[arr[i]['name']] = arr[i]['value'];
    }
    return json;
};

const fixUrl = (url) => {
    if (!!url && !!url.trim()) {
        url = url.trim();
        if (!/^(https?:)?\/\//i.test(url)) {
            url = 'https://' + url;
            return url
        }
    } else {

    }
};

const contactForm = document.getElementById('lf-contacts');

if (contactForm) {

    // phone number format

    let filter = [];

    const keypadZero = 48;
    const numpadZero = 96;

    for (let i = 0; i <= 9; i++) {
        filter.push(i + keypadZero);
        filter.push(i + numpadZero);
    }

    filter.push(8);
    filter.push(9);
    filter.push(46);
    filter.push(37);
    filter.push(39);

    // check if key is allowed
    const onKeyDown = (e) => {
        if (filter.indexOf(e.keyCode) < 0) {
            e.preventDefault();
            return false;
        }
    }

    function formatPhoneText(value) {
        value = value.trim().replace(/-/g, "");

        if (value.length > 3 && value.length <= 6)
            value = value.slice(0, 3) + "-" + value.slice(3);
        else if (value.length > 6)
            value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);

        return value;
    }


    const validatePhone = (p) => {
        let phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
        let digits = p.replace(/\D/g, "");
        return phoneRe.test(digits);
    }


    const onKeyUp = (e) => {
        let input = e.target;
        let formatted = formatPhoneText(input.value);
        let isError = (validatePhone(formatted) || formatted.length == 0);
        input.value = formatted;
    }


    const setupPhoneFields = (className) => {
        // console.log(className);
        let lstPhoneFields = document.getElementsByClassName(className);
        // console.log(lstPhoneFields);

        for (let i = 0; i < lstPhoneFields.length; i++) {
            let input = lstPhoneFields[i];
            // console.log(input);
            if (input.type.toLowerCase() == "tel") {
                input.placeholder = "(XXX-XXX-XXXX)";
                input.addEventListener("keydown", onKeyDown);
                input.addEventListener("keyup", onKeyUp);
            }
        }
    }

    setupPhoneFields('input-phone');

    $("#lf-use-broker-info").change((e) => {
        $("#lf-select-team-broker").toggle();
    });

    // $("#lf-select-team-broker").change((e) => {
    //     const broker = JSON.parse(e.target.value);
    //     $("#contactName").val(broker.first_name ? broker.first_name + ' ' + broker.last_name : '');
    //     $("#brokerage").val(broker.company);
    //     $("#phone").val(broker.phone_number);
    //     $("#phoneExt").val(broker.phone_extension);
    //     $("#email").val(broker.email);
    // });

}

if (form) {

    // Proccess form and append file objects before submitting
    form.addEventListener("submit", function (e) {
        e.preventDefault();
        const post_url = $(this).attr("action");
        const next_page = $(this).attr("data-next-page");
        const request_method = $(this).attr("method");
        let form_data = $(this).serializeArray();

        form_data = formArrayToJSON(form_data);

        // prepend https://
        const websiteInput = document.getElementById("website");
        let websiteUrl = websiteInput.value;
        const memoInput = document.getElementById("memorandum");
        let memoUrl = memoInput.value;

        // Get keywords
        const lines = $("#keywords").val().split("\n");
        for (let i = 0; i < lines.length; i++) {
            keywords.push(lines[i]);
        }

        form_data["files"] = uploadFiles;
        form_data["images"] = uploadImages;
        form_data["website"] = fixUrl(websiteUrl);
        form_data["memorandum"] = fixUrl(memoUrl);
        form_data["keywords"] = keywords.join(",");

        if (form.id === "edit-listing-form") {
            form_data["existingPhotos"] = existingPhotos;
            form_data["existingDocs"] = existingDocs;
            form_data["photosToDelete"] = photosToDelete;
            form_data["docsToDelete"] = docsToDelete;
        }
        console.log(form_data);

        $.ajax({
            url: post_url,
            type: "POST",
            data: JSON.stringify(form_data),
            contentType: 'application/json',
            success: function (data) {
                console.log('Sent!');
                console.log(data);

                $(location).attr('href', next_page);

            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error({
                    "Failed posting: ": errorThrown
                });
            }
        });
    });

    // Dropzone for images
    // autoProcessQueue set to false to prevent native Dropzone submition
    Dropzone.options.imageUpload = {
        url: "add-listing",
        paramName: function () { return "images"; },
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 100,
        maxFiles: 100,
        init: function () {

            const wrapperThis = this;

            this.on("addedfile", function (file) {

                // Push image to array when added
                uploadImages.push(file);
                console.log(uploadImages);
                // Add a remove button
                const removeButton = Dropzone.createElement('<button class="remove-btn"><i class="fas fa-trash-alt"></i></button>');
                // Handle image removals
                removeButton.addEventListener("click", function (e) {
                    // Make sure the button click doesn't submit the form
                    e.preventDefault();
                    e.stopPropagation();
                    // Remove the image preview
                    wrapperThis.removeFile(file);
                    // Remove image from array
                    uploadImages = uploadImages.filter(e => e.upload.uuid !== file.upload.uuid);
                    console.log(uploadImages);
                });
                // Add the button to the file preview element.
                file.previewElement.appendChild(removeButton);
            });
        }
    };

    // Dropzone for files
    Dropzone.options.fileUpload = {
        url: "add-listing",
        paramName: function () { return "files"; },
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 100,
        maxFiles: 100,
        init: function () {


            const wrapperThis = this;

            this.on("addedfile", function (file) {
                // add dataURL prop missing from non-image files
                const fileReader = new FileReader();
                let base64;
                fileReader.onload = (fileLoadedEvent) => {
                    base64 = fileLoadedEvent.target.result;
                    file.dataURL = base64;
                };
                fileReader.readAsDataURL(file);
                // Push file to array
                uploadFiles.push(file);
                console.log(uploadFiles);
                const removeButton = Dropzone.createElement('<button class="remove-btn"><i class="fas fa-trash-alt"></i></button>');
                removeButton.addEventListener("click", function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    wrapperThis.removeFile(file);
                    uploadFiles = uploadFiles.filter(e => e.upload.uuid !== file.upload.uuid);
                    console.log(uploadFiles);
                });
                file.previewElement.appendChild(removeButton);
            });
        },
        thumbnail: (file, dataURL) => {
            console.log("thumbnail");
            console.log(dataURL);
        }
    };

    // delete files in edit listing form
    if (form.id === "edit-listing-form") {
        console.log(existingPhotos);
        console.log(existingDocs);

        const photoBtns = document.getElementsByClassName("delete-listing-photo-btn");
        const docBtns = document.getElementsByClassName("delete-listing-doc-btn");

        for (let p of photoBtns) {
            p.addEventListener("click", e => {
                e.preventDefault();
                const objUrl = e.target.value
                photosToDelete.push(objUrl);
                const image = document.getElementById(objUrl);
                image.style.display = "none";
                // const objUrl = e.target.value
                // const key = objUrl.split('https://urt-listings.s3.amazonaws.com/')[1];
                // existingPhotos = existingPhotos.filter(p => p.description !== description); 
                // const url = "/listings/delete?type=photo&key=" + key;
                // $.ajax({
                //     url: url,
                //     type: "GET",
                //     data: JSON.stringify({}),
                //     contentType: 'application/json',
                //     success: function (data) {
                //         console.log('Deleted!');
                //         const image = document.getElementById(objUrl);
                //         image.style.display = "none";
                //     },
                //     error: function (jqXHR, textStatus, errorThrown) {
                //         // TODO display error
                //         console.error({
                //             "Failed deleting: ": errorThrown
                //         });
                //     }
                // });
            });
        }

        for (let d of docBtns) {
            d.addEventListener("click", e => {
                e.preventDefault();
                const objUrl = e.target.value
                docsToDelete.push(objUrl);
                const doc = document.getElementById(objUrl);
                doc.style.display = "none";
                // const objUrl = e.target.value
                // const key = objUrl.split('https://urt-listings.s3.amazonaws.com/')[1];
                // existingPhotos = existingPhotos.filter(p => p.name !== name); 
                // const url = "/listings/delete?type=doc&key=" + key;
                // $.ajax({
                //     url: url,
                //     type: "GET",
                //     data: JSON.stringify({}),
                //     contentType: 'application/json',
                //     success: function (data) {
                //         console.log('Deleted!');
                //         const doc = document.getElementById(objUrl);
                //         doc.style.display = "none";
                //     },
                //     error: function (jqXHR, textStatus, errorThrown) {
                //         // TODO display error
                //         console.error({
                //             "Failed deleting: ": errorThrown
                //         });
                //     }
                // });
            });
        }
    }


}

const summaryPage = document.getElementById('summaryPage');

if (summaryPage) {
    const openTab = (e, tabName) => {
        console.log(tabName);
    
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tabName).style.display = "flex";
        e.currentTarget.className += " active";
    }
    
    const tabLinks = document.getElementsByClassName("tablinks");
    for (const t of tabLinks) {
        t.addEventListener("click", (e) => {
            const target = t.getAttribute("data-target");
            openTab(e, target);
        });
    }
}