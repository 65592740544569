const ladda = require("ladda");

const { formValidation } = require("./validation.js")

let laddaElements;

const startLadda = (ele) => {
    ele.laddaInstance.start();
    const spinnerML = (ele.scrollWidth - 32) * 1.0 / 2;
    ele.children[1].children[0].style.setProperty("margin-left", `${spinnerML}px`);
    const label = ele.children[0];
    label.style = "display:none";
}

const findElements = () => {
    const eles = $("[ladda-button='']");
    let i;
    for (i = 0; i < eles.length; i++) {
        const ele = eles[i];
        if (!ele.laddaInstance) {
            ele.laddaInstance = ladda.create(ele);
        };
        if (ele.onclick) {
            let originalClickHandler = ele.onclick;
            ele.onclick = () => {
                startLadda(ele);
                originalClickHandler();
            };
        } else if (ele.form) {
            ele.onclick = () => {
                let validationRequired;
                ele.form.classList.forEach((c) => {
                    if (c === "validation-required") {
                        validationRequired = true;
                    }
                });
                if (validationRequired) {
                    formValidation(ele.form, ele);
                } else {
                    startLadda(ele);
                    ele.form.submit();
                }
            }
        }
    }
    return eles;
}

$(document).ready(function() {
    laddaElements = findElements();
    console.log("Ladda elements found => ", laddaElements);
});
