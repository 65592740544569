let searchForm = document.getElementById("search-form")

if (searchForm) {
  document.getElementById("search-form").addEventListener("submit", (e) => {
    e.preventDefault();
  });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var defaults = {
    lines: 12,
    length: 7,
    width: 5,
    radius: 10,
    scale: 1.0,
    corners: 1,
    color: '#000',
    fadeColor: 'transparent',
    animation: 'spinner-line-fade-default',
    rotate: 0,
    direction: 1,
    speed: 1,
    zIndex: 2e9,
    className: 'spinner',
    top: '50%',
    left: '50%',
    shadow: '0 0 1px transparent',
    position: 'absolute',
};
var Spinner = /** @class */ (function () {
    function Spinner(opts) {
        if (opts === void 0) { opts = {}; }
        this.opts = __assign({}, defaults, opts);
    }
    /**
     * Adds the spinner to the given target element. If this instance is already
     * spinning, it is automatically removed from its previous target by calling
     * stop() internally.
     */
    Spinner.prototype.spin = function (target) {
        this.stop();
        this.el = document.createElement('div');
        this.el.className = this.opts.className;
        this.el.setAttribute('role', 'progressbar');
        css(this.el, {
            position: this.opts.position,
            width: 0,
            zIndex: this.opts.zIndex,
            left: this.opts.left,
            top: this.opts.top,
            transform: "scale(" + this.opts.scale + ")",
        });
        if (target) {
            target.insertBefore(this.el, target.firstChild || null);
        }
        drawLines(this.el, this.opts);
        return this;
    };
    /**
     * Stops and removes the Spinner.
     * Stopped spinners may be reused by calling spin() again.
     */
    Spinner.prototype.stop = function () {
        if (this.el) {
            if (typeof requestAnimationFrame !== 'undefined') {
                cancelAnimationFrame(this.animateId);
            }
            else {
                clearTimeout(this.animateId);
            }
            if (this.el.parentNode) {
                this.el.parentNode.removeChild(this.el);
            }
            this.el = undefined;
        }
        return this;
    };
    return Spinner;
}());

/**
 * Sets multiple style properties at once.
 */
function css(el, props) {
    for (var prop in props) {
        el.style[prop] = props[prop];
    }
    return el;
}
/**
 * Returns the line color from the given string or array.
 */
function getColor(color, idx) {
    return typeof color == 'string' ? color : color[idx % color.length];
}
/**
 * Internal method that draws the individual lines.
 */
function drawLines(el, opts) {
    var borderRadius = (Math.round(opts.corners * opts.width * 500) / 1000) + 'px';
    var shadow = 'none';
    if (opts.shadow === true) {
        shadow = '0 2px 4px #000'; // default shadow
    }
    else if (typeof opts.shadow === 'string') {
        shadow = opts.shadow;
    }
    var shadows = parseBoxShadow(shadow);
    for (var i = 0; i < opts.lines; i++) {
        var degrees = ~~(360 / opts.lines * i + opts.rotate);
        var backgroundLine = css(document.createElement('div'), {
            position: 'absolute',
            top: -opts.width / 2 + "px",
            width: (opts.length + opts.width) + 'px',
            height: opts.width + 'px',
            background: getColor(opts.fadeColor, i),
            borderRadius: borderRadius,
            transformOrigin: 'left',
            transform: "rotate(" + degrees + "deg) translateX(" + opts.radius + "px)",
        });
        var delay = i * opts.direction / opts.lines / opts.speed;
        delay -= 1 / opts.speed; // so initial animation state will include trail
        var line = css(document.createElement('div'), {
            width: '100%',
            height: '100%',
            background: getColor(opts.color, i),
            borderRadius: borderRadius,
            boxShadow: normalizeShadow(shadows, degrees),
            animation: 1 / opts.speed + "s linear " + delay + "s infinite " + opts.animation,
        });
        backgroundLine.appendChild(line);
        el.appendChild(backgroundLine);
    }
}
function parseBoxShadow(boxShadow) {
    var regex = /^\s*([a-zA-Z]+\s+)?(-?\d+(\.\d+)?)([a-zA-Z]*)\s+(-?\d+(\.\d+)?)([a-zA-Z]*)(.*)$/;
    var shadows = [];
    for (var _i = 0, _a = boxShadow.split(','); _i < _a.length; _i++) {
        var shadow = _a[_i];
        var matches = shadow.match(regex);
        if (matches === null) {
            continue; // invalid syntax
        }
        var x = +matches[2];
        var y = +matches[5];
        var xUnits = matches[4];
        var yUnits = matches[7];
        if (x === 0 && !xUnits) {
            xUnits = yUnits;
        }
        if (y === 0 && !yUnits) {
            yUnits = xUnits;
        }
        if (xUnits !== yUnits) {
            continue; // units must match to use as coordinates
        }
        shadows.push({
            prefix: matches[1] || '',
            x: x,
            y: y,
            xUnits: xUnits,
            yUnits: yUnits,
            end: matches[8],
        });
    }
    return shadows;
}
/**
 * Modify box-shadow x/y offsets to counteract rotation
 */
function normalizeShadow(shadows, degrees) {
    var normalized = [];
    for (var _i = 0, shadows_1 = shadows; _i < shadows_1.length; _i++) {
        var shadow = shadows_1[_i];
        var xy = convertOffset(shadow.x, shadow.y, degrees);
        normalized.push(shadow.prefix + xy[0] + shadow.xUnits + ' ' + xy[1] + shadow.yUnits + shadow.end);
    }
    return normalized.join(', ');
}
function convertOffset(x, y, degrees) {
    var radians = degrees * Math.PI / 180;
    var sin = Math.sin(radians);
    var cos = Math.cos(radians);
    return [
        Math.round((x * cos + y * sin) * 1000) / 1000,
        Math.round((-x * sin + y * cos) * 1000) / 1000,
    ];
}

! function (a, b) {
    // "object" == typeof module && module.exports ? module.exports = b() : "function" == typeof define && define.amd ? define(b) : a.Spinner = b()
}(this, function () {
    "use strict";

    function a(a, b) {
        var c, d = document.createElement(a || "div");
        for (c in b) d[c] = b[c];
        return d
    }

    function b(a) {
        for (var b = 1, c = arguments.length; b < c; b++) a.appendChild(arguments[b]);
        return a
    }

    function c(a, b, c, d) {
        var e = ["opacity", b, ~~(100 * a), c, d].join("-"),
            f = .01 + c / d * 100,
            g = Math.max(1 - (1 - a) / b * (100 - f), a),
            h = j.substring(0, j.indexOf("Animation")).toLowerCase(),
            i = h && "-" + h + "-" || "";
        return m[e] || (k.insertRule("@" + i + "keyframes " + e + "{0%{opacity:" + g + "}" + f + "%{opacity:" + a + "}" + (f + .01) + "%{opacity:1}" + (f + b) % 100 + "%{opacity:" + a + "}100%{opacity:" + g + "}}", k.cssRules.length), m[e] = 1), e
    }

    function d(a, b) {
        var c, d, e = a.style;
        if (b = b.charAt(0).toUpperCase() + b.slice(1), void 0 !== e[b]) return b;
        for (d = 0; d < l.length; d++)
            if (c = l[d] + b, void 0 !== e[c]) return c
    }

    function e(a, b) {
        for (var c in b) a.style[d(a, c) || c] = b[c];
        return a
    }

    function f(a) {
        for (var b = 1; b < arguments.length; b++) {
            var c = arguments[b];
            for (var d in c) void 0 === a[d] && (a[d] = c[d])
        }
        return a
    }

    function g(a, b) {
        return "string" == typeof a ? a : a[b % a.length]
    }

    function h(a) {
        this.opts = f(a || {}, h.defaults, n)
    }

    function i() {
        function c(b, c) {
            return a("<" + b + ' xmlns="urn:schemas-microsoft.com:vml" class="spin-vml">', c)
        }
        k.addRule(".spin-vml", "behavior:url(#default#VML)"), h.prototype.lines = function (a, d) {
            function f() {
                return e(c("group", {
                    coordsize: k + " " + k,
                    coordorigin: -j + " " + -j
                }), {
                        width: k,
                        height: k
                    })
            }

            function h(a, h, i) {
                b(m, b(e(f(), {
                    rotation: 360 / d.lines * a + "deg",
                    left: ~~h
                }), b(e(c("roundrect", {
                    arcsize: d.corners
                }), {
                        width: j,
                        height: d.scale * d.width,
                        left: d.scale * d.radius,
                        top: -d.scale * d.width >> 1,
                        filter: i
                    }), c("fill", {
                        color: g(d.color, a),
                        opacity: d.opacity
                    }), c("stroke", {
                        opacity: 0
                    }))))
            }
            var i, j = d.scale * (d.length + d.width),
                k = 2 * d.scale * j,
                l = -(d.width + d.length) * d.scale * 2 + "px",
                m = e(f(), {
                    position: "absolute",
                    top: l,
                    left: l
                });
            if (d.shadow)
                for (i = 1; i <= d.lines; i++) h(i, -2, "progid:DXImageTransform.Microsoft.Blur(pixelradius=2,makeshadow=1,shadowopacity=.3)");
            for (i = 1; i <= d.lines; i++) h(i);
            return b(a, m)
        }, h.prototype.opacity = function (a, b, c, d) {
            var e = a.firstChild;
            d = d.shadow && d.lines || 0, e && b + d < e.childNodes.length && (e = e.childNodes[b + d], e = e && e.firstChild, (e = e && e.firstChild) && (e.opacity = c))
        }
    }
    var j, k, l = ["webkit", "Moz", "ms", "O"],
        m = {},
        n = {
            lines: 12,
            length: 7,
            width: 5,
            radius: 10,
            scale: 1,
            corners: 1,
            color: "#000",
            opacity: .25,
            rotate: 0,
            direction: 1,
            speed: 1,
            trail: 100,
            fps: 20,
            zIndex: 2e9,
            className: "spinner",
            top: "50%",
            left: "50%",
            shadow: !1,
            hwaccel: !1,
            position: "absolute"
        };
    if (h.defaults = {}, f(h.prototype, {
        spin: function (b) {
            this.stop();
            var c = this,
                d = c.opts,
                f = c.el = a(null, {
                    className: d.className
                });
            if (e(f, {
                position: d.position,
                width: 0,
                zIndex: d.zIndex,
                left: d.left,
                top: d.top
            }), b && b.insertBefore(f, b.firstChild || null), f.setAttribute("role", "progressbar"), c.lines(f, c.opts), !j) {
                var g, h = 0,
                    i = (d.lines - 1) * (1 - d.direction) / 2,
                    k = d.fps,
                    l = k / d.speed,
                    m = (1 - d.opacity) / (l * d.trail / 100),
                    n = l / d.lines;
                ! function a() {
                    h++;
                    for (var b = 0; b < d.lines; b++) g = Math.max(1 - (h + (d.lines - b) * n) % l * m, d.opacity), c.opacity(f, b * d.direction + i, g, d);
                    c.timeout = c.el && setTimeout(a, ~~(1e3 / k))
                }()
            }
            return c
        },
        stop: function () {
            var a = this.el;
            return a && (clearTimeout(this.timeout), a.parentNode && a.parentNode.removeChild(a), this.el = void 0), this
        },
        lines: function (d, f) {
            function h(b, c) {
                return e(a(), {
                    position: "absolute",
                    width: f.scale * (f.length + f.width) + "px",
                    height: f.scale * f.width + "px",
                    background: b,
                    boxShadow: c,
                    transformOrigin: "left",
                    transform: "rotate(" + ~~(360 / f.lines * k + f.rotate) + "deg) translate(" + f.scale * f.radius + "px,0)",
                    borderRadius: (f.corners * f.scale * f.width >> 1) + "px"
                })
            }
            for (var i, k = 0, l = (f.lines - 1) * (1 - f.direction) / 2; k < f.lines; k++) i = e(a(), {
                position: "absolute",
                top: 1 + ~(f.scale * f.width / 2) + "px",
                transform: f.hwaccel ? "translate3d(0,0,0)" : "",
                opacity: f.opacity,
                animation: j && c(f.opacity, f.trail, l + k * f.direction, f.lines) + " " + 1 / f.speed + "s linear infinite"
            }), f.shadow && b(i, e(h("#000", "0 0 4px #000"), {
                top: "2px"
            })), b(d, b(i, h(g(f.color, k), "0 0 1px rgba(0,0,0,.1)")));
            return d
        },
        opacity: function (a, b, c) {
            b < a.childNodes.length && (a.childNodes[b].style.opacity = c)
        }
    }), "undefined" != typeof document) {
        k = function () {
            var c = a("style", {
                type: "text/css"
            });
            return b(document.getElementsByTagName("head")[0], c), c.sheet || c.styleSheet
        }();
        var o = e(a("group"), {
            behavior: "url(#default#VML)"
        });
        !d(o, "transform") && o.adj ? i() : j = d(o, "animation")
    }
    return h
}),
    function (a) {
        function b(a) {
            this.config = null, this.input_el = a, this.results_id = null, this.results_el = null, this.parent_el = null, this.results_showing = !1, this.form_el = null, this.timer = !1, this.last_string = "", this.spinner = null, this.spinner_showing = !1, this.has_results = !1, this.current_request = !1, this.results_destroy_on_blur = !0, this.a11y_keys = [27, 40, 38, 9], this.init()
        }
        var c = "searchwp_live_search";
        b.prototype = {
            init: function () {
                var b = this,
                    c = this.input_el;
                this.form_el = c.parents("form:eq(0)"), this.results_id = this.uniqid("searchwp_live_search_results_");
                var d = !1,
                    e = c.data("swpconfig");
                if (e && void 0 !== e)
                    for (var f in searchwp_live_search_params.config) e === f && (d = !0, this.config = searchwp_live_search_params.config[f]);
                else
                    for (var g in searchwp_live_search_params.config) "default" === g && (d = !0, this.config = searchwp_live_search_params.config[g]);
                if (d) {
                    var h = c.data("swpengine");
                    h && (this.config.engine = h), c.data("swpengine", this.config.engine), c.attr("autocomplete", "off");
                    var i = this.results_id + "_instructions";
                    c.attr("aria-describedby", i), c.attr("aria-owns", this.results_id), c.attr("aria-expanded", "false"), c.attr("aria-autocomplete", "both"), c.attr("aria-activedescendant", ""), c.after('<p class="searchwp-live-search-instructions screen-reader-text" id="' + i + '">' + searchwp_live_search_params.aria_instructions + "</p>");
                    var j = '<div class="searchwp-live-search-results" id="' + this.results_id + '" role="listbox" tabindex="0"></div>',
                        k = c.data("swpparentel");
                    k ? (this.parent_el = a(k), this.parent_el.html(j)) : this.config.parent_el ? (this.parent_el = a(this.config.parent_el), this.parent_el.html(j)) : a("body").append(a(j)), this.results_el = a("#" + this.results_id), a(window).resize(function () {
                        // b.position_results()
                    }), this.config.spinner && (this.spinner = new Spinner(this.config.spinner)), void 0 === this.config.abort_on_enter && (this.config.abort_on_enter = !0), c.keyup(function (c) {

                        if (13 === c.keyCode) {

                            a.inArray(c.keyCode, b.a11y_keys) > -1 || (b.current_request && b.config.abort_on_enter && 13 === c.keyCode && b.current_request.abort(),
                                a.trim(b.input_el.val()).length ? b.results_showing || (b.results_el.addClass("searchwp-live-search-results-showing"), b.show_spinner(), b.results_showing = !0) : b.destroy_results(),
                                b.has_results && !b.spinner_showing && b.last_string !== a.trim(b.input_el.val()) && (b.results_el.empty(), b.show_spinner()), c.currentTarget.value.length >= 0 ? b.results_el.removeClass("searchwp-live-search-no-min-chars") : b.results_el.addClass("searchwp-live-search-no-min-chars"))

                        }
                    }).keyup(a.proxy(this.maybe_search, this)), (this.config.results_destroy_on_blur || void 0 === this.config.results_destroy_on_blur) && a("html").click(function () {
                        b.destroy_results()
                    }), c.click(function (a) {
                        a.stopPropagation()
                    }), this.results_el.click(function (a) {
                        a.stopPropagation()
                    })
                } else alert(searchwp_live_search_params.msg_no_config_found)
            },
            keyboard_navigation: function () {
                var b = this,
                    c = this.input_el,
                    d = this.results_el,
                    e = "searchwp-live-search-result--focused",
                    f = ".searchwp-live-search-result",
                    g = this.a11y_keys;
                a(document).off("keyup.searchwp_a11y").on("keyup.searchwp_a11y", function (h) {
                    if (!d.hasClass("searchwp-live-search-results-showing")) return void a(document).off("keyup.searchwp_a11y");
                    if (-1 !== a.inArray(h.keyCode, g)) {
                        // if (h.preventDefault(), 27 === h.keyCode && !c.is(":focus")) return b.destroy_results(), a(document).off("keyup.searchwp_a11y"), c.focus(), void a(document).trigger("searchwp_live_escape_results");
                        if (40 === h.keyCode) {
                            console.log("down!");
                            var i = a(d[0]).find("." + e);
                            1 === i.length && 1 === i.next().length ?
                                (i.removeClass(e).attr("aria-selected", "false").next().addClass(e).attr("aria-selected", "true").find("a").focus(), b.aria_activedescendant(!0)) :
                                (i.removeClass(e).attr("aria-selected", "false"),
                                    d.find(f + ":first").addClass(e).attr("aria-selected", "true").find("a").focus(),
                                    d.find(f + ":first").length > 0 ? b.aria_activedescendant(!0) : b.aria_activedescendant(!1)),
                                a(document).trigger("searchwp_live_key_arrowdown_pressed")
                        }
                        if (38 === h.keyCode) {
                            var j = a(d[0]).find("." + e);
                            1 === j.length && 1 === j.prev().length ? (j.removeClass(e).attr("aria-selected", "false").prev().addClass(e).attr("aria-selected", "true").find("a").focus(), b.aria_activedescendant(!0)) : (j.removeClass(e).attr("aria-selected", "false"), d.find(f + ":last").addClass(e).attr("aria-selected", "true").find("a").focus(), d.find(f + ":last").length > 0 ? b.aria_activedescendant(!0) : b.aria_activedescendant(!1)), a(document).trigger("searchwp_live_key_arrowup_pressed")
                        }
                        // 13 === h.keyCode && a(document).trigger("searchwp_live_key_enter_pressed"), 9 === h.keyCode && a(document).trigger("searchwp_live_key_tab_pressed")
                    }
                }), a(document).trigger("searchwp_live_keyboad_navigation")
            },
            aria_expanded: function (b) {
                var c = this.input_el;
                b ? c.attr("aria-expanded", "true") : (c.attr("aria-expanded", "false"), this.aria_activedescendant(!1)), a(document).trigger("searchwp_live_aria_expanded")
            },
            aria_activedescendant: function (b) {
                var c = this.input_el;
                b ? c.attr("aria-activedescendant", "selectedOption") : c.attr("aria-activedescendant", ""), a(document).trigger("searchwp_live_aria_activedescendant")
            },
            position_results: function () {
                var b = this.input_el,
                    c = b.offset(),
                    d = this.results_el,
                    e = 0;

                if (!b.is(":hidden")) {
                    switch (c.left += parseInt(this.config.results.offset.x, 10), c.top += parseInt(this.config.results.offset.y, 10), this.config.results.position) {
                        case "top":
                            e = 0 - d.height();
                            break;
                        default:
                            e = b.outerHeight()
                    }
                    d.css("left", c.left), d.css("top", c.top + e + "px"), "auto" === this.config.results.width && d.width(b.outerWidth() - parseInt(d.css("paddingRight").replace("px", ""), 10) - parseInt(d.css("paddingLeft").replace("px", ""), 10)), a(document).trigger("searchwp_live_position_results", [d.css("left"), d.css("top"), d.width()])
                }
            },
            destroy_results: function (b) {
                this.hide_spinner(), this.aria_expanded(!1), this.results_el.empty().removeClass("searchwp-live-search-results-showing"), this.results_showing = !1, this.has_results = !1, a(document).trigger("searchwp_live_destroy_results")
            },
            maybe_search: function (b) {
                console.log("type");
                if (b.keyCode === 13) {
                    console.log(b);
                    // b.preventDefault();
                    this.search(b);
                }
            },
            show_spinner: function () {
                this.config.spinner && !this.spinner_showing && (this.spinner.spin(document.getElementById(this.results_id)), this.spinner_showing = !0, a(document).trigger("searchwp_live_show_spinner"))
            },
            hide_spinner: function () {
                this.config.spinner && (this.spinner.stop(), this.spinner_showing = !1, a(document).trigger("searchwp_live_hide_spinner"))
            },
            search: function (b) {
                // console.log("in search");
                // //b.destroy_results();
                // b.results_showing;
                //                         b.results_el.addClass("searchwp-live-search-results-showing");
                this.show_spinner();
                var c = this,
                    d = this.form_el,
                    e = d.serialize(),
                    f = d.attr("action") ? d.attr("action") : "",
                    g = this.input_el,
                    h = this.results_el,
                    term = g[0].value;
                if (!window.location.href.includes("/listings")) {
                    a(document).trigger("searchwp_live_search_start", [g, h, d, f, e]), this.aria_expanded(!1), e += "&action=searchwp_live_search&swpengine=" + g.data("swpengine") + "&swpquery=" + g.val(), -1 !== f.indexOf("?") && (f = f.split("?"), e += "&" + f[1]), this.last_string = g.val(), this.has_results = !0,
                        this.current_request = a.ajax({
                            url: '/api/search',
                            type: "POST",
                            data: e,
                            complete: function () {
                                a(document).trigger("searchwp_live_search_complete", [g, h, d, f, e]), c.spinner_showing = !1, c.hide_spinner(), this.current_request = !1
                            },
                            success: function (b) {
                                // b = "test";
                                // console.log("results", b);
                                0 === b && (b = "");
                                a(document).trigger("searchwp_live_search_success", [g, h, d, f, e]);
                                c.position_results();
                                //h.html(b);
                                //console.log(b);
                                if (b.byHeadline.length > 0) {

                                    const hlDiv = document.createElement("div");
                                    const label = document.createElement("div");
                                    const labelSpan = document.createElement("span");
                                    labelSpan.innerText = "Headlines";
                                    label.append(labelSpan);
                                    label.setAttribute("class", "search-label");
                                    hlDiv.append(label);
                                    hlDiv.setAttribute("class", "search-res-hl");

                                    for (let r of b.byHeadline) {
                                        const a = document.createElement("a");
                                        a.setAttribute("href", "/post/" + r.slug);
                                        const resDiv = document.createElement("div");
                                        const h5 = document.createElement("h5");
                                        h5.innerText = r.headline;
                                        const ex = document.createElement("p");
                                        ex.innerText = r.excerpt;
                                        const auth = document.createElement("p");
                                        auth.innerText = r.author;
                                        if (r.imageUrl) {
                                            const col1 = document.createElement("div");
                                            const col2 = document.createElement("div");
                                            col1.setAttribute("class", "cs-col-xl-6");
                                            col2.setAttribute("class", "cs-col-xl-6");
                                            const img = document.createElement("img");
                                            img.setAttribute("src", r.imageUrl);
                                            col1.append(h5, ex, auth);
                                            col2.append(img);
                                            resDiv.append(col1, col2);
                                        } else {
                                            const col = document.createElement("div");
                                            col.setAttribute("class", "cs-col-xl-12");
                                            col.append(h5, ex, auth);
                                            resDiv.append(col);
                                        }
                                        resDiv.setAttribute("class", "search-res-item cs-row");
                                        a.append(resDiv);
                                        hlDiv.append(a);
                                        // $(".search-res").mark(e);
                                    }
                                    h.append(hlDiv);
                                }
                                if (b.byExcerpt.length > 0) {
                                    const exDiv = document.createElement("div");
                                    const label = document.createElement("div");
                                    const labelSpan = document.createElement("span");
                                    labelSpan.innerText = "Excerpts";
                                    label.append(labelSpan);
                                    label.setAttribute("class", "search-label");
                                    exDiv.append(label);
                                    exDiv.setAttribute("class", "search-res-hl");
                                    for (let r of b.byExcerpt) {
                                        const a = document.createElement("a");
                                        a.setAttribute("href", "/post/" + r.slug);
                                        const resDiv = document.createElement("div");
                                        const h5 = document.createElement("h5");
                                        h5.innerText = r.headline;
                                        const ex = document.createElement("p");
                                        ex.innerText = r.excerpt;
                                        const auth = document.createElement("p");
                                        auth.innerText = r.author;
                                        if (r.imageUrl) {
                                            const col1 = document.createElement("div");
                                            const col2 = document.createElement("div");
                                            col1.setAttribute("class", "cs-col-xl-6");
                                            col2.setAttribute("class", "cs-col-xl-6");
                                            const img = document.createElement("img");
                                            img.setAttribute("src", r.imageUrl);
                                            col1.append(h5, ex, auth);
                                            col2.append(img);
                                            resDiv.append(col1, col2);
                                        } else {
                                            const col = document.createElement("div");
                                            col.setAttribute("class", "cs-col-xl-12");
                                            col.append(h5, ex, auth);
                                            resDiv.append(col);
                                        }
                                        resDiv.setAttribute("class", "search-res-item cs-row");
                                        a.append(resDiv);
                                        exDiv.append(a);
                                        // $(".search-res").mark(e);
                                    }
                                    h.append(exDiv);
                                }
                                if (b.byMarkdown.length > 0) {
                                    const mkDiv = document.createElement("div");
                                    const label = document.createElement("div");
                                    const labelSpan = document.createElement("span");
                                    labelSpan.innerText = "Posts";
                                    label.append(labelSpan);
                                    label.setAttribute("class", "search-label");
                                    mkDiv.append(label);
                                    mkDiv.setAttribute("class", "search-res-hl");
                                    for (let r of b.byMarkdown) {
                                        const a = document.createElement("a");
                                        a.setAttribute("href", "/post/" + r.slug);
                                        const resDiv = document.createElement("div");
                                        const h5 = document.createElement("h5");
                                        h5.innerText = r.headline;
                                        const ex = document.createElement("p");
                                        const termLocation = r.linkedMarkdown.indexOf(term);
                                        if (termLocation === -1) break;
                                        const start = termLocation > 150 ? termLocation - 150 : termLocation;
                                        const end = r.linkedMarkdown.length > 300 ? termLocation + 150 : r.linkedMarkdown.length - 1;
                                        const highlight = "..." + r.linkedMarkdown.slice(start, end) + "...";
                                        console.log(highlight);
                                        ex.innerText = highlight;
                                        const auth = document.createElement("p");
                                        auth.innerText = r.author;
                                        if (r.imageUrl) {
                                            const col1 = document.createElement("div");
                                            const col2 = document.createElement("div");
                                            col1.setAttribute("class", "cs-col-xl-6");
                                            col2.setAttribute("class", "cs-col-xl-6");
                                            const img = document.createElement("img");
                                            img.setAttribute("src", r.imageUrl);
                                            col1.append(h5, ex, auth);
                                            col2.append(img);
                                            resDiv.append(col1, col2);
                                        } else {
                                            const col = document.createElement("div");
                                            col.setAttribute("class", "cs-col-xl-12");
                                            col.append(h5, ex, auth);
                                            resDiv.append(col);
                                        }
                                        resDiv.setAttribute("class", "search-res-item cs-row");
                                        a.append(resDiv);
                                        mkDiv.append(a);
                                        // $(".search-res").mark(e);
                                    }
                                    if (mkDiv.childNodes.length > 1) {
                                        h.append(mkDiv);
                                    }
                                }
                                if (b.byPerson.length > 0) {
                                    const ppDiv = document.createElement("div");
                                    const label = document.createElement("div");
                                    const labelSpan = document.createElement("span");
                                    labelSpan.innerText = "People";
                                    label.append(labelSpan);
                                    label.setAttribute("class", "search-label");
                                    ppDiv.append(label);
                                    ppDiv.setAttribute("class", "search-res-pp");
                                    for (let r of b.byPerson) {
                                        const a = document.createElement("a");
                                        if (r.contentType === "bio") {
                                            a.setAttribute("href", "/company/team");
                                        }
                                        if (r.contentType === "author") {
                                            a.setAttribute("href", "/author/" + r.authorSlug);
                                        }
                                        const resDiv = document.createElement("div");
                                        if (r.contentType === "bio") {
                                            resDiv.innerHTML = `<h6>${r.teamName}</h6>`;
                                        }
                                        if (r.contentType === "author") {
                                            resDiv.innerHTML = `<h6>${r.authorName}</h6>`;
                                        }
                                        resDiv.setAttribute("class", "search-res-item");
                                        a.append(resDiv);
                                        ppDiv.append(a);
                                        // $(".search-res").mark(e);
                                    }
                                    if (ppDiv.childNodes.length > 1) {
                                        h.append(ppDiv);
                                    }
                                }

                                console.log(h[0]);

                                if (b.byHeadline.length === 0 && b.byExcerpt.length === 0 && b.byMarkdown.length === 0 && b.byPerson.length === 0) {
                                    const noRes = document.createElement("p");
                                    noRes.innerText = "No results found.";
                                    noRes.setAttribute("class", "no-res");
                                    h.append(noRes);
                                }


                                var context = document.querySelectorAll(".search-res-item");
                                var instance = new Mark(context);
                                console.log(instance, context);
                                instance.mark(term);

                                c.aria_expanded(!0);
                                c.keyboard_navigation();
                            }
                        })
                }
                if (window.location.href.includes("/listings")) {
                    a(document).trigger("searchwp_live_search_start", [g, h, d, f, e]), this.aria_expanded(!1), e += "&action=searchwp_live_search&swpengine=" + g.data("swpengine") + "&swpquery=" + g.val(), -1 !== f.indexOf("?") && (f = f.split("?"), e += "&" + f[1]), this.last_string = g.val(), this.has_results = !0,
                        this.current_request = a.ajax({
                            url: '/listings/search',
                            type: "POST",
                            data: e,
                            complete: function () {
                                a(document).trigger("searchwp_live_search_complete", [g, h, d, f, e]), c.spinner_showing = !1, c.hide_spinner(), this.current_request = !1
                            },
                            success: function (b) {
                                // b = "test";
                                console.log("results", b);
                                0 === b && (b = "");
                                a(document).trigger("searchwp_live_search_success", [g, h, d, f, e]);
                                c.position_results();
                                //h.html(b);
                                // console.log(b);

                                if (b.length > 0) {

                                    const hlDiv = document.createElement("div");
                                    // const label = document.createElement("div");
                                    // const labelSpan = document.createElement("span");
                                //     labelSpan.innerText = "Headlines";
                                //     label.append(labelSpan);
                                //     label.setAttribute("class", "search-label");
                                //     hlDiv.append(label);
                                    hlDiv.setAttribute("class", "search-res-hl");

                                    for (let r of b) {
                                        const a = document.createElement("a");
                                        a.setAttribute("href", "/listings/" + r.id + "/listing");
                                        const resDiv = document.createElement("div");
                                        const h5 = document.createElement("h5");
                                        h5.innerText = r.name;
                                        const ex = document.createElement("p");
                                        ex.innerText = `${r.address}, ${r.city}, ${r.state} ${r.zip}`;
                                        // const auth = document.createElement("p");
                                        // auth.innerText = r.author;
                                        if (r.photos.length > 0) {
                                            const col1 = document.createElement("div");
                                            const col2 = document.createElement("div");
                                            col1.setAttribute("class", "cs-col-xl-6");
                                            col2.setAttribute("class", "cs-col-xl-6");
                                            const img = document.createElement("img");
                                            img.setAttribute("src", r.photos[0].url);
                                            col1.append(h5, ex);
                                            col2.append(img);
                                            resDiv.append(col1, col2);
                                        } else {
                                            const col = document.createElement("div");
                                            col.setAttribute("class", "cs-col-xl-12");
                                            col.append(h5, ex, auth);
                                            resDiv.append(col);
                                        }
                                        resDiv.setAttribute("class", "search-res-item cs-row");
                                        a.append(resDiv);
                                        hlDiv.append(a);
                                        // $(".search-res").mark(e);
                                    }
                                    h.append(hlDiv);
                                }
                                // if (b.byExcerpt.length > 0) {
                                //     const exDiv = document.createElement("div");
                                //     const label = document.createElement("div");
                                //     const labelSpan = document.createElement("span");
                                //     labelSpan.innerText = "Excerpts";
                                //     label.append(labelSpan);
                                //     label.setAttribute("class", "search-label");
                                //     exDiv.append(label);
                                //     exDiv.setAttribute("class", "search-res-hl");
                                //     for (let r of b.byExcerpt) {
                                //         const a = document.createElement("a");
                                //         a.setAttribute("href", "/post/" + r.slug);
                                //         const resDiv = document.createElement("div");
                                //         const h5 = document.createElement("h5");
                                //         h5.innerText = r.headline;
                                //         const ex = document.createElement("p");
                                //         ex.innerText = r.excerpt;
                                //         const auth = document.createElement("p");
                                //         auth.innerText = r.author;
                                //         if (r.imageUrl) {
                                //             const col1 = document.createElement("div");
                                //             const col2 = document.createElement("div");
                                //             col1.setAttribute("class", "cs-col-xl-6");
                                //             col2.setAttribute("class", "cs-col-xl-6");
                                //             const img = document.createElement("img");
                                //             img.setAttribute("src", r.imageUrl);
                                //             col1.append(h5, ex, auth);
                                //             col2.append(img);
                                //             resDiv.append(col1, col2);
                                //         } else {
                                //             const col = document.createElement("div");
                                //             col.setAttribute("class", "cs-col-xl-12");
                                //             col.append(h5, ex, auth);
                                //             resDiv.append(col);
                                //         }
                                //         resDiv.setAttribute("class", "search-res-item cs-row");
                                //         a.append(resDiv);
                                //         exDiv.append(a);
                                //         // $(".search-res").mark(e);
                                //     }
                                //     h.append(exDiv);
                                // }
                                // if (b.byMarkdown.length > 0) {
                                //     const mkDiv = document.createElement("div");
                                //     const label = document.createElement("div");
                                //     const labelSpan = document.createElement("span");
                                //     labelSpan.innerText = "Posts";
                                //     label.append(labelSpan);
                                //     label.setAttribute("class", "search-label");
                                //     mkDiv.append(label);
                                //     mkDiv.setAttribute("class", "search-res-hl");
                                //     for (let r of b.byMarkdown) {
                                //         const a = document.createElement("a");
                                //         a.setAttribute("href", "/post/" + r.slug);
                                //         const resDiv = document.createElement("div");
                                //         const h5 = document.createElement("h5");
                                //         h5.innerText = r.headline;
                                //         const ex = document.createElement("p");
                                //         const termLocation = r.linkedMarkdown.indexOf(term);
                                //         if (termLocation === -1) break;
                                //         const start = termLocation > 150 ? termLocation - 150 : termLocation;
                                //         const end = r.linkedMarkdown.length > 300 ? termLocation + 150 : r.linkedMarkdown.length - 1;
                                //         const highlight = "..." + r.linkedMarkdown.slice(start, end) + "...";
                                //         console.log(highlight);
                                //         ex.innerText = highlight;
                                //         const auth = document.createElement("p");
                                //         auth.innerText = r.author;
                                //         if (r.imageUrl) {
                                //             const col1 = document.createElement("div");
                                //             const col2 = document.createElement("div");
                                //             col1.setAttribute("class", "cs-col-xl-6");
                                //             col2.setAttribute("class", "cs-col-xl-6");
                                //             const img = document.createElement("img");
                                //             img.setAttribute("src", r.imageUrl);
                                //             col1.append(h5, ex, auth);
                                //             col2.append(img);
                                //             resDiv.append(col1, col2);
                                //         } else {
                                //             const col = document.createElement("div");
                                //             col.setAttribute("class", "cs-col-xl-12");
                                //             col.append(h5, ex, auth);
                                //             resDiv.append(col);
                                //         }
                                //         resDiv.setAttribute("class", "search-res-item cs-row");
                                //         a.append(resDiv);
                                //         mkDiv.append(a);
                                //         // $(".search-res").mark(e);
                                //     }
                                //     if (mkDiv.childNodes.length > 1) {
                                //         h.append(mkDiv);
                                //     }
                                // }
                                // if (b.byPerson.length > 0) {
                                //     const ppDiv = document.createElement("div");
                                //     const label = document.createElement("div");
                                //     const labelSpan = document.createElement("span");
                                //     labelSpan.innerText = "People";
                                //     label.append(labelSpan);
                                //     label.setAttribute("class", "search-label");
                                //     ppDiv.append(label);
                                //     ppDiv.setAttribute("class", "search-res-pp");
                                //     for (let r of b.byPerson) {
                                //         const a = document.createElement("a");
                                //         if (r.contentType === "bio") {
                                //             a.setAttribute("href", "/company/team");
                                //         }
                                //         if (r.contentType === "author") {
                                //             a.setAttribute("href", "/author/" + r.authorSlug);
                                //         }
                                //         const resDiv = document.createElement("div");
                                //         if (r.contentType === "bio") {
                                //             resDiv.innerHTML = `<h6>${r.teamName}</h6>`;
                                //         }
                                //         if (r.contentType === "author") {
                                //             resDiv.innerHTML = `<h6>${r.authorName}</h6>`;
                                //         }
                                //         resDiv.setAttribute("class", "search-res-item");
                                //         a.append(resDiv);
                                //         ppDiv.append(a);
                                //         // $(".search-res").mark(e);
                                //     }
                                //     if (ppDiv.childNodes.length > 1) {
                                //         h.append(ppDiv);
                                //     }
                                // }

                                // console.log(h[0]);

                                // if (b.byHeadline.length === 0 && b.byExcerpt.length === 0 && b.byMarkdown.length === 0 && b.byPerson.length === 0) {
                                //     const noRes = document.createElement("p");
                                //     noRes.innerText = "No results found.";
                                //     noRes.setAttribute("class", "no-res");
                                //     h.append(noRes);
                                // }


                                var context = document.querySelectorAll(".search-res-item");
                                var instance = new Mark(context);
                                // console.log(term);
                                // console.log(instance, context);
                                term = term.split(' ');
                                for (const t of term) {
                                    instance.mark(t);
                                }
                                

                                c.aria_expanded(!0);
                                c.keyboard_navigation();
                            }
                        })
                }

            },
            uniqid: function (a, b) {
                void 0 === a && (a = "");
                var c, d = function (a, b) {
                    return a = parseInt(a, 10).toString(16), b < a.length ? a.slice(a.length - b) : b > a.length ? new Array(b - a.length + 1).join("0") + a : a
                };
                return this.php_js || (this.php_js = {}), this.php_js.uniqidSeed || (this.php_js.uniqidSeed = Math.floor(123456789 * Math.random())), this.php_js.uniqidSeed++ , c = a, c += d(parseInt((new Date).getTime() / 1e3, 10), 8), c += d(this.php_js.uniqidSeed, 5), b && (c += (10 * Math.random()).toFixed(8).toString()), c
            }
        }, a.fn[c] = function (d) {
            return this.each(function () {
                a.data(this, "plugin_" + c) || a.data(this, "plugin_" + c, new b(a(this), d))
            }), this
        }
    }(jQuery), jQuery(document).ready(function (a) {
        a('input[data-swplive="true"]').searchwp_live_search()
    });

module.exports = { 
    Spinner 
};
