const io = require('socket.io-client')

const makeBidPanels = document.querySelectorAll('[id^="make-bid-panel"]');
const changeListingPanels = document.querySelectorAll('[id^="switch-listing-panel"]');
const makeBidBtns = document.querySelectorAll('[id^="make-bid-btn"]');
const changeListingBtns = document.querySelectorAll('[id^="switch-listing-btn"]');

function searchEleById(searchVal, myArray) {
    for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === searchVal) {
            return myArray[i];
        }
    }
};

const toggleMakeBidPanel = (auctionId, btn) => {
    const bidPanelId = `make-bid-panel-${auctionId}`;
    const changePanelId = `switch-listing-panel-${auctionId}`;
    const makeBidPanel = searchEleById(bidPanelId, makeBidPanels);
    const changeListingPanel = searchEleById(changePanelId, changeListingPanels);
    if (changeListingPanel) {
        changeListingPanel.style.setProperty("display", "none");
    }
    if (makeBidPanel) {
        const property = makeBidPanel.style.getPropertyValue("display") === "none" ? "block" : "none";
        makeBidPanel.style.setProperty("display", property);
        if (property === "block") {
            btn.innerHTML = `<i class="fas fa-chevron-up"></i>`
        } else {
            btn.innerHTML = "Bid";
        }
    }
};

const toggleChangeListingPanel = (auctionId, btn) => {
    const bidPanelId = `make-bid-panel-${auctionId}`;
    const changePanelId = `switch-listing-panel-${auctionId}`;
    const makeBidPanel = searchEleById(bidPanelId, makeBidPanels);
    const changeListingPanel = searchEleById(changePanelId, changeListingPanels);
    if (makeBidPanel) {
        makeBidPanel.style.setProperty("display", "none");
    }
    if (changeListingPanel) {
        const property = changeListingPanel.style.getPropertyValue("display") === "none" ? "block" : "none";
        changeListingPanel.style.setProperty("display", property);
        if (property === "block") {
            btn.innerHTML = `<i class="fas fa-chevron-up"></i>`
        } else {
            btn.innerHTML = "Change";
        }
    }
};

const hideElement = (targetElement) => {
    if (targetElement) {
        targetElement.style.setProperty("display", "none");
    }
};

const showElement = (targetElement) => {
    if (targetElement) {
        targetElement.style.setProperty("display", "block");
    }
};

$(function () {
    if (makeBidBtns.length > 0) {
        for (let index = 0; index < makeBidBtns.length; index++) {
            const makeBidBtn = makeBidBtns[index];
            makeBidBtn.addEventListener("click", async (event) => {
                const splits = makeBidBtn.id.split("-");
                const auctionId = splits.slice(splits.length - 1, splits.length)[0];
                toggleMakeBidPanel(auctionId, makeBidBtn);
            });
        }
    }
    if (changeListingBtns.length > 0) {
        for (let index = 0; index < changeListingBtns.length; index++) {
            const changeListingBtn = changeListingBtns[index];
            changeListingBtn.addEventListener("click", async (event) => {
                const splits = changeListingBtn.id.split("-");
                const auctionId = splits.slice(splits.length - 1, splits.length)[0];
                toggleChangeListingPanel(auctionId, changeListingBtn);
            });
        }
    }


    const AuctionPageEle = document.getElementById("auction-page");
    if (AuctionPageEle) {
        const brokerUserId = $('#broker-user-id').text(); 
        var socket = io(`/auction?userId=${brokerUserId}`);
        console.log("Socket connected to /auction ...");
        socket.on("message", (data) => {
            console.log("Auction websocket client received message - ");
            console.log("Socket received auction update - ", data);
            for (let index = 0; index < data.length; index++) {
                const auction = data[index];
                const auctionId = auction["auctionId"];
                const currentBidVal = auction.currentBid.current_bid;

                const bidHistory = auction.bidHistory;
                if (bidHistory.length > 0) {
                    const noBidEle = document.getElementById(`no-bid-${auctionId}`);
                    if (noBidEle) {
                        hideElement(noBidEle);
                        const hasBidEle = document.getElementById(`has-bid-${auctionId}`);
                        const currentBidDiv = document.getElementById(`current-bid-div-${auctionId}`);
                        if (currentBidDiv) {
                            currentBidDiv.innerHTML = `
                            <hr style="margin-top:20px;margin-bottom:10px;">
                            <h6 class="auction-blue-subtitle">
                                Current bid</h6>
                            <strong
                                id="current-bid-${auctionId}">$${currentBidVal}</strong>
                            `;
                        }
                        showElement(currentBidDiv);
                        hasBidEle.innerHTML = `
                        <div style="height:180px;overflow:scroll;">
                            <table class="auction-history-tbl" id="auction-history-${auctionId}">
                            </table>
                        </div>`;
                        showElement(hasBidEle);
                    }
                }
                // process bidding price
                const bannerInfoEle = document.getElementById(`alert-info-${auctionId}`);
                const bannerSuccessEle = document.getElementById(`alert-success-${auctionId}`);
                const bannerDangerEle = document.getElementById(`alert-danger-${auctionId}`);
                let userBid = $(`#user-bid-${auctionId}`).text();

                console.log(`User bid for auction [${auctionId}] - ${userBid}`);
                if (userBid) {
                    userBid = parseFloat(userBid);
                }
                if (!userBid) {
                    showElement(bannerInfoEle);
                    hideElement(bannerSuccessEle);
                    hideElement(bannerDangerEle);
                } else if (auction.currentBid.winning_user_id === brokerUserId) {
                    showElement(bannerSuccessEle);
                    hideElement(bannerDangerEle);
                    hideElement(bannerInfoEle);
                } else {
                    showElement(bannerDangerEle);
                    hideElement(bannerInfoEle);
                    hideElement(bannerSuccessEle);
                }

                const currentBidEle = document.getElementById(`current-bid-${auctionId}`);
                if (currentBidEle) {
                    currentBidEle.innerHTML = currentBidVal ? "$" + currentBidVal : "$ -";
                }
                // process bidding history
                let rowEleString = "";
                for (let index = 0; index < bidHistory.length; index++) {
                    const item = bidHistory[index];
                    const col2_val = brokerUserId === item.user_id ? "You" : " - ";
                    const row_style = brokerUserId === item.user_id ? "color:green;font-weight:600;" : "color:black;";
                    const color = item.is_auto ? "dodgerblue" : "green";
                    rowEleString += `<tr>
                                    <td style="${row_style}" width="80%">${item.bid_time}</td>
                                    <td style="${row_style}" width="10%">${col2_val}</td>
                                    <td style="${row_style}" width="5%">$${item.bid}</td>
                                    <td style="${row_style} padding-top: 0.5rem;" width="5%"><i style="color:${color};" class="fas fa-circle"></i></td>
                                </tr>\n`;
                }
                const historyEle = document.getElementById(`auction-history-${auctionId}`);
                if (historyEle) {
                    historyEle.innerHTML = rowEleString;
                }
            }
        });
    }

    const countDownEle = document.getElementById("count-down");

    // Update the count down every 1 second
    var x = setInterval(function () {
        if (countDownEle) {
            const endTime = countDownEle.getAttribute("value");
            var countDownDate = new Date(endTime).getTime();
            // Get todays date and time
            var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // If the count down is over, write some text 
            if (distance < 0) {
                clearInterval(x);
                document.getElementById("count-down").innerHTML = "EXPIRED";
                document.getElementById("count-down").style.setProperty("color", "red");
            }
            // Output the result in an element with id="count-down"
            if (days >= 1) {
                document.getElementById("count-down").innerHTML = days + " days " + hours + " hours ";
            } else {
                if (hours > 1) {
                    document.getElementById("count-down").innerHTML = hours + " hours  " + minutes + " minutes";
                } else {
                    if (minutes > 1) {
                        document.getElementById("count-down").innerHTML = minutes + " minutes  " + seconds + " seconds";
                    } else {
                        if (seconds > 1) {
                            document.getElementById("count-down").innerHTML = seconds + " seconds";
                            document.getElementById("count-down").style.setProperty("color", "red");
                        }
                    }
                }
            }
        }
    }, 1000);

})