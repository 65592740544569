
const upload = require("../common/fileUpload.js");
const autocomplete = require("../common/autocomplete.js");
const createEditCompanyForm = require("./createEditCompanyForm.js");
const updateEditCompanyForm = require("./updateBrokerProfileForm.js");

if (page === "broker-admin") {
    // params are dropzone element id (string), image only or part of form (boolean), context/location of dropzone ele (string)
    upload.initiateDropzone("#company-logo", false);
    upload.initiateDropzone("#logo-overlay", true, "edit-logo");
    upload.initiateDropzone("#company-logo-2", true, "edit-logo");
    upload.initiateDropzone("#broker-photo", true, "edit-broker-photo");
    upload.initiateDropzone("#photo-overlay", true, "edit-broker-photo");

    const editCompanyBtn = document.getElementById("edit-company-toggle");
    if (editCompanyBtn) {
        editCompanyBtn.addEventListener("click", (e) => {

            const editPanel = document.getElementById("edit-company-panel");

            if (editPanel) {
                const panelDisplay = editPanel.style.getPropertyValue("display") === "none" ? false : true;
                if (panelDisplay) {
                    editPanel.style.display = "none";
                    editCompanyBtn.innerHTML = "Edit";
                } else {
                    editPanel.style.display = "block";
                    editCompanyBtn.innerHTML = `<i class="fas fa-chevron-up"></i>`;
                }

            }
        });

    }
    const initializeImageEdit = (imgEle, overlay, editBtn) => {
        imgEle.addEventListener("mouseover", (e) => {

            const overlayEle = document.getElementById(overlay);
            if (overlayEle) {
                overlayEle.style.display = "flex";

                const editBtnEle = document.getElementById(editBtn);
    
                editBtnEle.addEventListener("mouseenter", (e) => {
                    overlayEle.style.display = "flex";
                });
                editBtnEle.addEventListener("mouseleave", (e) => {
                    overlayEle.style.display = "none";
                });
    
                overlayEle.addEventListener("mouseenter", (e) => {
                    overlayEle.style.display = "flex";
                });
                overlayEle.addEventListener("mouseleave", (e) => {
                    overlayEle.style.display = "none";
                });
            }
        });
        imgEle.addEventListener("mouseleave", (e) => {
            const overlayEle = document.getElementById(overlay);
            if (overlayEle) overlayEle.style.display = "none";

        });

    };

    const companyLogo = document.getElementById("company-logo-2");

    const brokerPhoto = document.getElementById("broker-photo");

    if (companyLogo) {
        initializeImageEdit(companyLogo, "logo-overlay", "edit-logo-btn");
    }

    if (brokerPhoto) {
        initializeImageEdit(brokerPhoto, "photo-overlay", "edit-broker-photo-btn");
    }

    const autocompleteAddress = (e, getLatLng, autocompleteAddress, closeAllLists, inputPrefix) => {
        $(`#${inputPrefix}-address1`).val(autocompleteAddress.houseNumber + ' ' + autocompleteAddress.street);
        $(`#${inputPrefix}-city`).val(autocompleteAddress.city);
        $(`#${inputPrefix}-zip`).val(autocompleteAddress.postalCode); 
        if (!inputPrefix.includes('profile')) {
            createEditCompanyForm.setStates(autocompleteAddress.countryCode, inputPrefix.split("-")[0])
        } else {
            updateEditCompanyForm.setStatesProfile(autocompleteAddress.countryCode)
        }
        $(`#${inputPrefix}-state`).val(autocompleteAddress.stateCode);
        $(`#${inputPrefix}-country`).val(autocompleteAddress.countryCode);
        closeAllLists();
    }

    const createCompanyInput = document.getElementById("create-company-address1");
    const editCompanyInput = document.getElementById("edit-company-address1");
    const updateProfileInput = document.getElementById("update-profile-address1");

    if (createCompanyInput) {
        autocomplete.beginAutocomplete("create-company-address1", autocompleteAddress, "create-company");
    }

    if (editCompanyInput) {
        autocomplete.beginAutocomplete("edit-company-address1", autocompleteAddress, "edit-company");
    }

    if (updateProfileInput) {
        autocomplete.beginAutocomplete("update-profile-address1", autocompleteAddress, "update-profile");
    }

    const activateBtns = document.getElementsByClassName("activate-listing-btn");
    const deactivateBtns = document.getElementsByClassName("deactivate-listing-btn");

    for (let b of activateBtns) {
        b.addEventListener("click", e => {
            e.preventDefault();
            const id = b.getAttribute("id").split("listing-")[1];
            $.ajax({
                type: 'POST',
                data: { id: id,
                "activate": true },
                url: `/listings/toggle`,
                success: function (data) {
                    window.location.href = "/broker";
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // server responds with 200 in case of error, error msg is rendered through ejs
                    console.error({
                        errorThrown
                    });
                }
            });
        });
    }

    for (let b of deactivateBtns) {
        b.addEventListener("click", e => {
            e.preventDefault();
            const id = b.getAttribute("id").split("listing-")[1];
            $.ajax({
                type: 'POST',
                data: { id: id,
                "deactivate": true },
                url: `/listings/toggle`,
                success: function (data) {
                    window.location.href = "/broker";
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // server responds with 200 in case of error, error msg is rendered through ejs
                    console.error({
                        errorThrown
                    });
                }
            });
        });
    }
}