const validate = require("validate.js");
// const dropzone = require("dropzone");
const moment = require("moment");

const commonJS = require("./common");
const billingJS = require("./billing");
const profileJS = require("./profile");
const listingsJS = require("./listings");
const sessionPocJS = require("./session-poc");
const reportsJS = require("./reports");
const messagingJS = require("./messaging");
const auctionJS = require("./auction");
const brokersJS = require("./brokers");
const insiderJS = require("./insider");
const adminJS = require("./admin");
const homeJS = require("./home");
const paymentJS = require("./payment");