const mapStyle = require("./mapStyle.js");
const driveTime = require("./driveTime.js");

if (page && page === "listingsMain") {

    favIds = favIds.map(f => +f);

    const addFavorite = (id) => {
        if (loggedIn) {
            const btn = document.getElementById(`fav-btn-${id}`);
            const btnMob = document.getElementById(`fav-mob-btn-${id}`);
            const fav = btn.className.includes("favorite") ? true : false;
            if (fav) {
                favIds = favIds.filter(f => { return f !== +id; });
                $.ajax({
                    type: 'POST',
                    data: { id: id },
                    url: `/listings/delete-favorite`,
                    success: function (data) {
                        btn.innerHTML = '<i class="far fa-heart"></i>';
                        btn.classList.remove("favorite");
                        btnMob.innerHTML = '<i class="far fa-heart"></i>';
                        btnMob.classList.remove("favorite");
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        // TODO handle error
                        console.error({
                            errorThrown
                        });
                    }
                });
            }
            else {
                favIds.push(+id);
                $.ajax({
                    type: 'POST',
                    data: { id: id },
                    url: `/listings/add-favorite`,
                    success: function (data) {
                        btn.innerHTML = '<i class="fas fa-heart" style="color: #d80517;"></i>';
                        btn.classList.add("favorite");
                        btnMob.innerHTML = '<i class="fas fa-heart" style="color: #d80517;"></i>';
                        btnMob.classList.add("favorite");
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        // TODO handle error
                        console.error({
                            errorThrown
                        });
                    }
                });
            }
        } else {
            const signupModal = document.getElementById('signup-modal');
            const signupModalMob = document.getElementById('signup-modal-mob');
            const backdrop = document.getElementById('modal-backdrop');
            backdrop.addEventListener("click", (e) => {
                const signupModal = document.getElementById('signup-modal');
                const signupModalMob = document.getElementById('signup-modal-mob');
                const backdrop = document.getElementById('modal-backdrop');
                signupModal.style.display = "none";
                signupModalMob.style.display = "none";
                backdrop.style.display = "none";
            });
            signupModal.style.display = "block";
            signupModalMob.style.display = "block";
            backdrop.style.display = "block";
        }
    };

    const activateFavBtns = () => {
        const favBtns = document.getElementsByClassName("listing-main-add-fav");
        for (const b of favBtns) {
            b.addEventListener("click", (e) => {
                const id = b.getAttribute("id").split("fav-btn-")[1];
                addFavorite(id);
            });
        }

        const favBtnsMob = document.getElementsByClassName("listing-main-add-fav-mob");
        for (const b of favBtnsMob) {
            b.addEventListener("click", (e) => {
                const id = b.getAttribute("id").split("fav-mob-btn-")[1];
                addFavorite(id);
            });
        }
    }

    activateFavBtns();


    const closeModal = () => {
        const signupModal = document.getElementById('signup-modal');
        const signupModalMob = document.getElementById('signup-modal-mob');
        const backdrop = document.getElementById('modal-backdrop');
        signupModal.style.display = "none";
        signupModalMob.style.display = "none";
        backdrop.style.display = "none";
    };

    const closeModalBtn = document.getElementById("close-modal-btn");
    if (closeModalBtn) {
        closeModalBtn.addEventListener("click", (e) => {
            closeModal();
        });
    }

    const closeModalBtnMob = document.getElementById("close-modal-btn-mob");
    if (closeModalBtnMob) {
        closeModalBtnMob.addEventListener("click", (e) => {
            closeModal();
        });
    }

    const sort = document.getElementById('listings-sort-by');
    sort.addEventListener('change', (e) => {
        const order = e.target.value;
        history.pushState({}, "", `/listings?orderBy=${order}&favorites=${showFavsOnly ? 'true' : 'false'}`);
        $('#listing-cards-wrapper-mob').load(`/listings/get-cards?orderBy=${order}&favorites=${showFavsOnly ? 'true' : 'false'}&mobile=true`, () => {
            activateFavBtns();
            activateCards();
        });
        $('#listing-cards-wrapper').load(`/listings/get-cards?orderBy=${order}&favorites=${showFavsOnly ? 'true' : 'false'}&mobile=false`, () => {
            activateFavBtns();
            activateCards();
        });
        // window.location = `/listings/?orderBy=${order}`;
    });

    const sortMob = document.getElementById('listings-sort-by-mob');
    sortMob.addEventListener('change', (e) => {
        const order = e.target.value;
        history.pushState({}, "", `/listings?orderBy=${order}&favorites=${showFavsOnly ? 'true' : 'false'}`);
        $('#listing-cards-wrapper-mob').load(`/listings/get-cards?orderBy=${order}&favorites=${showFavsOnly ? 'true' : 'false'}&mobile=true`, () => {
            activateFavBtns();
            activateCards();
        });
        $('#listing-cards-wrapper').load(`/listings/get-cards?orderBy=${order}&favorites=${showFavsOnly ? 'true' : 'false'}&mobile=false`, () => {
            activateFavBtns();
            activateCards();
        });
        // window.location = `/listings/?orderBy=${order}`;
    });

    const toggleListingFavorites = () => {
        const toggleEle = document.getElementById("listings-fav-toggle");
        const toggleEleMob = document.getElementById("listings-fav-toggle-mob");

        if (toggleEle) {

            toggleEle.addEventListener('click', (e) => {
                if (loggedIn) {
                    if (!showFavsOnly) {
                        map.setFilter("listings-points", ["in", "id", ...favIds]);
                        const order = window.location.href.indexOf("orderBy") > 1 ? window.location.href.split("orderBy=")[1][0] : 1;
                        history.pushState({}, "", `/listings?orderBy=${order}&favorites=true`);
                        $('#listing-cards-wrapper-mob').load(`/listings/get-cards?favorites=true&orderBy=${order}&mobile=true`, () => {
                            activateFavBtns();
                            activateCards();
                        });
                        $('#listing-cards-wrapper').load(`/listings/get-cards?favorites=true&orderBy=${order}&mobile=false`, () => {
                            activateFavBtns();
                            activateCards();
                        });
                        showFavsOnly = true;
                    } else {
                        map.setFilter("listings-points", null);
                        const order = window.location.href.indexOf("orderBy") > 1 ? window.location.href.split("orderBy=")[1][0] : 1;
                        history.pushState({}, "", `/listings?orderBy=${order}&favorites=false`);
                        $('#listing-cards-wrapper-mob').load(`/listings/get-cards?favorites=false&orderBy=${order}&mobile=true`, () => {
                            activateFavBtns();
                            activateCards();
                        });
                        $('#listing-cards-wrapper').load(`/listings/get-cards?favorites=false&orderBy=${order}&mobile=false`, () => {
                            activateFavBtns();
                            activateCards();
                        });
                        showFavsOnly = false;
                    }
                } else {
                    e.preventDefault();
                    const signupModal = document.getElementById('signup-modal');
                    const signupModalMob = document.getElementById('signup-modal-mob');
                    const backdrop = document.getElementById('modal-backdrop');
                    backdrop.addEventListener("click", (e) => {
                        const signupModal = document.getElementById('signup-modal');
                        const signupModalMob = document.getElementById('signup-modal-mob');
                        const backdrop = document.getElementById('modal-backdrop');
                        signupModal.style.display = "none";
                        signupModalMob.style.display = "none";
                        backdrop.style.display = "none";
                    });
                    signupModal.style.display = "block";
                    signupModalMob.style.display = "block";
                    backdrop.style.display = "block";
                }
            });

        }
        if (toggleEleMob) {

            toggleEleMob.addEventListener('click', (e) => {

                if (loggedIn) {
                    if (!showFavsOnly) {
                        map.setFilter("listings-points", ["in", "id", ...favIds]);
                        map.setFilter("listing-prices", ["in", "id", ...favIds]);
                        showFavsOnly = true;
                    } else {
                        map.setFilter("listings-points", null);
                        map.setFilter("listing-prices", null);
                        showFavsOnly = false;
                    }
                } else {
                    e.preventDefault();
                    const signupModal = document.getElementById('signup-modal');
                    const signupModalMob = document.getElementById('signup-modal-mob');
                    const backdrop = document.getElementById('modal-backdrop');
                    backdrop.addEventListener("click", (e) => {
                        const signupModal = document.getElementById('signup-modal');
                        const signupModalMob = document.getElementById('signup-modal-mob');
                        const backdrop = document.getElementById('modal-backdrop');
                        signupModal.style.display = "none";
                        signupModalMob.style.display = "none";
                        backdrop.style.display = "none";
                    });
                    signupModal.style.display = "block";
                    signupModalMob.style.display = "block";
                    backdrop.style.display = "block";
                }
            });

        }
    }

    toggleListingFavorites();

    const tabLinks = document.getElementsByClassName("tablinks");
    for (const t of tabLinks) {
        t.addEventListener("click", (e) => {
            const target = t.getAttribute("data-target");
            openTab(e, target);
        });
    }

    // geoString = geoString.replace(/(&#34;)/gm, '\"')
    // const geo = JSON.parse(geoString);

    // const features = geo.features;

    let reset = true;
    let zoom = true;

    const resetMap = (map) => {
        const bounds = [[-128, 23], [-66, 50]];
        if (reset) {
          map.flyTo({ center: [-100, 40], zoom: 3, bearing: 0, pitch: 0, bounds: bounds, speed: 1.5 });
          if (popup) {
            popup.remove();
          }
        }
    };

    const zoomMap = (map, coords) => {
        if (zoom) {
            const maxWLng = -130;
            const maxELng = -65;
            const lng = coords[0];
            const lngPerc = 1 - ((Math.abs(lng) - Math.abs(maxELng)) / (Math.abs(maxWLng) - Math.abs(maxELng)));
            const bearingRange = 140;
            const bearingMin = 70;
            const bearing = bearingMin - (bearingRange * lngPerc);
            map.flyTo({ center: coords, zoom: 12, bearing: bearing, pitch: 60, speed: 1.5 });
        }
    };

    let popup;

    const activateCards = () => {
        const els = document.getElementsByClassName('wrapper-inner');
        for (let el of els) {
            el.addEventListener('mouseover', (e) => {
                reset = false;
                zoom = true;
                let id = el.getAttribute('id');
                id = id.split('-');
                id = id[1];
                const feat = features.find((feat) => {
                    return feat.properties.id == id;
                });

                const coordinates = feat.geometry.coordinates;
                const name = feat.properties.name;
                const price = feat.properties.price;
                const imgUrl = feat.properties.imgUrl ? feat.properties.imgUrl : "https://urt-listings.s3.amazonaws.com/common/nofacility.jpg";
                const address = feat.properties.address;

                const html = `<div class="list-popup">
                <span class="list-popup-name">${name}</span><br>
                <img class="list-popup-img" src="${imgUrl}"><br>
                <span class="list=opup-address">${address}</span><br>
                <span class="font-radius-black c-blue-black">${price}</span>
                </div>`;

                popup.setLngLat(coordinates)
                    .setHTML(html)
                    .addTo(map);
                const coords = feat.geometry.coordinates;
                setTimeout(() => {
                    zoomMap(map, coords);
                }, 1000);
            });
            el.addEventListener('mouseleave', (e) => {
                reset = true;
                zoom = false;

                setTimeout(() => {
                    resetMap(map);

                }, 2000);
            });
        }
    }

    activateCards();


  mapboxgl.accessToken = "pk.eyJ1IjoiZGlnaXRhbGdsb2JlIiwiYSI6ImNqaGphNmdzdDJkbjIzNm50YmJzYjEzbDkifQ.m4axiz_t3BE3_nQs5iAaIg";
  

    let map;
    const initializeListMap = (container) => {
        const bounds = [[-128, 23], [-66, 50]];

        map = new mapboxgl.Map({
            container: container,
            style: 'mapbox://styles/mapbox/streets-v9',
            center: [-100, 40],
            zoom: 3,
            style: mapStyle.style,
            bounds: bounds
        });

        const activateMapEvent = () => {
            const el = document.getElementById(container);
            el.addEventListener('mouseover', (e) => {
                reset = false;
            });
            el.addEventListener('mouseleave', (e) => {
                reset = true;
                resetMap(map);
            });
        };

        activateMapEvent();


        map.on('load', function () {
            console.log("map initialized");

            map.addSource("listings", {
                "type": "geojson",
                "data": geo
            });

            console.log(favIds);

            map.addLayer({
                "id": "listings-points",
                "type": "circle",
                "source": "listings",
                "paint": {
                    "circle-radius": 7,
                    'circle-color': [
                        'match',
                        ['get', 'id'],
                        favIds, 'red', '#FF8037'
                    ],
                    "circle-stroke-width": 1,
                },
            });

            map.addLayer({
                "id": "listings-points-hover",
                "type": "circle",
                "source": "listings",
                "paint": {
                    "circle-radius": 8,
                    'circle-color': [
                        'match',
                        ['get', 'id'],
                        favIds, 'red', '#FF8037'
                    ],
                    "circle-stroke-width": 2,
                },
                "filter": ["==", "id", ""]
            });


            popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            if (showFavsOnly) {
                map.setFilter("listings-points", ["in", "id", ...favIds]);
            }

            map.on("click", "listings-points", function (e) {
                const id = e.features[0].properties.id;
                window.location = `/listings/${id}/listing`;
            });

            map.on("mouseenter", "listings-points", function (e) {
                map.getCanvas().style.cursor = "pointer";
                map.setFilter("listings-points-hover", ["==", "id", e.features[0].properties.id]);
                const id = e.features[0].properties.id;
                const coordinates = e.features[0].geometry.coordinates.slice();
                const name = e.features[0].properties.name;
                const price = e.features[0].properties.price;
                const imgUrl = e.features[0].properties.imgUrl;
                const address = e.features[0].properties.address;

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                const html = `<div class="list-popup">
                <span>${name}</span><br>
                <img class="list-popup-img" src="${imgUrl}"><br>
                <span>${address}</span><br>
                <span class="font-radius-black c-blue-black">${price}</span>
                </div>`;

                if (popup) {
                  popup.setLngLat(coordinates)
                    .setHTML(html)
                    .addTo(map);

                }
                const el = document.getElementById('listing-' + id);
                el.setAttribute('class', 'wrapper-inner listing-card-hover');
                // el.scrollIntoView({ block: 'end', behavior: 'smooth' });
            });

            map.on("mouseleave", "listings-points", function (e) {
                map.getCanvas().style.cursor = "";
                //const id = e.features[0].properties.id;
                map.setFilter("listings-points-hover", ["==", "id", ""]);
                popup.remove();

                const els = document.getElementsByClassName('wrapper-inner');
                for (let el of els) {
                    el.classList.remove('listing-card-hover');
                }
            });
        });


    };

    initializeListMap("listing-list-map");

    const openTab = (e, tabName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tabName).style.display = "block";
        e.currentTarget.className += " active";
        const mobileMap = document.getElementById("listing-list-map-mobile");
        if (tabName === "Map" && map && !mobileMap.hasChildNodes()) {
            map.remove();
            initializeListMap("listing-list-map-mobile");
        }
        if (tabName === "List") {
            document.getElementById("colophon").style.position = "relative";
        }
        if (tabName === "Map") {
            document.getElementById("colophon").style.position = "fixed";
        }
    }

    const rerenderMap = (x) => {
        if (x.matches) {
            map.remove();
            initializeListMap("listing-list-map");
        } else {

        }
    };

    const unstickFooter = (y) => {
        if (y.matches) {
            map.remove();
            document.getElementById("colophon").style.position = "relative";
        } else {

        }
    };

    const x = window.matchMedia("(min-width: 1020px)")
    rerenderMap(x)
    x.addListener(rerenderMap)

    const y = window.matchMedia("(max-width: 1020px)")
    unstickFooter(y)
    y.addListener(rerenderMap)

}

if (page && page === "listingDetails") {
    const listingsFeedback = () => {
        Intercom("showNewMessage", "I'd like to offer some feedback on listings");
    };

    // const feedbackbBtn = document.getElementById("feedback-btn");
    // feedbackbBtn.addEventListener("click", (e) => {
    //     listingsFeedback();
    // });

    const addFavorite = (id) => {
        if (loggedIn) {
            const btn = document.getElementById('add-listing-fav');
            const fav = btn.className.includes("favorite") ? true : false;
            if (fav) {
                $.ajax({
                    type: 'POST',
                    data: { id: id },
                    url: `/listings/delete-favorite`,
                    success: function (data) {
                        btn.innerHTML = '<i class="far fa-heart"></i>';
                        btn.classList.remove("favorite");
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        // TODO handle error
                        console.error({
                            errorThrown
                        });
                    }
                });
            }
            else {
                $.ajax({
                    type: 'POST',
                    data: { id: id },
                    url: `/listings/add-favorite`,
                    success: function (data) {
                        btn.innerHTML = '<i class="fas fa-heart" style="color: #d80517;"></i>';
                        btn.classList.add("favorite");
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        // TODO handle error
                        console.error({
                            errorThrown
                        });
                    }
                });
            }
        } else {
            const signupModal = document.getElementById('signup-modal');
            const backdrop = document.getElementById('modal-backdrop');
            backdrop.addEventListener("click", (e) => {
                const signupModal = document.getElementById('signup-modal');
                const backdrop = document.getElementById('modal-backdrop');
                signupModal.style.display = "none";
                backdrop.style.display = "none";
            });
            signupModal.style.display = "block";
            backdrop.style.display = "block";
        }
    };

    const addFavBtn = document.getElementById("add-listing-fav");
    addFavBtn.addEventListener("click", (e) => {
        addFavorite(listingId);
    });

    const closeModal = () => {
        const signupModal = document.getElementById('signup-modal');
        const backdrop = document.getElementById('modal-backdrop');
        signupModal.style.display = "none";
        backdrop.style.display = "none";
    };

    const closeModalBtn = document.getElementById("close-modal-btn");
    if (closeModalBtn) {
        closeModalBtn.addEventListener("click", (e) => {
            closeModal();
        });
    }

    const loanForm = document.getElementById('loan-calc-form');
    const loanInputs = document.getElementsByClassName('loan-input');

    for (const l of loanInputs) {
        l.addEventListener("change", (e) => {
            e.preventDefault();
            calculateLoan();
        });
    }

    loanForm.addEventListener("submit", (e) => {
        e.preventDefault();
        calculateLoan();
    });

    const calcPayment = (ir, np, pv, fv, type) => {
        var pmt, pvif;

        fv || (fv = 0);
        type || (type = 0);

        if (ir === 0)
            return -(pv + fv) / np;

        pvif = Math.pow(1 + ir, np);
        pmt = - ir * pv * (pvif + fv) / (pvif - 1);

        if (type === 1)
            pmt /= (1 + ir);

        return pmt;
    }

    const calculateLoan = () => {
        const amount = document.getElementById('amount').value;
        let interest = document.getElementById('interest').value;
        interest = interest / 100 / 12;
        let term = document.getElementById('term').value;
        term = term * 12;
        const payment = calcPayment(interest, term, -amount);
        const resEl = document.getElementById('loan-result');
        resEl.innerText = `$ ${payment.toFixed(2)}`;

    };

    calculateLoan();

    mapboxgl.accessToken = "pk.eyJ1IjoiZGlnaXRhbGdsb2JlIiwiYSI6ImNqaGphNmdzdDJkbjIzNm50YmJzYjEzbDkifQ.m4axiz_t3BE3_nQs5iAaIg";

    const map = new mapboxgl.Map({
        container: 'listing-detail-map',
      // style: 'mapbox://styles/mapbox/streets-v9',
        style: mapStyle.style,
        center: [detailLng, detailLat],
        zoom: 12
    });

    setInterval(() => { map.resize(); }, 100);

    map.on('load', function () {

        map.addLayer({
            "id": "points",
            "type": "circle",
            "source": {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [detailLng, detailLat],
                            zoom: 9
                        },
                        "properties": {
                        }
                    }]
                }
            },
            "paint": {
                "circle-radius": 7,
                "circle-color": "#FF8037"
            }
        });
    });

    const mapDriveTime = new mapboxgl.Map({
        container: 'drive-time-map',
        center: [detailLng, detailLat],
        zoom: 9.5,
        style: mapStyle.style,
        interactive: false
    });

    mapDriveTime.on('load', function () {
        driveTime.initializeDriveTimeLayers(mapDriveTime, detailLat, detailLng);
    });

    const openTab = (e, tabName) => {
        console.log(tabName);

        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tabName).style.display = "flex";
        e.currentTarget.className += " active";

        if (tabName === 'Location') {
            mapDriveTime.resize();
        }
    }

    const tabLinks = document.getElementsByClassName("tablinks");
    for (const t of tabLinks) {
        t.addEventListener("click", (e) => {
            const target = t.getAttribute("data-target");
            openTab(e, target);
        });
    }

    // visitor tracking
    const offset = new Date().getTimezoneOffset();
    const utc = new Date().getTime();
    let payload = { offset, utc };
    visitor = visitor.replace(/&#34;/g, '"');
    visitor = JSON.parse(visitor);
    payload = {...payload, ...visitor};
    $.ajax({
        type: 'POST',
        data: payload,
        url: `/listings/visitors`,
        success: function (data) {
           console.log("Visit recorded: ", payload);
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.error("Error recording a visit: ", {
                errorThrown
            });
        }
    });
}