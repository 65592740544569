const statusForm = document.getElementById('status-form');

const formArrayToJSON = (arr) => {
    let json = {};
    for (var i = 0; i < arr.length; i++) {
        json[arr[i]['name']] = arr[i]['value'];
    }
    return json;
};

if (statusForm) {
    // Proccess form and append file objects before submitting
    statusForm.addEventListener("submit", function (e) {
        e.preventDefault();
        var post_url = $(this).attr("action");
        var request_method = $(this).attr("method");
        var form_data = $(this).serializeArray();

        form_data = formArrayToJSON(form_data);
        console.log(form_data);

        $.ajax({
            url: post_url,
            type: request_method,
            data: JSON.stringify(form_data),
            contentType: 'application/json',
            success: function (data) {
                console.log('Sent!');
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error({
                    "Failed: ": errorThrown
                });
            }
        });
    });
}
