const { loginPopup } = require("../common/loginPopup.js")

const brokerDiv = document.getElementById("broker-info");
const brokerChatDisabled = document.getElementById("broker-info-chat-disabled");
const brokerSide = document.getElementById("broker-side");
const inboxDiv = document.getElementById("inbox-container");
const chatInboxBtn = document.getElementById("broker-chat-inbox");
const newChatBtn = document.getElementById("new-broker-chat");

const getBroker = () => {
    let brokerUser;
    var elName = brokerSide ? "broker-side" : "broker-info";
    var inputElement = document.getElementById(elName);
    if (inputElement) {
        const info = inputElement.value;
        brokerUser = JSON.parse(decodeURIComponent(info));
        newConversation = true;
        localStorage.setItem("broker", JSON.stringify(brokerUser))
        console.log("Broker from input =>", brokerUser);
        return brokerUser;
    } else {
        if (brokerChatDisabled) {
            const info = brokerChatDisabled.value;
            brokerUser = JSON.parse(decodeURIComponent(info));
            const brokerUserId = brokerUser.id;
            $.ajax({
                type: 'GET',
                url: `/listings/available-broker?id=${brokerUserId}`,
                async: false,
                success: function (data) {
                    if (data.brokerInfo) {
                        brokerUser = data.brokerInfo;
                        newConversation = true;
                        localStorage.setItem("broker", JSON.stringify(brokerUser));
                        console.log("Broker's chat disabled, team mate found =>", brokerUser);
                        return data.brokerInfo;
                    } else {
                        newConversation = true;
                        console.log("Broker's chat disabled, no team mates found for =>", brokerUser);
                        brokerUser = {...brokerUser, ...data};
                        return brokerUser;
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // TODO handle error
                    console.error({
                        errorThrown
                    });
                }
            });
        } else {
            brokerUser = localStorage.getItem("broker");
            brokerUser = JSON.parse(brokerUser);
            console.log("Broker in LS =>", brokerUser);
            return brokerUser;
        }
    }
    return brokerUser;
}

const getVisitor = () => {
    let visitorUser;
    var inputElement = document.getElementById('visitor-info');
    if (inputElement) {
        let info = inputElement.value;
        info = JSON.parse(decodeURIComponent(info));
        const idLength = info.userId.length;
        let visitorName = "Visitor_" + info.userId.slice(idLength - 7, idLength);
        if (info.givenName && info.familyName) {
            visitorName = info.givenName + " " + info.familyName;
        } else if (info.username) {
            visitorName = info.username;
        }
        visitorUser = {
            id: info.userId,
            name: visitorName,
            role: "client"
        }
        newConversation = true
        localStorage.setItem("visitor", JSON.stringify(visitorUser))
        console.log("Visitor from input =>", visitorUser);
    } else {
        visitorUser = localStorage.getItem("visitor");
        visitorUser = JSON.parse(visitorUser);
        console.log("Visitor in LS =>", visitorUser);
    }

    return visitorUser;
}

const toggleInboxElement = () => {
    if (inboxDiv) {
        const property = inboxDiv.style.getPropertyValue("display") === "none" ? "block" : "none";
        inboxDiv.style.setProperty("display", property);
    }
}

const startNewChat = async () => {
    var brokerInfo = getBroker();
    var visitorInfo = getVisitor();
    console.log(brokerInfo);
    if (brokerInfo && visitorInfo && !brokerInfo.unavailable) {
        var visitor = new Talk.User(visitorInfo);
        var broker = new Talk.User(brokerInfo);
        const conversationId = Talk.oneOnOneId(visitor, broker);
        console.log("ConversationId - ", conversationId);
        conversation = window.talkSession.getOrCreateConversation(conversationId);
        conversation.setParticipant(visitor);
        conversation.setParticipant(broker);

        if (inboxDiv) {
            const inbox = window.talkSession.createInbox({ selected: conversation });
            inbox.mount(document.getElementById("inbox-container"));
            inboxDiv.style.setProperty("display", "block");
        }
    } 
}

if (chatInboxBtn) {
    chatInboxBtn.addEventListener('click', async (event) => {
        event.preventDefault();
        toggleInboxElement();
        const icon = chatInboxBtn.children[0].getAttribute("class") !== "fas fa-times" ? "fas fa-times" : "fas fa-comment-alt";
        chatInboxBtn.children[0].setAttribute("class", icon);
    });
}

if (newChatBtn) {
    newChatBtn.addEventListener('click', async (event) => {
        event.preventDefault();
        if (loggedIn) {
            startNewChat();
            chatInboxBtn.children[0].setAttribute("class", "fas fa-times");
        } else {
            loginPopup();
        }
    });
}

if (brokerDiv || brokerSide || inboxDiv) {
    // load talk js
    (function (t, a, l, k, j, s) {
        s = a.createElement('script'); s.async = 1; s.src = "https://cdn.talkjs.com/talk.js"; a.head.appendChild(s);
        k = t.Promise; t.Talk = {
            v: 1,
            ready: {
                then: function (f) {
                    if (k) return new k(function (r, e) { l.push([f, r, e]) }); l
                        .push([f])
                }, catch: function () { return k && new k() }, c: l
            }
        };
    })(window, document, []);

    // initialize talk js with users
    Talk.ready.then(function () {
        const brokerInfo = getBroker();
        console.log(brokerInfo);
        var visitorInfo = getVisitor();
        let broker;
        if (brokerInfo) {
            broker = new Talk.User(brokerInfo);
        }

        if (brokerSide) {
            window.talkSession = new Talk.Session({
                appId: "Gd2CE6K6",
                me: broker
            });
        } else {
            var visitor = new Talk.User(visitorInfo);

            window.talkSession = new Talk.Session({
                appId: "Gd2CE6K6",
                me: visitor
            });
        }

        var visitor = new Talk.User(visitorInfo);

        let conversation;
        let conversationId;
        if (broker) {
            conversationId = Talk.oneOnOneId(visitor, broker);
            conversation = window.talkSession.getOrCreateConversation(conversationId);
        }

        if (brokerInfo && visitorInfo && !brokerInfo.unavailable) {
            broker.availabilityText = null;
            broker.welcomeMessage = "Hey, how can I help?";
            conversation.setParticipant(visitor);
            conversation.setParticipant(broker);
            console.log("Found both visitor and broker info, conversationId - ", conversationId);
        } else if (brokerInfo && visitorInfo && brokerInfo.unavailable) {
            broker.availabilityText = "This broker has disabled their chat.";
            broker.welcomeMessage = null;
            conversation.setParticipant(visitor);
            conversation.setParticipant(broker);
            console.log("Found both visitor and broker info, broker has disabled chat, conversationId - ", conversationId);
        }

        if (inboxDiv) {
            let inbox;
            if (conversation) {
                inbox = window.talkSession.createInbox({ selected: conversation });
            } else {
                inbox = window.talkSession.createInbox({ selected: null });
            }
            inbox.mount(document.getElementById("inbox-container"));
            if (newChatBtn) {
                const openChat = newChatBtn.getAttribute("start");

                if (openChat === 'true') {
                    if (loggedIn) {
                        inboxDiv.style.setProperty("display", "block");
                        chatInboxBtn.children[0].setAttribute("class", "fas fa-times");
                    } else {
                        loginPopup();
                    }
                } else {
                    inboxDiv.style.setProperty("display", "none");
                }
            } else {
                inboxDiv.style.setProperty("display", "none");
            }
        }
        newMsgNotifications();

    });

    function newMsgNotifications() {
        // new message notifications
        window.talkSession.unreads.on("change", function (conversationIds) {
            var amountOfUnreads = conversationIds.length;

            // update the tab title so users can easily see that they have messages waiting
            if (amountOfUnreads > 0) {
                if (chatInboxBtn) {
                    chatInboxBtn.children[0].setAttribute("class", "fas fa-envelope");
                }
            }
        });
    }

}
