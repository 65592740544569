const ladda = require("ladda");

const startLadda = (ele) => {
    if (!ele.laddaInstance) {
        ele.laddaInstance = ladda.create(ele);
    };
    ele.laddaInstance.start();
    const spinnerML = (ele.scrollWidth - 32)*1.0 / 2;
    ele.children[1].children[0].style.setProperty("margin-left", `${spinnerML}px`);
    const label = ele.children[0];
    label.style = "display:none";
}

module.exports = {
    startLadda
}