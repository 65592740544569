let removeCard;
let setAsDefault;
const laddaStart = require('../common/laddaStart')

$(document).ready(function() {
    const pk = stripePublicKey;
    const stripe = Stripe(pk);
    const els = stripe.elements();

    const findForm = () => {
        let form = null, num = 0;
        const addCardForm = document.getElementById('add_card_form');
        const postOrderForm = document.getElementById('post-order-form');
        const ccEntryForm = document.getElementById('cc-entry-form');
        const subForm = document.getElementById('subscription-checkout-form');
        if (addCardForm) form = addCardForm, num = 1;
        if (postOrderForm) form = postOrderForm, num = 2;
        if (subForm) form = subForm, num = 3;
        return { form, num, ccEntryForm };
    }

    const findConfirmBtn = () => {
        let confirmBtn = null;
        confirmBtn = document.getElementById("btn-confirm-checkout");
        return confirmBtn;
    }

    const { form, num, ccEntryForm } = findForm();

    console.log("Form element found => ", form);
    console.log("CC Entry form found => ", ccEntryForm);

    // Only load if 'add card form' is on page OR if 'post order form' is on page && user has no default card
    if (form && ((num === 2 && ccEntryForm) || num === 1 || (num === 3 && ccEntryForm))) {
        const createCardElement = (includePostalCode) => {
            const style = {
                base: {
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#f1f1f1",
                    }
                }
            };

            const cardNumber = els.create("cardNumber", { style: style.style });
            const cardExpiry = els.create("cardExpiry", { style: style.style });
            const cardCvc = els.create("cardCvc", { style: style.style });

            return {
                card_cvc: cardCvc,
                card_number: cardNumber,
                card_expiry: cardExpiry
            };
        }

        const cardElements = createCardElement(false);

        cardElements.card_number.mount('#card_number');
        cardElements.card_expiry.mount('#card_expiry');
        cardElements.card_cvc.mount('#card_cvc');


        form.addEventListener('submit', async (event) => {
            event.preventDefault();
            const { token, error } = await stripe.createToken(cardElements.card_number);

            if (error) {
                const errorElement = document.getElementById('card-element-message');
                errorElement.textContent = error.message;
                errorElement.style = "color:red;"
            } else {
                const errorElement = document.getElementById('card-element-message');
                errorElement.textContent = "";
                const confirmBtn = findConfirmBtn();
                if (confirmBtn) {
                    laddaStart.startLadda(confirmBtn);
                }
                // Send the token to your server.
                stripeTokenHandler(token);
            }
        });
    }

    if (form && (num === 3 && !ccEntryForm)) {
        const confirmBtn = findConfirmBtn();
        if (confirmBtn) {
            confirmBtn.addEventListener("click", e => {
                e.preventDefault();
                laddaStart.startLadda(confirmBtn);
                form.submit();
            });
            
        }  
    }

    const stripeTokenHandler = (token) => {
        // Insert the token ID into the form so it gets submitted to the server
        let { form, num } = findForm();

        if (form && num === 1) {
            const data = {
                "stripeToken": token.id
            };
            const btn_el = "#add_card_button";
            $(btn_el).attr("disabled", true);
            const load = $(btn_el).next("div");
            load.css("display", "inline-block");
            $(btn_el).css("display", "none");
            $.ajax({
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json',
                url: '/profile/add-card',
                success: function (data) {
                    // Reload page
                    window.location.href = '/profile/billing';
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.error({
                        "Failed to add card: ": errorThrown
                    });
                }
            });
        }

        if (form && num === 2) {
            form = document.getElementById('post-order-form');
            const hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripeToken');
            hiddenInput.setAttribute('value', token.id);
            form.appendChild(hiddenInput);

            // Submit the form
            form.submit();
        }

        if (form && num === 3) {
            const addressField = document.getElementById('line1');
            if (!addressField) {
                form = document.getElementById('subscription-checkout-form');
                const hiddenInput = document.createElement('input');
                hiddenInput.setAttribute('type', 'hidden');
                hiddenInput.setAttribute('name', 'stripeToken');
                hiddenInput.setAttribute('value', token.id);
                form.appendChild(hiddenInput);

                // Submit the form
                form.submit();
            }
        }

    };

});