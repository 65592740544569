const setupPhoneInput = (eles) => {

    let filter = [];

    const keypadZero = 48;
    const numpadZero = 96;

    for (let i = 0; i <= 9; i++) {
        filter.push(i + keypadZero);
        filter.push(i + numpadZero);
    }

    filter.push(8);
    filter.push(9);
    filter.push(46);
    filter.push(37);
    filter.push(39);

    // check if key is allowed
    const onKeyDown = (e) => {
        if (filter.indexOf(e.keyCode) < 0) {
            e.preventDefault();
            return false;
        }
    }

    function formatPhoneText(value) {
        value = value.trim().replace(/-/g, "");

        if (value.length > 3 && value.length <= 6)
            value = value.slice(0, 3) + "-" + value.slice(3);
        else if (value.length > 6)
            value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6);

        return value;
    }


    const validatePhone = (p) => {
        let phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
        let digits = p.replace(/\D/g, "");
        return phoneRe.test(digits);
    }


    const onKeyUp = (e) => {
        let input = e.target;
        let formatted = formatPhoneText(input.value);
        let isError = (validatePhone(formatted) || formatted.length == 0);
        input.value = formatted;
    }


    const setupPhoneFields = (phoneFields) => {

        for (let i = 0; i < phoneFields.length; i++) {
            let input = phoneFields[i];
            console.log(input);
            if (input.type.toLowerCase() == "tel") {
                input.placeholder = "(XXX-XXX-XXXX)";
                input.addEventListener("keydown", onKeyDown);
                input.addEventListener("keyup", onKeyUp);
            }
        }
    }

    setupPhoneFields(phoneFields);
};

const phoneFields = document.getElementsByClassName('input-phone');
if (phoneFields.length > 0) {
    setupPhoneInput(phoneFields);
} 
